import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from "primereact/accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { InputNumber } from "primereact/inputnumber";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Header from "../Menu/Header";
import moment from "moment";
import axios from "axios";
import "./Management.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

// import Header from "../Menu/Header";
// import { Fieldset } from "primereact/fieldset";
// import Switch from "react-switch";
// import Footerpage from "../Footerpage/Footerpage";
// import { Checkbox } from "primereact/checkbox";
// import { height } from "@material-ui/system";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    incomeStatement: state.incomeStatement.incomeStatement,
    sessionDetails: state.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    incomeStatement: state.incomeStatement.incomeStatement,
    balanceSheet: state.balanceSheet.balanceSheet,
    notes: state.notes.notes,
    notesTemplateReqArrayReducer: state.notes.notesTemplateReqArrayReducer,
    managementStatus: state.managementStatus.managementStatus,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
  };
};

let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let mgmtStmtTemplateReqArray = [],
  mgmtStmtTemplateResponse = {},
  columnNosArray = ["column2", "column3", "column4", "column5"],
  usedColumnNos = [],
  financialYearLoc = [],
  tabTitleArray = [],
  sideBarGreenTickPages = [],
  headerUpdateLineObj = {},
  currentReportGUIDFromUrl = "",
  autoDate = { from: "", to: "" },
  linkSharedUser;

let steps = [
  {
    content: TranslationFile.TourGuide_AddNewColumn_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#addNewColumnMS",
    title: "Add New Record",
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_RemoveColumn_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#mgmtDeleteHeader",
    title: "Remove Column",
    disableBeacon: true,
    delay: 500,
  },
];

class ManagementStatementNew extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      sideBarStatus: true,
      isLoading: true,
      mgmtStatementValue: "",
      activeIndex: [0],
      toggleOpen: false,
      companyInformation: "",
      companyName: "",
      currentFinancialYear: "",
      run: false,
      headerDetails: "",
    };
  }

  handleChange(toggleOpen) {
    window.scroll(0, 0);
    if (toggleOpen == true) {
      let headerIdxList = [];
      Object.keys(tabTitleArray).map((heading, idx) => {
        headerIdxList.push(idx);
      });
      this.setState({ activeIndex: headerIdxList });
      this.setState({ toggleOpen: false });
    } else {
      let collapse = [];
      this.setState({ activeIndex: collapse });
      this.setState({ toggleOpen: true });
    }
  }

  openAll(resp) {
    let headerIdxList = [];
    Object.keys(resp).map((heading, idx) => {
      headerIdxList.push(idx);
    });
    let open = headerIdxList;
    this.setState({ activeIndex: open });
  }

  checkForPreviousYear() {
    const { mgmtStatementValue, headerDetails } = this.state;
    if (
      headerDetails.previousYear1 == null ||
      headerDetails.previousYear1 == ""
    ) {
      let filteredMgmtValue = [];
      {
        Object.keys(mgmtStatementValue).map((heading, parentIdx) => {
          let responseArray = mgmtStatementValue[heading];
          responseArray = [...responseArray].sort(
            (a, b) => a.lineNo - b.lineNo
          );
          responseArray = responseArray.filter(
            (val) => val.financialYearValue == 0
          );
          filteredMgmtValue.push(responseArray);
        });
      }
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    let { companyInformation, sessionDetails, sideBarReducer } = this.props;
    localStorage.setItem("currentPageName", "Management Statement");

    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseDate = response.data;
          this.setState({ headerDetails: responseDate });
          if (responseDate != "") {
            let reportAccessReq = {
              reportId: responseDate.reportID,
            };
            axios
              .post(
                "/annualReport/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {
                  case 2:
                    linkSharedUser = 2; //Write
                    break;
                  case 3:
                    linkSharedUser = 3; //Read Only
                    break;
                  case 4:
                    linkSharedUser = 4; //No Access
                    break;
                }
              })
              .catch((error) => {
                let noAccessURL =
                  "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                this.props.history.push(noAccessURL);
              });
          }
          if (responseDate.companyName != "") {
            this.setState({
              companyName: responseDate.companyName,
            });
          }

          if (responseDate.currentYear != "")
            this.setState({
              currentFinancialYear: responseDate.currentYear,
            });
        });

      let getJsonLines = {
        guid: currentReportGUIDFromUrl,
        pageName: "Management Statement",
      };

      let currentReportCompanyName = localStorage.getItem(
        "navigatedCompanyName"
      );
      if (currentReportCompanyName != "") {
        this.setState({
          companyInformation: currentReportCompanyName,
        });
      }

      axios
        .post(
          "/annualReportLine/getLinesJson",
          getJsonLines,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseData = response.data.managementStatement;
          if (responseData == undefined) {
            responseData = "";
          }

          if (responseData != "") {
            let jsonConvertedResponseData = JSON.parse(responseData);
            let financialYearFromJsonResponse = [];
            Object.keys(jsonConvertedResponseData).map((i, idx) => {
              if (i == "financialYear") {
                financialYearFromJsonResponse = [
                  ...jsonConvertedResponseData[i],
                ];
              }
            });

            mgmtStmtTemplateReqArray = financialYearFromJsonResponse;

            delete jsonConvertedResponseData.financialYear;

            tabTitleArray = jsonConvertedResponseData;
            this.openAll(jsonConvertedResponseData);

            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                  response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                sideBarReducer.values = completedStatementTypesResponse.data;
                sideBarGreenTickPages = completedStatementTypesResponse.data;
                this.setState({
                  reRender: true,
                });
              });

            this.setState({
              mgmtStatementValue: jsonConvertedResponseData,
              isLoading: false,
            });
            this.checkForPreviousYear();
          } else {
            mgmtStmtTemplateReqArray = [];

            if (companyInformation != undefined) {
              companyInformation != undefined &&
                companyInformation.financialYear.length &&
                companyInformation.financialYear.map((financialYear, idx) => {
                  let notesTemplateReq = {
                    validFromDate: moment(financialYear.from).format(
                      "YYYY-MM-DD"
                    ),
                    validToDate: moment(financialYear.to).format("YYYY-MM-DD"),
                    statementType: 2,
                  };
                  mgmtStmtTemplateReqArray.push(notesTemplateReq);
                });

              axios
                .post(
                  "/user/getAnnualReportTemplateDetails",
                  mgmtStmtTemplateReqArray,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  if (Object.keys(response.data).length > 0) {
                    mgmtStmtTemplateResponse = response.data;
                    this.setState({
                      isLoading: false,
                    });
                  } else {
                    this.setState({
                      isLoading: true,
                    });
                  }

                  this.dataManipulation(mgmtStmtTemplateResponse, true, false);
                  tabTitleArray = mgmtStmtTemplateResponse;
                  this.openAll(mgmtStmtTemplateResponse);
                });
            } else {
              let getJsonLines = {
                guid: currentReportGUIDFromUrl,
                pageName: "Company Information",
              };

              axios
                .post(
                  "/annualReportLine/getLinesJson",
                  getJsonLines,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  let responseData = response.data.companyInformation;

                  if (responseData == undefined) {
                    responseData = "";
                  }

                  if (responseData != "") {
                    let jsonConvertedResponseData = JSON.parse(responseData);
                    companyInformation = jsonConvertedResponseData.values;

                    jsonConvertedResponseData.values.financialYear.map(
                      (financialYear, idx) => {
                        let balanceSheetTemplateReq = {
                          validFromDate: moment(financialYear.from).format(
                            "YYYY-MM-DD"
                          ),
                          validToDate: moment(financialYear.to).format(
                            "YYYY-MM-DD"
                          ),
                          statementType: 2,
                        };
                        mgmtStmtTemplateReqArray.push(balanceSheetTemplateReq);
                      }
                    );

                    axios
                      .post(
                        "/user/getAnnualReportTemplateDetails",
                        mgmtStmtTemplateReqArray,
                        userSessionAuthKeyReturnValue
                      )
                      .then((response) => {
                        if (Object.keys(response.data).length > 0) {
                          mgmtStmtTemplateResponse = response.data;
                          this.setState({
                            isLoading: false,
                          });
                        } else {
                          this.setState({
                            isLoading: true,
                          });
                        }

                        this.dataManipulation(
                          mgmtStmtTemplateResponse,
                          true,
                          false
                        );
                        tabTitleArray = mgmtStmtTemplateResponse;
                        this.openAll(mgmtStmtTemplateResponse);
                      });
                  }
                });
            }
          }
        });
    }
  }

  pageNavigation(navigateToPageName) {
    const { sessionDetails } = this.props;
    let { sideBarReducer } = this.props;
    const annualReport = "/annualreport/";

    if (navigateToPageName == "representative") {
      let sideBarReducerValues = [];
      sideBarReducerValues = sideBarReducer.values;
      sideBarReducerValues.push("Management Statement");
      if (sideBarGreenTickPages == 0)
        sideBarReducer.values = sideBarReducerValues;

      let possibleAccessablePagesListLSString = localStorage.getItem(
        "possibleAccessablePages"
      );

      let possibleAccessablePagesListLSArray = JSON.parse(
        possibleAccessablePagesListLSString
      );

      // if (!possibleAccessablePagesListLSArray.includes("Representative")) {

      possibleAccessablePagesListLSArray.push(
        "Company Information",
        "Income Statement",
        "Balance Sheet",
        "Notes",
        "Management Statement"
        // "Representative"
      );

      let updatePossibleNavigationPagesListString = JSON.stringify(
        possibleAccessablePagesListLSArray
      );

      localStorage.setItem(
        "possibleAccessablePages",
        updatePossibleNavigationPagesListString
      );
      // }
    }
    this.props.history.push(
      annualReport + currentReportGUIDFromUrl + "/" + navigateToPageName
    );
  }

  dataManipulation(resp, isInitialRender, isOnChangeCall) {
    let {
      companyInformation,
      sessionDetails,
      login,
      managementStatus,
      financialYear,
    } = this.props;

    const { mgmtStatementValue } = this.state;
    let fieldExistsInMultiYear = [];
    if (userSessionAuthKeyReturnValue) {
      delete resp.financialYear;

      if (managementStatus.values != undefined && !isOnChangeCall) {
        resp = managementStatus.values;
      } else {
        if (isInitialRender) {
          Object.keys(resp).map((i, idx) => {
            let sortedRespArray = [...resp[i]].sort(
              (a, b) => a.lineNo - b.lineNo
            );
            sortedRespArray.map((sortedResp, sortedRespIdx) => {
              sortedResp.column1 = sortedResp.name;
              sortedResp["reportGUID"] = currentReportGUIDFromUrl;
              sortedResp["createdByUserID"] =
                login == undefined ? 0 : login.userID;

              if (sortedResp.templateColumn2 != "") {
                if (sortedResp.templateColumn2.split("Date").length > 1) {
                  //set Fy from column2 to column 6 - START
                  let lastFy = "";
                  let fy = "";
                  let fyRange = "";

                  for (let j = 0; j < 4; j++) {
                    let columnNo = "column" + (j + 2);
                    let DateNo = j + 1;
                    let templateColumnNo = "templateColumn" + (j + 2);
                    if (sortedResp[templateColumnNo] == "Date" + DateNo) {
                      if (mgmtStmtTemplateReqArray[j] != undefined) {
                        // fy =
                        //   mgmtStmtTemplateReqArray[j].validFromDate.split("-")[0];

                        fyRange =
                          mgmtStmtTemplateReqArray[j].validFromDate +
                          " - " +
                          mgmtStmtTemplateReqArray[j].validToDate;
                        sortedResp[columnNo] = fyRange;
                        lastFy = fy;

                        autoDate.from =
                          mgmtStmtTemplateReqArray[j].validFromDate;
                        autoDate.to = mgmtStmtTemplateReqArray[j].validToDate;
                      } else {
                        autoDate.from = moment(autoDate.from).subtract(
                          12,
                          "month"
                        )._d;
                        autoDate.to = moment(autoDate.from).subtract(
                          1,
                          "day"
                        )._d;

                        let fromDate = moment(autoDate.from).format(
                          "YYYY-MM-DD"
                        );
                        let toDate = moment(autoDate.to).format("YYYY-MM-DD");

                        let fromToDateRange = fromDate + " - " + toDate;

                        let lastFyFullDate = new Date(lastFy);
                        let prevFyFullDate = moment(lastFyFullDate).subtract(
                          1,
                          "years"
                        );
                        let prevFyYear = prevFyFullDate.year();
                        lastFy = prevFyYear.toString();

                        // sortedResp[columnNo] = lastFy;
                        sortedResp[columnNo] = fromToDateRange;
                        autoDate = {
                          from: fromDate,
                          to: toDate,
                        };
                      }
                      financialYearLoc.push(lastFy);
                      financialYearLoc = [...new Set(financialYearLoc)];
                    } else {
                      sortedResp[columnNo] = sortedResp[templateColumnNo];
                    }
                  }
                  //set Fy from column2 to column 6 - END
                } else {
                  //set inside header column wise header from column2 to column 6 - START
                  sortedResp.column2 = sortedResp.templateColumn2;
                  if (sortedResp.column2 == "") {
                    delete sortedResp.column2;
                  }
                  sortedResp.column3 = sortedResp.templateColumn3;
                  if (sortedResp.column3 == "") {
                    delete sortedResp.column3;
                  }
                  sortedResp.column4 = sortedResp.templateColumn4;
                  if (sortedResp.column4 == "") {
                    delete sortedResp.column4;
                  }
                  sortedResp.column5 = sortedResp.templateColumn5;
                  if (sortedResp.column5 == "") {
                    delete sortedResp.column5;
                  }
                  sortedResp.column6 = sortedResp.templateColumn6;
                  if (sortedResp.column6 == "") {
                    delete sortedResp.column6;
                  }
                  //set inside header column wise header from column2 to column 6 - END
                }
              }

              let range = this.convert(sortedResp.range, "-");
              if (range[0] == 0) {
                range = this.convert(sortedResp.lineSumRange, "-");
              }

              if (companyInformation != undefined && range[0] != 0) {
                let fyArray = companyInformation.financialYear;
                let ffFormattedArray = [];
                fyArray.map((fyI, fyIdx) => {
                  ffFormattedArray.push(moment(fyI.from).format("YYYY"));
                });
              }
            });
          });
        }
      }

      delete resp.financialYear;
      this.calcLineSumAmount(resp, false);
      fieldExistsInMultiYear = this.mergeMultiYearObject(resp);
      let lineDetailsArray = this.removeDuplicateLineNo(fieldExistsInMultiYear);
      if (lineDetailsArray.length > 0) {
        axios
          .post(
            "/annualReportLine/insertLines",
            lineDetailsArray,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              let responseJson = resp;
              responseJson["financialYear"] = mgmtStmtTemplateReqArray;
              // if (userSessionAuthKeyReturnValue) {
              let currentPageName = localStorage.getItem("currentPageName");
              let updateJsonTableObj = {
                pageName: "Management Statement",
                guid: currentReportGUIDFromUrl,
                json: JSON.stringify(responseJson),
              };
              axios
                .post(
                  "/annualReportLine/updateLinesJsonTable",
                  updateJsonTableObj,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {});
              // }
            }
          });
      }

      this.setState({
        mgmtStatementValue: resp,
        isLoading: false,
      });

      managementStatus.values = resp;
    }
  }

  convert = (str) =>
    str.split(",").flatMap((s) => {
      if (!s.includes("-")) return +s;
      const [min, max] = s.split("-");
      return Array.from({ length: max - min + 1 }, (_, n) => n + +min);
    });

  ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  }

  calcLineSumAmount(resp, insert, heading, amount, columnNo, lineDetails) {
    const { sessionDetails } = this.props;
    const { mgmtStatementValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      delete resp.financialYear;
      let tempResp = { ...resp };
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
        let lineSumObjArray = sortedRespArray.filter((obj) => obj.isLineSum);

        lineSumObjArray &&
          lineSumObjArray.length &&
          lineSumObjArray.map((lineSumObj, lineSumObjIdx) => {
            let range = this.convert(lineSumObj.lineSumRange, "-");

            const lineObjPerHeader = sortedRespArray.filter(
              (obj) => obj.lineType == "Line"
            );

            let column2Value = 0,
              column3Value = 0,
              column4Value = 0,
              column5Value = 0,
              column6Value = 0;

            lineObjPerHeader &&
              lineObjPerHeader.length &&
              lineObjPerHeader.map((lineObj, lineObjIdx) => {
                if (range.includes(lineObj.lineNo)) {
                  if (lineObj.column2 != null) {
                    column2Value += lineObj.column2;
                  }

                  if (lineObj.column3 != null) {
                    column3Value += lineObj.column3;
                  }

                  if (lineObj.column4 != null) {
                    column4Value += lineObj.column4;
                  }

                  if (lineObj.column5 != null) {
                    column5Value += lineObj.column5;
                  }

                  if (lineObj.column6 != null) {
                    column6Value += lineObj.column6;
                  }
                }
              });

            let isDecimalValue = false;

            if (null != column2Value) {
              isDecimalValue = column2Value % 1 != 0;
              lineSumObj.column2 = isDecimalValue
                ? this.ParseFloat(column2Value.toString(), 2)
                : column2Value;
            }

            if (null != column3Value) {
              isDecimalValue = column3Value % 1 != 0;
              lineSumObj.column3 = isDecimalValue
                ? this.ParseFloat(column3Value.toString(), 2)
                : column3Value;
            }

            if (null != column4Value) {
              isDecimalValue = column4Value % 1 != 0;
              lineSumObj.column4 = isDecimalValue
                ? this.ParseFloat(column4Value.toString(), 2)
                : column4Value;
            }

            if (null != column5Value) {
              isDecimalValue = column5Value % 1 != 0;
              lineSumObj.column5 = isDecimalValue
                ? this.ParseFloat(column5Value.toString(), 2)
                : column5Value;
            }

            if (null != column6Value) {
              isDecimalValue = column6Value % 1 != 0;
              lineSumObj.column6 = isDecimalValue
                ? this.ParseFloat(column6Value.toString(), 2)
                : column6Value;
            }
          });
      });

      this.setState({
        reRender: true,
      });

      let fieldExistsInMultiYear = this.mergeMultiYearObject(resp);
      let lineDetailsArray = this.removeDuplicateLineNo(fieldExistsInMultiYear);

      if (insert && lineDetailsArray.length > 0) {
        axios
          .post(
            "/annualReportLine/insertLines",
            lineDetailsArray,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              let responseJson = resp;
              responseJson["financialYear"] = mgmtStmtTemplateReqArray;

              // if (userSessionAuthKeyReturnValue) {
              let currentPageName = localStorage.getItem("currentPageName");
              let updateJsonTableObj = {
                pageName: "Management Statement",
                guid: currentReportGUIDFromUrl,
                json: JSON.stringify(responseJson),
              };
              axios
                .post(
                  "/annualReportLine/updateLinesJsonTable",
                  updateJsonTableObj,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {});
              // }
            }
          });
      }
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  convert = (str) =>
    str.split(",").flatMap((s) => {
      if (!s.includes("-")) return +s;
      const [min, max] = s.split("-");
      return Array.from({ length: max - min + 1 }, (_, n) => n + +min);
    });

  accordionHeader(header) {
    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "100%", marginTop: "7px" }}
        >
          <div>
            <label className="BSFieldsStyle">{header}</label>
          </div>
        </Col>
      </Row>
    );
  }

  mergeMultiYearObject(resp) {
    let fieldExistsInMultiYear = [];

    delete resp.financialYear;
    Object.keys(resp).map((i, idx) => {
      let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
      sortedRespArray.map((sortedResp, sortedRespIdx) => {
        const filtered = sortedRespArray.filter(
          (obj) => obj.lineNo == sortedResp.lineNo
        );

        if (filtered && filtered.length > 1) {
          let omitNull = (obj) => {
            Object.keys(obj)
              .filter((k) => obj[k] === null || obj[k] == undefined)
              .forEach((k) => delete obj[k]);
            return obj;
          };

          let objCollection = [];
          filtered.map((i, idx) => {
            if (i.column1 == "Comments") {
              i.column1 = "";
            }
            let cc = { ...omitNull(i) };
            objCollection.push(cc);
          });

          fieldExistsInMultiYear.push(Object.assign({}, ...objCollection));
        } else {
          fieldExistsInMultiYear.push(Object.assign({}, ...filtered));
        }
      });
    });

    return fieldExistsInMultiYear;
  }

  removeDuplicateLineNo(fieldExistsInMultiYear) {
    let lineNoArray = [],
      lineDetailsArray = [];

    fieldExistsInMultiYear.length &&
      fieldExistsInMultiYear.map((i, idx) => {
        let lineNoWiseObjArray = fieldExistsInMultiYear.filter(
          (obj) => obj.lineNo == i.lineNo
        );

        let arr = [];
        lineNoWiseObjArray &&
          lineNoWiseObjArray.length &&
          lineNoWiseObjArray.map((j, jdx) => {
            j = JSON.parse(JSON.stringify(j));
            Object.keys(j).forEach((key) => {
              if (j[key] == null) {
                delete j[key];
              }
            });
            arr.push(j);
          });
        const mergedLines = Object.assign({}, ...arr);

        if (!lineNoArray.includes(mergedLines.lineNo)) {
          lineNoArray.push(mergedLines.lineNo);
          lineDetailsArray.push(mergedLines);
        }
      });

    return lineDetailsArray;
  }

  addNewLine(prevObj, headerName) {
    const { mgmtStatementValue } = this.state;

    if (userSessionAuthKeyReturnValue) {
      //remove existing empty cust row - START
      Object.keys(mgmtStatementValue).map((heading, idx) => {
        let headerWiseLines = mgmtStatementValue[heading];

        let defaultHeaderWiseLines = headerWiseLines.filter(
          (obj) => obj.isCustomRow == undefined
        );

        let notEmptyCustRow = headerWiseLines.filter(
          (obj) => obj.isCustomRow && obj.name != ""
        );

        const mergedLines = [...defaultHeaderWiseLines, ...notEmptyCustRow];
        mgmtStatementValue[heading] = mergedLines;
      });
      //remove existing empty cust row - END

      //add cust row - START
      let headerResult = mgmtStatementValue[headerName];
      let addObj = { ...prevObj };

      let lineNo = addObj.lineNo + 1;
      let calcNextLineNo = false;

      for (let i = addObj.lineNo; i <= addObj.lineNo + 1000; i++) {
        let lineNoObj = [];
        lineNoObj = headerResult.filter((obj) => obj.lineNo == i);
        if (lineNoObj.length == 0 && !calcNextLineNo) {
          lineNo = i;
          calcNextLineNo = true;
          break;
        }
      }

      addObj.lineNo = lineNo;
      addObj.name = "";
      addObj.column1 = "";
      addObj.column2 = null;
      addObj.column3 = null;
      addObj.column4 = null;
      addObj.column5 = null;
      addObj.column6 = null;
      addObj.statementSubType = prevObj.statementSubType;
      addObj.isCustomRow = true;
      headerResult.push(addObj);
      //add cust row - EBD

      let addObjArray = [addObj];
      axios
        .post(
          "/annualReportLine/insertLines",
          addObjArray,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data)
            this.setState({
              reRender: true,
            });
        });
    }
  }

  txtAreaOnChange(text, heading, lineDetails) {
    const { mgmtStatementValue } = this.state;
    let headerWiseLines = mgmtStatementValue[heading];
    let objByLineNo = headerWiseLines.filter(
      (obj) => obj.lineNo == lineDetails.lineNo
    );

    objByLineNo &&
      objByLineNo.length &&
      objByLineNo.map((i, idx) => {
        i.name = text;
        i.column1 = text;
      });

    this.setState({
      reRender: true,
    });
  }

  stringInputOnChange(e, parentHeading, lineNo) {
    const { mgmtStatementValue } = this.state;
    mgmtStatementValue[parentHeading]
      .filter((obj) => obj.lineNo == lineNo)
      .map((line, lineIdx) => {
        line.column2 = e.target.value;
      });

    this.setState({
      reRender: true,
    });
  }

  stringInputOnBlur(e, parentHeading, lineNo) {
    const { mgmtStatementValue } = this.state;
    let isStringInputUpdated = false;

    if (userSessionAuthKeyReturnValue) {
      mgmtStatementValue[parentHeading]
        .filter((obj) => obj.lineNo == lineNo)
        .map((line, lineIdx) => {
          if (!isStringInputUpdated) {
            isStringInputUpdated = true;
            line.column2 = e.target.value;
            line.column1 = line.name;

            axios
              .post(
                "/annualReportDetails/updateDetailsTextArea",
                line,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                if (response.data) {
                  let responseJson = mgmtStatementValue;
                  responseJson["financialYear"] = mgmtStmtTemplateReqArray;

                  let splittedUrl = window.location.href.split("/");
                  let currentReportGUIDFromUrl =
                    splittedUrl[splittedUrl.length - 2];

                  let updateJsonTableObj = {
                    pageName: "Management Statement",
                    guid: currentReportGUIDFromUrl,
                    json: JSON.stringify(responseJson),
                  };

                  axios
                    .post(
                      "/annualReportLine/updateLinesJsonTable",
                      updateJsonTableObj,
                      userSessionAuthKeyReturnValue
                    )
                    .then((response) => {});
                }
              });
          }
        });

      this.setState({
        reRender: true,
      });
    }
  }

  txtAreaOnBlur(text, heading, lineDetails, isInsert) {
    const { sessionDetails } = this.props;
    const { mgmtStatementValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      let headerWiseLines = mgmtStatementValue[heading];
      let objByLineNo = headerWiseLines.filter(
        (obj) => obj.lineNo == lineDetails.lineNo
      );

      objByLineNo &&
        objByLineNo.length &&
        objByLineNo.map((i, idx) => {
          i.name = text;
          i.column1 = text;
        });

      if (isInsert) {
        axios
          .post(
            "/annualReportDetails/updateDetailsTextArea",
            lineDetails,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              let responseJson = mgmtStatementValue;
              responseJson["financialYear"] = mgmtStmtTemplateReqArray;

              // if (userSessionAuthKeyReturnValue) {
              let currentPageName = localStorage.getItem("currentPageName");
              let updateJsonTableObj = {
                pageName: "Management Statement",
                guid: currentReportGUIDFromUrl,
                json: JSON.stringify(responseJson),
              };
              axios
                .post(
                  "/annualReportLine/updateLinesJsonTable",
                  updateJsonTableObj,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {});
              // }
            }
          });
      }

      this.setState({
        reRender: true,
      });
    }
  }

  amountOnBlur(amount, heading, lineDetails, headerLblIdx, columnNo) {
    const { mgmtStatementValue } = this.state;
    let amountRemovedComma = amount.target.value.replace(",", "");
    let parsedFloatAmount = Number(amountRemovedComma.replaceAll(/\s/g, ""));

    let spacesRemoved = amountRemovedComma.replaceAll(/\s/g, "");

    const firstChar = spacesRemoved.charAt(0);
    let minusArray = ["-", "−", "‑", "−"];
    if (minusArray.includes(firstChar)) {
      const newStr = spacesRemoved.slice(1);
      parsedFloatAmount = Number(-1 * newStr);
    } else parsedFloatAmount = Number(spacesRemoved);

    let headerWiseLines = mgmtStatementValue[heading];
    let objByLineNo = headerWiseLines.filter(
      (obj) => obj.lineNo == lineDetails.lineNo
    );

    let lineSumArray = headerWiseLines.filter((obj) => obj.isLineSum);
    let columnNoToUpdate = headerLblIdx + 2;

    if (isNaN(parsedFloatAmount)) {
      parsedFloatAmount = 0;
    }

    const uniqueLineObjects = [
      ...new Map(objByLineNo.map((item) => [item.lineNo, item])).values(),
    ];

    uniqueLineObjects &&
      uniqueLineObjects.length &&
      uniqueLineObjects.map((i, idx) => {
        let objByLineNoTemp = headerWiseLines.filter(
          (obj) => obj.lineNo == lineDetails.lineNo
        );

        const uniqueLineObjectsTemp = [
          ...new Map(
            objByLineNoTemp.map((item) => [item.lineNo, item])
          ).values(),
        ];

        uniqueLineObjectsTemp &&
          uniqueLineObjectsTemp.length &&
          uniqueLineObjectsTemp.map((o, odx) => {
            if (o.column2 != null) {
              i.column2 = o.column2;
            }

            if (o.column3 != null) {
              i.column3 = o.column3;
            }

            if (o.column4 != null) {
              i.column4 = o.column4;
            }

            if (o.column5 != null) {
              i.column5 = o.column5;
            }
            i.column6 = 0;
          });

        if (columnNo !== "6") {
          i["column" + columnNoToUpdate] = parsedFloatAmount;
        }
      });

    if (heading.includes("2 ", 0)) {
      objByLineNo &&
        objByLineNo.length &&
        objByLineNo.map((respObj, idx) => {
          let rowSum = 0;

          if (respObj.column2 != null) {
            rowSum += respObj.column2;
          }

          if (respObj.column3 != null) {
            rowSum += respObj.column3;
          }

          if (respObj.column4 != null) {
            rowSum += respObj.column4;
          }

          if (respObj.column5 != null) {
            rowSum += respObj.column5;
          }

          if (rowSum != 0) {
            if (columnNo == "6") {
              respObj.column6 = parsedFloatAmount;
            } else {
              respObj.column6 = rowSum;
            }
          }
        });

      lineSumArray &&
        lineSumArray.length &&
        lineSumArray.map((i, idx) => {
          i["column" + columnNoToUpdate] += parsedFloatAmount;
        });
    }

    this.setState({
      reRender: true,
    });

    this.calcLineSumAmount(mgmtStatementValue, true);
  }

  amountOnChange(amount, heading, lineDetails, headerLblIdx, columnNo) {
    const { mgmtStatementValue } = this.state;

    let headerWiseLines = mgmtStatementValue[heading];
    let objByLineNo = headerWiseLines.filter(
      (obj) => obj.lineNo == lineDetails.lineNo
    );

    let lineSumArray = headerWiseLines.filter((obj) => obj.isLineSum);

    let columnNoToUpdate = headerLblIdx + 2;

    objByLineNo &&
      objByLineNo.length &&
      objByLineNo.map((i, idx) => {
        if (idx == 0) {
          i["column" + columnNoToUpdate] = amount.value;
        } else {
          i["column" + columnNoToUpdate] = null;
        }
      });

    objByLineNo &&
      objByLineNo.length &&
      objByLineNo.map((respObj, idx) => {
        if (idx == 0) {
          let rowSum = 0;

          if (respObj.column2 != null) {
            rowSum += respObj.column2;
          }

          if (respObj.column3 != null) {
            rowSum += respObj.column3;
          }

          if (respObj.column4 != null) {
            rowSum += respObj.column4;
          }

          if (respObj.column5 != null) {
            rowSum += respObj.column5;
          }

          if (rowSum != 0) {
            if (columnNo == "6") {
              respObj.column6 = amount.value;
            } else {
              respObj.column6 = rowSum;
            }
          }
        }
      });

    lineSumArray &&
      lineSumArray.length &&
      lineSumArray.map((i, idx) => {
        i["column" + columnNoToUpdate] += amount.value;
      });

    this.setState({
      reRender: true,
    });

    this.calcLineSumAmount(mgmtStatementValue, true);
  }

  deleteCustomRow(customRowToDelete, heading) {
    const { mgmtStatementValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      Object.keys(mgmtStatementValue).map((i, idx) => {
        if (i == heading) {
          let filteredObjectsToRemove = mgmtStatementValue[i].filter(
            (item) => item.lineNo == customRowToDelete.lineNo
          );

          filteredObjectsToRemove.length &&
            filteredObjectsToRemove.map((ObjToRemove, ObjToRemoveIdx) => {
              mgmtStatementValue[i].splice(
                mgmtStatementValue[i].indexOf(ObjToRemove),
                1
              );
            });

          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              customRowToDelete,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              if (response.data) {
                this.dataManipulation(mgmtStatementValue, false, true);
                this.setState({
                  reRender: true,
                });
              }
            });
        }
      });
    }
  }

  headerOnChange(e, lineDetail, col, heading) {
    const { mgmtStatementValue } = this.state;
    let headingValues = mgmtStatementValue[heading];
    let lineNoFiltered = headingValues.filter(
      (obj) => obj.lineNo == lineDetail.lineNo
    );

    let value = e.target.value;
    // if (e.target.value != "") {
    lineNoFiltered.map((i, idx) => {
      i[col] = value != "" ? value : " ";
      // i[col] = value != "" && value ;
      headerUpdateLineObj = i;
    });
    // }

    this.setState({
      reRender: true,
    });
  }

  headerOnBlur() {
    const { mgmtStatementValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/annualReportDetails/updateManagementStatementHeader",
          headerUpdateLineObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseJson = mgmtStatementValue;
            responseJson["financialYear"] = mgmtStmtTemplateReqArray;
            // if (userSessionAuthKeyReturnValue) {
            let currentPageName = localStorage.getItem("currentPageName");
            let updateJsonTableObj = {
              pageName: "Management Statement",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };
            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
            // }
          }
        });
    }
  }
  languageValue = (e) => {};

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    if (status) {
      this.props.history.push(annualReport + session + link);
    }
    this.setState({
      reRender: true,
    });
  };

  deleteColumn(lineDetail, colIdx, deleteColumnsUnderHeading) {
    const { mgmtStatementValue } = this.state;
    //Deleting Column5

    Object.keys(mgmtStatementValue).map((heading, headingIdx) => {
      if (heading == deleteColumnsUnderHeading) {
        let resp = [...mgmtStatementValue[heading]];
        let deleteCol = colIdx + 2;
        if(lineDetail.templateColumn2 != "Date1") {
          resp.map((val) => {
             delete val["column" + deleteCol];
          })
        }
      }
    });
    
    Object.keys(mgmtStatementValue).map((heading, headingIdx) => {
      if (heading == deleteColumnsUnderHeading) {
      let resp = [...mgmtStatementValue[heading]];
        
        let linesList = resp.filter(
          (obj) => obj.parentLineNo == lineDetail.lineNo
        );
        let deleteCol = colIdx + 2;
        linesList.map((respObj, respIdx) => {
          if (!respObj.isLineSum) {
            delete respObj["column" + deleteCol];
          } else {
            respObj["column" + deleteCol] = 0;
          }

          if (lineDetail.templateColumn2 == "Date1") {
            let moveFromCol = deleteCol + 1;
            respObj["column" + deleteCol] = respObj["column" + moveFromCol];
            delete respObj["column" + moveFromCol];
          } else {
          }
        });

        linesList = resp.filter((obj) => obj.lineType == "Line");
        linesList.map((respObj, idx) => {
          // if (idx == 0) {
          let rowSum = 0;

          if (respObj.column2 != null) {
            rowSum += respObj.column2;
          }

          if (respObj.column3 != null) {
            rowSum += respObj.column3;
          }

          if (respObj.column4 != null) {
            rowSum += respObj.column4;
          }

          if (respObj.column5 != null) {
            rowSum += respObj.column5;
          }

          if (rowSum != 0) {
            respObj.column6 = rowSum;
          }
          // }
        });
      }
    });

    this.setState({
      reRender: true,
    });

    Object.keys(mgmtStatementValue).map((heading, headingIdx) => {
      if (heading == deleteColumnsUnderHeading) {
        let resp = [...mgmtStatementValue[heading]];
        let linesList = resp.filter(
          (obj) => obj.parentLineNo == lineDetail.lineNo
        );
        let totaltSumValue = 0;
        linesList.map((respObj, respIdx) => {
          if (respObj.lineLevel == 3 && !respObj.isHeader) {
            if (respObj.column6 != undefined)
              totaltSumValue = totaltSumValue + respObj.column6;
          }

          if (linesList.length == respIdx + 1) {
            respObj["column6"] = totaltSumValue;
          }
        });
      }
    });

    //colIdx
    let updateAction = "move";
    if (lineDetail.templateColumn2 != "Date1") {
      updateAction = "clear";
    }
    let deleteColumnObj = {
      columnNo: colIdx + 2,
      parentLineNo: lineDetail.parentLineNo,
      reportGUID: currentReportGUIDFromUrl,
      updateAction: updateAction,
    };

    axios
      .post(
        "/annualReportDetails/deleteColumnInMgmtStmt",
        deleteColumnObj,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        if (response.data) {
          if (lineDetail.templateColumn2 != "Date1") {
          }
          let responseJson = mgmtStatementValue;
            responseJson["financialYear"] = mgmtStmtTemplateReqArray;
            // if (userSessionAuthKeyReturnValue) {
            let currentPageName = localStorage.getItem("currentPageName");
            let updateJsonTableObj = {
              pageName: "Management Statement",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };
            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
        }
      });
      this.setState({
        reRender: true,
      });      

    this.toast.clear();
  }

  addColumn(lineDetail, addInHeading) {
    const { mgmtStatementValue } = this.state;
    let isFyHeading = lineDetail.templateColumn2 == "Date1" ? true : false;
    let columnLimit = isFyHeading ? 5 : 6;
    let arr = [];
    // if (isFyHeading) {
    Object.keys(mgmtStatementValue).map((heading, headingIdx) => {
      if (heading == addInHeading) {
        let resp = [...mgmtStatementValue[heading]];
        // resp = resp.filter(
        //   (obj) =>
        //     obj.parentLineNo == lineDetail.lineNo && obj.dataType != "Textarea"
        // );

        //LINE
        // resp.filter((obj)=>obj.parentLineNo == lineDetail.lineNo && obj.dataType != "Textarea")
        //HEADER
        // resp.filter((obj)=>obj.lineNo== lineDetail.lineNo)

        resp = resp.filter((obj) => obj.lineNo == lineDetail.lineNo);

        let headingFromResp = resp.filter((obj) => obj.isHeader);

        let headerArrayObj = headingFromResp[0];

        let columnToAdd = "",
          columnNoToAdd = 0;

        let lastFyInHeading = "";

        if (
          !isFyHeading &&
          headerArrayObj &&
          (headerArrayObj.column6 == undefined || headerArrayObj.column6 == "")
        ) {
          columnToAdd = "column6";
          columnNoToAdd = 6;
          lastFyInHeading = headerArrayObj.column5;
        }

        if (
          headerArrayObj.column5 == undefined ||
          headerArrayObj.column5.length == 0
        ) {
          columnToAdd = "column5";
          columnNoToAdd = 5;
          lastFyInHeading = headerArrayObj.column4;
        }

        if (
          headerArrayObj.column4 == undefined ||
          headerArrayObj.column4.length == 0
        ) {
          columnToAdd = "column4";
          columnNoToAdd = 4;
          lastFyInHeading = headerArrayObj.column3;
        }

        if (
          headerArrayObj.column3 == undefined ||
          headerArrayObj.column3.length == 0
        ) {
          columnToAdd = "column3";
          columnNoToAdd = 3;
          lastFyInHeading = headerArrayObj.column2;
        }

        let fyRange = "",
          splitFyByHiffin = [];
        if (lastFyInHeading != "") {
          splitFyByHiffin = lastFyInHeading.split(" - ");
          if (splitFyByHiffin.length == 2) {
            let fromDate = (autoDate.from = moment(splitFyByHiffin[0]).subtract(
              12,
              "month"
            )._d);

            let toDate = moment(splitFyByHiffin[1]).subtract(1, "day")._d;

            fromDate = moment(fromDate).format("YYYY-MM-DD");
            toDate = moment(toDate).format("YYYY-MM-DD");
            fyRange = fromDate + " - " + toDate;
          }
        }

        let columnValueToAdd = "";
        if (columnToAdd != "") {
          columnToAdd = columnToAdd;
          resp.map((i, idx) => {
            if (!arr.includes(i.column1)) {
              arr.push(i.column1);

              if (i.isHeader) {
                i[columnToAdd] = fyRange != "" ? fyRange : "text here..";
              } else {
                if (splitFyByHiffin.length == 2) {
                  i[columnToAdd] = 0;
                }
              }
            } else if (i.isHeader) {
              i[columnToAdd] = fyRange != "" ? fyRange : "text here..";
            } else {
              delete i[columnToAdd];
            }
          });
        } else {
          this.toast.show({
            severity: "error",
            summary: TranslationFile.LimitReachedText[userSelectedLanguage],
            life: 2000,
          });
        }
      }
    });

    this.setState({
      reRender: true,
    });
    // }
  }

  navigateToPDF() {
    window.open(`#/annualreport/${currentReportGUIDFromUrl}/pdf`, "_blank");
  }

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  componentDidMount() {
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    setTimeout(() => {
      this.setState({ run: userTourGuide });
    }, 200);
  }

  tourStatus = (e) => {
    this.setState({ run: e });
  };

  render() {
    const {
      sideBarStatus,
      isLoading,
      mgmtStatementValue,
      activeIndex,
      toggleOpen,
      companyName,
      currentFinancialYear,
    } = this.state;
    const { readOnly, showWIPStatus } = this.props;
    delete mgmtStatementValue.financialYear;

    let pageUnderWIP = false;
    if (showWIPStatus.values != undefined) {
      if (showWIPStatus.values) {
        pageUnderWIP = true;
      } else {
        pageUnderWIP = false;
      }
    }
    return (
      <div>
        <Joyride
          continuous
          run={this.state.run}
          disableBeacon={true}
          showProgress
          showSkipButton
          steps={steps}
          scrollToFirstStep
          scrollToSteps
          scrollOffset={100}
          locale={{
            last: "End",
            back: "Back",
            next: "Next",
            skip: "Skip",
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          className="companyInfoErrorToast"
          style={{ marginTop: "34%" }}
        ></Toast>
        <NavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />
        <div
          className={
            pageUnderWIP
              ? "managementSideBarWithWIP"
              : "managementStatementSideBar"
          }
        >
          <Sidebar
            children="Management Statement"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={this.sideBarCallback}
            tourStatus={this.tourStatus.bind(this)}
          />
        </div>

        <Row
          className={sideBarStatus ? "MSFYStyle" : "MSFYStyle-sidebar-close"}
          // className="MSFYStyle"
        >
          <Col>
            <div>
              <div className="Page_Title_manage_div">
                <div className="Page_Title_T1_Management">{companyName}</div>
                <div className="Page_Title_management_Year">
                  {currentFinancialYear}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <Button
              type="button"
              icon="pi pi-file-pdf"
              outlined
              style={{ borderRadius: "70px" }}
              className="pdf_view_management"
              tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
              tooltipOptions={{ position: "bottom" }}
              // onClick={() => this.navigateToPDF()}
              onClick={() => previewPdf(currentReportGUIDFromUrl)}
            />
          </Col>

          <div className="MgmtExapndCollapsToggleLbl">
            {toggleOpen == true ? (
              <Button
                value={toggleOpen}
                icon="pi pi-chevron-down"
                title="Expand"
                onClick={(e) => this.handleChange(toggleOpen)}
                className="toggle-open"
              />
            ) : (
              <Button
                value={toggleOpen}
                icon="pi pi-chevron-up"
                title="Collapse"
                onClick={(e) => this.handleChange(toggleOpen)}
                className="toggle-open"
              />
            )}
          </div>
        </Row>

        <div className={sideBarStatus ? "MSSideBarOpen" : "MSSideBarClose"}>
          {isLoading ? (
            <div className="BSLoading">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
            </div>
          ) : (
            <Row style={{ width: "100%" }}>
              <Col
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{ width: "100%", marginLeft: "12px" }}
                className="MS_Main_Div"
              >
                {mgmtStatementValue != undefined && (
                  <Accordion
                    multiple
                    activeIndex={activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                    ref={this.myRef}
                  >
                    {Object.keys(mgmtStatementValue).map(
                      (heading, parentIdx) => {
                        let responseArray = mgmtStatementValue[heading];
                        responseArray = [...responseArray].sort(
                          (a, b) => a.lineNo - b.lineNo
                        );

                        let lineDetailsArray =
                          this.removeDuplicateLineNo(responseArray);

                        let tabHeaderName = lineDetailsArray[0].name;

                        lineDetailsArray = lineDetailsArray.filter(
                          (obj) => !obj.isHeader || obj.lineLevel != 1
                        );
                        let headerLblArray = [];
                        if (lineDetailsArray && lineDetailsArray.length) {
                          return (
                            <AccordionTab
                              header={this.accordionHeader(tabHeaderName)}
                              ref={this.myRef}
                              className="IS-Accordion-Tab"
                            >
                              {lineDetailsArray.map(
                                (lineDetail, lineDetailIdx) => {
                                  let isInsideHeader = lineDetail.isHeader;

                                  let isInsideHeaderWithoutColVal =
                                    isInsideHeader &&
                                    lineDetail.column2 != undefined &&
                                    lineDetail.column2.length == 0 &&
                                    lineDetail.column3 != undefined &&
                                    lineDetail.column3.length == 0 &&
                                    lineDetail.column4 != undefined &&
                                    lineDetail.column4.length == 0 &&
                                    lineDetail.column5 != undefined &&
                                    lineDetail.column5.length == 0 &&
                                    lineDetail.column6 != undefined &&
                                    lineDetail.column6.length == 0;
                                  // lineDetail.column2 == null &&
                                  // lineDetail.column3 == null &&
                                  // lineDetail.column4 == null &&
                                  // lineDetail.column5 == null &&
                                  // lineDetail.column6 == null;

                                  let prevObj =
                                    lineDetailsArray[lineDetailIdx - 1];

                                  lineDetail.templateColumn2 != "" &&
                                    usedColumnNos.push("column2");

                                  lineDetail.templateColumn3 != "" &&
                                    usedColumnNos.push("column3");

                                  lineDetail.templateColumn4 != "" &&
                                    usedColumnNos.push("column4");

                                  lineDetail.templateColumn5 != "" &&
                                    usedColumnNos.push("column5");

                                  lineDetail.templateColumn6 != "" &&
                                    usedColumnNos.push("column6");

                                  usedColumnNos = [...new Set(usedColumnNos)];

                                  return (
                                    <Row
                                      className="BSFields"
                                      style={{
                                        height:
                                          lineDetail.dataType != "Textarea" &&
                                          lineDetail.lineType == "Line" &&
                                          "50px",
                                      }}
                                    >
                                      <Col
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        lg={5}
                                        xl={5}
                                        className="mgmtHeaderMainDiv"
                                      >
                                        <div
                                          className="mgmtLblRowMainsideDiv"
                                          style={{
                                            background:
                                              isInsideHeader && "#DBE2EF",
                                            // fontFamily:
                                            //   isInsideHeader &&
                                            //   "Poppins-Medium",
                                          }}
                                        >
                                          {lineDetail.templateColumn2 != "" &&
                                            linkSharedUser != 3 && (
                                              <p
                                                className="mgmtStmntAddNewColumn"
                                                style={{
                                                  display:
                                                    lineDetail["column5"] &&
                                                    lineDetail.templateColumn2 !==
                                                      "Date1" &&
                                                    "none",
                                                }}
                                                onClick={() =>
                                                  this.addColumn(
                                                    lineDetail,
                                                    heading
                                                  )
                                                }
                                              >
                                                <span id="addNewColumnMS">
                                                  + Add New Column
                                                </span>
                                              </p>
                                            )}

                                          {lineDetail.dataType != "Textarea" ? (
                                            isInsideHeaderWithoutColVal ? (
                                              <div className="mgmtIsInsideHeaderWithoutColVal">
                                                <label className="mgmtIsInsideHeaderWithoutColValLbl">
                                                  {lineDetail.name}
                                                </label>
                                              </div>
                                            ) : (
                                              <Row
                                                style={{
                                                  display: "-webkit-box",
                                                }}
                                              >
                                                <Col
                                                  xs={3}
                                                  sm={3}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                >
                                                  {lineDetail.isLineSum ? (
                                                    <div>
                                                      <label className="mgmtLineSumLbl">
                                                        {lineDetail.name}
                                                      </label>
                                                    </div>
                                                  ) : lineDetail.name != "" &&
                                                    lineDetail.isCustomRow ==
                                                      undefined ? (
                                                    <div
                                                      style={{
                                                        display:
                                                          lineDetail.dataType ==
                                                            "Static" &&
                                                          "ruby-text",
                                                      }}
                                                    >
                                                      <label
                                                        className="mgmtLineLbl"
                                                        // style={{
                                                        //   fontFamily:
                                                        //     isInsideHeader &&
                                                        //     "Poppins-Medium",
                                                        // }}
                                                      >
                                                        {lineDetail.name}
                                                      </label>

                                                      {lineDetail.dataType ==
                                                        "Static" && (
                                                        <InputText
                                                          value={
                                                            lineDetail.column2 !=
                                                              null &&
                                                            lineDetail.column2 !=
                                                              0
                                                              ? lineDetail.column2
                                                              : undefined
                                                          }
                                                          className="notesStringBox"
                                                          onChange={(e) => {
                                                            this.stringInputOnChange(
                                                              e,
                                                              heading,
                                                              lineDetail.lineNo
                                                            );
                                                          }}
                                                          onBlur={(e) => {
                                                            this.stringInputOnBlur(
                                                              e,
                                                              heading,
                                                              lineDetail.lineNo
                                                            );
                                                          }}
                                                          disabled={
                                                            linkSharedUser == 3
                                                          }
                                                          style={{
                                                            marginLeft: "20px",
                                                            width: "60%",
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div className="mgmtCustTxtAreaDiv">
                                                      <InputTextarea
                                                        // autoFocus
                                                        id="mgmtCustTxtAreaDiv"
                                                        value={lineDetail.name}
                                                        className="mgmtCustTxtArea"
                                                        onChange={(e) =>
                                                          this.txtAreaOnBlur(
                                                            e.target.value,
                                                            heading,
                                                            lineDetail,
                                                            false
                                                          )
                                                        }
                                                        onBlur={(e) =>
                                                          this.txtAreaOnBlur(
                                                            e.target.value,
                                                            heading,
                                                            lineDetail,
                                                            true
                                                          )
                                                        }
                                                        disabled={
                                                          linkSharedUser == 3
                                                        }
                                                        placeholder="Enter your description"
                                                      />
                                                    </div>
                                                  )}
                                                </Col>

                                                {usedColumnNos.map(
                                                  (col, colIdx) => {
                                                    let convertedToNumb =
                                                      parseFloat(
                                                        lineDetail[col]
                                                      );

                                                    /*
                                                    if (dataType="" &&
                                                        lineType="Line")
                                                    {
                                                      set column 2 to column6 = 0
                                                    }
                                                    */

                                                    if (
                                                      lineDetail[col] != "" &&
                                                      lineDetail[col] != null &&
                                                      // lineDetail[col] != "" &&
                                                      lineDetail[col] != "-" &&
                                                      lineDetail["dataType"] !=
                                                        "" &&
                                                      lineDetail["lineType"] !=
                                                        "Line" &&
                                                      // !lineDetail.header &&
                                                      // typeof lineDetail[col] !=
                                                      //   "number" &&
                                                      // lineDetail.dataType !=
                                                      //   "Static" &&

                                                      // isNaN(convertedToNumb)
                                                      !headerLblArray.includes(
                                                        col
                                                      )
                                                    ) {
                                                      headerLblArray.push(col);

                                                      if (
                                                        lineDetail[col] != ""
                                                      ) {
                                                        return (
                                                          <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{
                                                              width:
                                                                lineDetail.templateColumn2 ==
                                                                "Date1"
                                                                  ? "15%"
                                                                  : "15%",

                                                              display: "flex",
                                                              // marginRight:
                                                              //   lineDetail.templateColumn2 !=
                                                              //     "Date1" &&
                                                              //   "3px",
                                                            }}
                                                          >
                                                            <InputText
                                                              className="mgmtInsideTxtHeaderColumns"
                                                              value={
                                                                lineDetail[
                                                                  col
                                                                ] != " "
                                                                  ? lineDetail[
                                                                      col
                                                                    ]
                                                                  : ""
                                                              }
                                                              placeholder={
                                                                lineDetail[
                                                                  col
                                                                ] == " " &&
                                                                "text here..."
                                                              }
                                                              onChange={(e) =>
                                                                this.headerOnChange(
                                                                  e,
                                                                  lineDetail,
                                                                  col,
                                                                  heading
                                                                )
                                                              }
                                                              onBlur={() =>
                                                                this.headerOnBlur()
                                                              }
                                                              disabled={
                                                                linkSharedUser ==
                                                                3
                                                                  ? true
                                                                  : lineDetail.templateColumn2 ==
                                                                    "Date1"
                                                                  ? colIdx +
                                                                      1 <=
                                                                      mgmtStmtTemplateReqArray.length &&
                                                                    lineDetail.templateColumn2 ==
                                                                      "Date1"
                                                                    ? true
                                                                    : false
                                                                  : col ==
                                                                    "column5"
                                                                  ? false
                                                                  : true
                                                              }
                                                            />
                                                            {linkSharedUser !=
                                                              3 && (
                                                              <i
                                                                id={
                                                                  parentIdx ==
                                                                    0 &&
                                                                  lineDetailIdx ==
                                                                    7 &&
                                                                  colIdx == 3
                                                                    ? "mgmtDeleteHeader"
                                                                    : "mgmtDeleteHeader1"
                                                                }
                                                                className="fa fa-times-circle"
                                                                aria-hidden="true"
                                                                style={{
                                                                  display:
                                                                    linkSharedUser ==
                                                                      3 &&
                                                                    "none",
                                                                  visibility:
                                                                    lineDetail.templateColumn2 ==
                                                                    "Date1"
                                                                      ? colIdx +
                                                                          1 <=
                                                                          mgmtStmtTemplateReqArray.length &&
                                                                        "hidden"
                                                                      : col ==
                                                                        "column5"
                                                                      ? "visible"
                                                                      : "hidden",
                                                                }}
                                                                onClick={() => {
                                                                  this.deleteColumn(
                                                                    lineDetail,
                                                                    colIdx,
                                                                    heading
                                                                  );
                                                                }}
                                                              ></i>
                                                            )}
                                                          </Col>
                                                        );
                                                      }
                                                    }
                                                  }
                                                )}
                                                {/* 
                                                lineDetail.isHeader ? 
                                                      <div>
                                                        {lineDetail.column1}
                                                      </div> */}

                                                {headerLblArray.map(
                                                  (headerLbl, headerLblIdx) => {
                                                    // let columnLblSPlit = headerLbl.split('column')[1]
                                                    let columnNo =
                                                      headerLbl.split(
                                                        "column"
                                                      )[1];

                                                    if (
                                                      lineDetail.lineType ==
                                                        "Line" &&
                                                      lineDetail.dataType == ""
                                                    ) {
                                                      // return (
                                                      //   <Col
                                                      //     xs={2}
                                                      //     sm={2}
                                                      //     md={2}
                                                      //     lg={2}
                                                      //     xl={2}
                                                      //     style={{
                                                      //       width:
                                                      //         lineDetail.lineLevel ==
                                                      //         3
                                                      //           ? "15%"
                                                      //           : "12%",
                                                      //     }}
                                                      //   >
                                                      //     {headerLbl}
                                                      //   </Col>
                                                      // );
                                                      return (
                                                        <Col
                                                          xs={2}
                                                          sm={2}
                                                          md={2}
                                                          lg={2}
                                                          xl={2}
                                                          style={{
                                                            width:
                                                              lineDetail.lineLevel ==
                                                              3
                                                                ? "15%"
                                                                : "12%",
                                                          }}
                                                        >
                                                          <InputNumber
                                                            className={
                                                              lineDetail.lineLevel ==
                                                              3
                                                                ? "mgmtYearAmountBox"
                                                                : "mgmtAmountBox"
                                                            }
                                                            id="InputNumber1"
                                                            value={
                                                              lineDetail[
                                                                "column" +
                                                                  columnNo
                                                              ] != undefined
                                                                ? Math.round(
                                                                    lineDetail[
                                                                      "column" +
                                                                        columnNo
                                                                    ]
                                                                  )
                                                                : undefined
                                                            }
                                                            onBlur={(e) =>
                                                              this.amountOnBlur(
                                                                e,
                                                                heading,
                                                                lineDetail,
                                                                headerLblIdx,
                                                                columnNo
                                                              )
                                                            }
                                                            useGrouping={true}
                                                            disabled={
                                                              linkSharedUser ==
                                                                3 ||
                                                              lineDetail.column1 ==
                                                                ""
                                                            }
                                                            locale="sv-SE"
                                                          />

                                                          {headerLblArray.length ==
                                                            headerLblIdx + 1 &&
                                                            !lineDetail.isCustomRow &&
                                                            linkSharedUser !=
                                                              3 &&
                                                            lineDetail.dataType !=
                                                              "Static" && (
                                                              <i
                                                                id="MgmtStmtAddNewLineIcon"
                                                                className="fa fa-plus"
                                                                aria-hidden="true"
                                                                onClick={(e) =>
                                                                  this.addNewLine(
                                                                    lineDetail,
                                                                    heading
                                                                  )
                                                                }
                                                                title="Add New Line"
                                                                style={{
                                                                  display:
                                                                    linkSharedUser ==
                                                                      3 &&
                                                                    "none",
                                                                }}
                                                              ></i>
                                                            )}
                                                        </Col>
                                                      );
                                                    } else {
                                                      if (
                                                        lineDetail.isLineSum
                                                      ) {
                                                        return (
                                                          <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{
                                                              width: "15%",
                                                            }}
                                                          >
                                                            <InputNumber
                                                              inputId="integeronly"
                                                              className="mgmtColWiseAmountBoxDisabled"
                                                              value={Math.round(
                                                                lineDetail[
                                                                  "column" +
                                                                    columnNo
                                                                ]
                                                              )}
                                                              disabled
                                                              locale="sv-SE"
                                                            />
                                                          </Col>
                                                        );
                                                      }
                                                    }
                                                  }
                                                )}

                                                {headerLblArray.length == 0 && (
                                                  <Col
                                                    xs={2}
                                                    sm={2}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                    style={{
                                                      width: "12%",
                                                    }}
                                                  >
                                                    {lineDetail.isLineSum ? (
                                                      <InputNumber
                                                        // mode="decimal"
                                                        inputId="integeronly"
                                                        className="mgmtAmountBoxDisabled"
                                                        value={Math.round(
                                                          lineDetail.column2
                                                        )}
                                                        disabled
                                                        locale="sv-SE"
                                                      />
                                                    ) : lineDetail.dataType ==
                                                      "Static" ? (
                                                      <div>
                                                        {/* <InputText
                                                          value={
                                                            lineDetail.column2 !=
                                                              null &&
                                                            lineDetail.column2 !=
                                                              0
                                                              ? lineDetail.column2
                                                              : undefined
                                                          }
                                                          className="notesStringBox"
                                                          onChange={(e) => {
                                                            this.stringInputOnChange(
                                                              e,
                                                              heading,
                                                              lineDetail.lineNo
                                                            );
                                                          }}
                                                          onBlur={(e) => {
                                                            this.stringInputOnBlur(
                                                              e,
                                                              heading,
                                                              lineDetail.lineNo
                                                            );
                                                          }}
                                                          disabled={
                                                            linkSharedUser == 3
                                                          }
                                                        /> */}
                                                      </div>
                                                    ) : (
                                                      !lineDetail.isHeader && (
                                                        // lineDetail.isHeader ? (
                                                        //   <div>
                                                        //     {lineDetail.column1}
                                                        //   </div>
                                                        // ) :
                                                        <div>
                                                          <InputNumber
                                                            className="mgmtAmountBox"
                                                            id="InputNumber2"
                                                            value={
                                                              lineDetail.column2 !=
                                                              undefined
                                                                ? Math.round(
                                                                    lineDetail.column2
                                                                  )
                                                                : undefined
                                                            }
                                                            onBlur={(e) =>
                                                              this.amountOnBlur(
                                                                e,
                                                                heading,
                                                                lineDetail,
                                                                0,
                                                                0
                                                              )
                                                            }
                                                            useGrouping={true}
                                                            disabled={
                                                              linkSharedUser ==
                                                                3 ||
                                                              lineDetail.column1 ==
                                                                ""
                                                            }
                                                            locale="sv-SE"
                                                          />

                                                          {!lineDetail.isCustomRow &&
                                                            linkSharedUser !=
                                                              3 && (
                                                              <i
                                                                id="MgmtStmtAddNewLineIcon"
                                                                className="fa fa-plus"
                                                                aria-hidden="true"
                                                                onClick={(e) =>
                                                                  this.addNewLine(
                                                                    lineDetail,
                                                                    heading
                                                                  )
                                                                }
                                                                title="Add New Line"
                                                                style={{
                                                                  display:
                                                                    linkSharedUser ==
                                                                      3 &&
                                                                    "none",
                                                                }}
                                                              ></i>
                                                            )}
                                                        </div>
                                                      )
                                                    )}
                                                  </Col>
                                                )}
                                                {lineDetail.isCustomRow && (
                                                  <i
                                                    // id="mgmtCustomRowDelete"
                                                    id={
                                                      sideBarStatus
                                                        ? "mgmtCustomRowDeleteOpenSideBar"
                                                        : "mgmtCustomRowDeleteCloseSideBar"
                                                    }
                                                    className="pi pi-trash"
                                                    style={{
                                                      display:
                                                        linkSharedUser == 3 &&
                                                        "none",
                                                    }}
                                                    onClick={this.deleteCustomRow.bind(
                                                      this,
                                                      lineDetail,
                                                      heading
                                                    )}
                                                  />
                                                )}
                                              </Row>
                                            )
                                          ) : (
                                            <div className="mgmtTxtAreaDiv">
                                              <InputTextarea
                                                id="comments"
                                                placeholder={
                                                  lineDetail.placeHolder ==
                                                    "" &&
                                                  lineDetail.dataType ==
                                                    "Textarea"
                                                    ? "Comments"
                                                    : lineDetail.placeHolder
                                                }
                                                disabled={linkSharedUser == 3}
                                                value={lineDetail.name}
                                                className="mgmtTxtArea"
                                                onChange={(e) =>
                                                  this.txtAreaOnBlur(
                                                    e.target.value,
                                                    heading,
                                                    lineDetail,
                                                    false
                                                  )
                                                }
                                                rows={3}
                                                cols={30}
                                                onBlur={(e) =>
                                                  this.txtAreaOnBlur(
                                                    e.target.value,
                                                    heading,
                                                    lineDetail,
                                                    true
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            </AccordionTab>
                          );
                        }
                      }
                    )}
                  </Accordion>
                )}
              </Col>
              <center className="MgmtSaveBtnCenter">
                <Button
                  label={TranslationFile.previousBtn[userSelectedLanguage]}
                  aria-label="Annual Report"
                  onClick={() => this.pageNavigation("notes")}
                  id="annualReportBtn"
                  className="MgmtSaveBtn"
                />
                <Button
                  label={TranslationFile.nextBtn[userSelectedLanguage]}
                  aria-label="Annual Report"
                  onClick={() => this.pageNavigation("representative")}
                  id="annualReportBtn"
                  className="MgmtSaveBtn"
                />
              </center>
            </Row>
          )}
        </div>
        <ScrolltoTop />
        {/* <Footerpage sideBar={this.state.sideBarStatus} /> */}
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(ManagementStatementNew);
