import "./NavBarHeader.css";
import React, { Component } from "react";
import logo from "../../Assests/RebelSkool.png";
import { Image } from "primereact/image";
import Login from "../Login/LoginNew";
import { Button } from "primereact/button";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import $ from "jquery";
import svFlag from "../../Assests/sweden_flag.webp";
import enFlag from "../../Assests/USUK_Flag.jpg";
import { FiShoppingCart } from "react-icons/fi";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputNumber } from "primereact/inputnumber";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TranslationFile from "../15-Translation/locale/Translation.json";
import config from "../../Configuration/Config";

var size;
let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let mappingCartArrayLength = 0;
let mappingCartArray = [];
let cartValuesLocalData;
let totalPriceValue = 0;
let statusBar;
let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
const annualReport = "/annualreport/";
const dashboardURL = annualReport + sessionUUIDFromLS + "/dashboard";
const myProfile = annualReport + sessionUUIDFromLS + "/MyProfile";
const admin = annualReport + sessionUUIDFromLS + "/admin";


let currentUser = localStorage.getItem("rebelSkoolUser");
class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginList: "",
      sessionUUID: "",
      isPrivateUser: "",
      userNameFromLS: "",
    };
  }

  componentWillReceiveProps() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    // const { login, showWIPStatus } = this.props;

    if (currentUser) {
      currentUser = JSON.parse(localStorage.getItem("rebelSkoolUser"));
    }
    let filteredPeople = [];
    if (localStorage.getItem("cartValues") != "") {
      cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      filteredPeople =
        currentUser != undefined &&
        cartValuesLocalData.filter((item) => item.userID == currentUser.userID);
    }
    let applicationFilter = [];
    filteredPeople && filteredPeople.forEach((element) => {
      applicationFilter = element.cartArray
      applicationFilter = applicationFilter.filter((val) => val.application == "Annual Report")
    })
    mappingCartArray =
      filteredPeople &&
      filteredPeople.length > 0 &&
      applicationFilter;
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    if (currentUser) {
      currentUser = JSON.parse(localStorage.getItem("rebelSkoolUser"));
    }
    if (currentUser != null && currentUser["emailID"] != undefined) {
      this.setState({ showLoginList: true });
    } else {
      this.setState({ showLoginList: false });
    }
    if (currentUser != null && currentUser["userName"] !== undefined) {
      this.setState({ userNameFromLS: currentUser.userName });
    }
    if (
      currentUser != null &&
      (currentUser["isAccountingCompany"] == false ||
        currentUser["userRole"] == "User")
    ) {
      this.setState({ isPrivateUser: false });
    } else {
      this.setState({ isPrivateUser: true });
    }

    if (sessionUUIDFromLS) {
      this.setState({ sessionUUID: sessionUUIDFromLS });
    }
    let filteredPeople = [];
    if (localStorage.getItem("cartValues") != "") {
      cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      filteredPeople =
        currentUser != undefined &&
        cartValuesLocalData.filter((item) => item.userID == currentUser.userID);
    }
    let applicationFilter = [];
    filteredPeople && filteredPeople.forEach((element) => {
      applicationFilter = element.cartArray
      applicationFilter = applicationFilter.filter((val) => val.application == "Annual Report")
    })
    mappingCartArray =
      filteredPeople &&
      filteredPeople.length > 0 &&
      applicationFilter;
    $(document).ready(function ($) {
      //SMALLER HEADER WHEN SCROLL PAGE
      function smallerMenu() {
        var sc = $(window).scrollTop();
        if (sc > 1) {
          $("#header-sroll").addClass("small");
        } else {
          $("#header-sroll").removeClass("small");
        }
      }

      // VERIFY WINDOW SIZE
      function windowSize() {
        size = $(document).width();
        if (size >= 1440) {
          $(".corp-body").removeClass("open-menu");
          $(".hamburger-menu .bar").removeClass("animate");
        }
      }

      // ESC BUTTON ACTION
      $(document).keyup(function (e) {
        if (e.keyCode == 27) {
          $(".bar").removeClass("animate");
          $(".corp-body").removeClass("open-menu");
          $(
            "header .desk-menu .menu-container .menu .menu-item-has-children a ul"
          ).each(function (index) {
            $(this).removeClass("open-sub");
          });
        }
      });

      $("#cd-primary-nav > li").hover(function () {
        var $whidt_item = $(this).width();
        $whidt_item = $whidt_item - 8;

        var $prevEl = $(this).prev("li");
        var $preWidth = $(this).prev("li").width();
        var pos = $(this).position();
        pos = pos.left + 4;
        $("header .desk-menu .menu-container .menu>li.line").css({
          width: $whidt_item,
          left: pos,
          opacity: 1,
        });
      });

      // ANIMATE HAMBURGER MENU
      $(document).ready(function () {
        $(".hamburger-menu").on("click", function () {
          $(".hamburger-menu .bar").toggleClass("animate");
          if ($(".corp-body").hasClass("open-menu")) {
            $(".corp-body").removeClass("open-menu");
            window.location.reload(false);
            //exit
          } else {
            $(".corp-body").toggleClass("open-menu");
            var userSidebar =
              document.getElementsByClassName("user-sidebar")[0];
            if (userSidebar) {
              userSidebar.remove();
            }
            //entry
          }
        });
      });

      $(
        "header .desk-menu .menu-container .menu .menu-item-has-children ul"
      ).each(function (index) {
        $(this).append('<li class="back"><a href="#">Back</a></li>');
      });

      // RESPONSIVE MENU NAVIGATION
      $(
        "header .desk-menu .menu-container .menu .menu-item-has-children > a"
      ).on("click", function (e) {
        e.preventDefault();
        if (size <= 1440) {
          $(this).next("ul").addClass("open-sub");
        }
      });

      // RESPONSIVE MENU NAVIGATION FOR LOGIN
      $(
        "header .desk-menu .menu-container .menu .menu-item-has-children .corp-login .login-btn-user #login_li > a"
      ).on("click", function (e) {
        e.preventDefault();
        if (size <= 1440) {
          $(this).next("ul").addClass("open-sub");
        }
      });

      // CLICK FUNCTION BACK MENU RESPONSIVE
      $(
        "header .desk-menu .menu-container .menu .menu-item-has-children ul .back"
      ).on("click", function (e) {
        e.preventDefault();
        $(this).parent("ul").removeClass("open-sub");
      });

      $(".corp-body .over-menu").on("click", function () {
        window.location.reload(false);
        $(".corp-body").removeClass("open-menu");
        $(".bar").removeClass("animate");
      });

      $(document).ready(function () {
        windowSize();
      });

      $(window).scroll(function () {
        smallerMenu();
      });

      $(window).resize(function () {
        windowSize();
      });
    });
  }

  annualReport() {
    // const annualReport = "/annualreport/";
    // window.location.href =
    //   annualReport + this.state.sessionUUID + "/companyInfo";

    localStorage.setItem("navigateTo", "companyInformation");
    localStorage.setItem("navigateToGUID", "");
    localStorage.setItem("navigatedCompanyName", "");
    localStorage.setItem("possibleAccessablePages", "");

    window.location.href = "/annualreport/";
  }

  sruPage() {
    const sruPage = "income-declaration-2/";
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    window.location.href = config.apps.url + sruPage + sessionUUIDFromLS + "/companyInfo";
  }

  signOut(e) {
    const { isAppPortal, isHomePage } = this.props;
    e.preventDefault();
    localStorage.setItem("rebelSkoolUser", null);

    // if (isAppPortal) {
    //   window.location.href = "/annualreport";
    // } else {
    //   window.location.href = "/";
    // }

    window.location.href = "/";

    // window.location.href = "/annualreport";
    // let { login } = this.props;
    // login.values = undefined;
    // if (currentPageName.values == "Home")
    // window.location.reload(false);
  }

  scrollToContactUs() {
    this.props.callBack();
  }

  refresh() {
    let possibleNavigationPagesList = ["My Dashboard"];
    let possibleNavigationPagesListString = JSON.stringify(
      possibleNavigationPagesList
    );

    localStorage.setItem(
      "possibleAccessablePages",
      possibleNavigationPagesListString
    );

    setTimeout(() => {
      window.location.reload(false);
    }, 10);
  }
  flagOnClick(langCode) {
    if (langCode == "sw") {
      localStorage.setItem("selectedLanguage", "sw");
    } else {
      localStorage.setItem("selectedLanguage", "en");
    }
    window.location.reload(false);
  }

  callback = (annualReport, session, link, status) => {
    this.props.callBack(annualReport, session, link, true);
    // this.props.showStatusAlertFunc(true);
  };

  handleRemove(id) {
    let cartValuesLocalData =
      JSON.parse(localStorage.getItem("cartValues")) || [];

    if (currentUser && currentUser["emailID"] && currentUser["userName"]) {
      let filteredPeople = cartValuesLocalData.filter(
        (item) => item.userID == currentUser.userID
      );
      let applicationFilter = [];
      filteredPeople && filteredPeople.forEach((element) => {
        applicationFilter = element.cartArray
        applicationFilter = applicationFilter.filter((val) => val.application == "Annual Report")
      })
      let cartArrayList =
        filteredPeople &&
        filteredPeople.length > 0 &&
        applicationFilter;
      let otherPackages = cartArrayList.filter((item) => item.packageId != id);
      let arr = [],
        cartWithUserID = [];
      arr = cartArrayList.filter((item) => item.packageId != id);
      cartWithUserID = [
        {
          userID: currentUser.userID,
          cartArray: arr,
        },
      ];
      localStorage.setItem("cartValues", []);
      localStorage.setItem("cartValues", JSON.stringify(cartWithUserID));
      this.props.callBack(arr, "", "", false);
    }
  }

  handleChange(item, d) {
    let cartValuesLocalData =
      JSON.parse(localStorage.getItem("cartValues")) || [];
    if (currentUser && currentUser["emailID"] && currentUser["userName"]) {
      let filteredPeople = cartValuesLocalData.filter(
        (item) => item.userID == currentUser.userID
      );
      let cartArrayList =
        filteredPeople &&
        filteredPeople.length > 0 &&
        filteredPeople[0].cartArray;
      let cartValuesHandleChange = cartArrayList;
      let ind = -1;
      cartValuesHandleChange.forEach((data, index) => {
        if (data.packageId === item.packageId) ind = index;
      });
      cartValuesHandleChange[ind].quantity =
        cartValuesHandleChange[ind].quantity + d;

      let cartWithUserID = [
        {
          userID: currentUser.userID,
          cartArray: cartValuesHandleChange,
        },
      ];

      localStorage.setItem("cartValues", JSON.stringify(cartWithUserID));
      if (cartValuesHandleChange[ind].quantity == 0) {
        this.handleRemove(cartValuesHandleChange[ind].packageId);
      }

      this.props.callBack(item, "", "", false);
    }
  }

  handlePrice() {
    let filteredPeople = [];
    if (localStorage.getItem("cartValues") != "") {
      let cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      filteredPeople =
        currentUser != undefined &&
        cartValuesLocalData.filter((item) => item.userID == currentUser.userID);
    }
    let applicationFilter = [];
    filteredPeople && filteredPeople.forEach((element) => {
      applicationFilter = element.cartArray
      applicationFilter = applicationFilter.filter((val) => val.application == "Annual Report")
    })
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      applicationFilter;
    let priceValue = 0;
    if (cartArrayList.length != undefined) {
      cartArrayList.filter(
        (item) => (priceValue += item.quantity * item.price)
      );
    }
    totalPriceValue = priceValue;
  }

  proceedToPaymentPage() {
    const { sessionDetails, login } = this.props;
    const annualReport = "/annualreport/";
    // const session = sessionDetails["sessionDetails"].values.uuid;
    const session = this.props.uuid;
    const link = "/payment";
    this.props.callBack(annualReport, session, link, true);
  }

  refresh() {
    let possibleNavigationPagesList = ["My Dashboard"];
    let possibleNavigationPagesListString = JSON.stringify(
      possibleNavigationPagesList
    );

    localStorage.setItem(
      "possibleAccessablePages",
      possibleNavigationPagesListString
    );

    setTimeout(() => {
      window.location.reload(false);
    }, 10);
  }

  flagOnClick(langCode) {
    if (langCode == "sw") {
      localStorage.setItem("selectedLanguage", "sw");
    } else {
      localStorage.setItem("selectedLanguage", "en");
    }
    window.location.reload(false);
  }

  callback = (annualReport, session, link, status) => {
    this.props.callBack(annualReport, session, link, true);
    // this.props.showStatusAlertFunc(true);
  };

  handleLinkClick(e) {
    e.preventDefault();

    // Get the href URL from the <a> tag
    let possibleNavigationPagesList = ["My Dashboard"];
    let possibleNavigationPagesListString = JSON.stringify(
      possibleNavigationPagesList
    );
    localStorage.setItem(
      "possibleAccessablePages",
      possibleNavigationPagesListString
    );

    const href = e.currentTarget.getAttribute("href");
    window.location.href = href;

    // // Get the local storage value
    // let localStorageValue = localStorage.getItem("rebelSkoolUser");

    // if (localStorageValue != null) {
    //   localStorageValue = localStorageValue.slice(1, -1);

    //   // If local storage value exists, append it to the href URL
    //   const newHref = localStorageValue
    //     ? `${href}?localStorageValue=${localStorageValue}`
    //     : href;

    //   // Redirect to the new URL
    //   window.location.href = newHref;
    // } else {
    //   window.location.href = href;
    // }
  }

  render() {
    const { sessionUUID, showLoginList, isPrivateUser, userNameFromLS } =
      this.state;

    this.handlePrice();
    if (mappingCartArray.length == 0) {
      mappingCartArrayLength = 0;
    } else {
      mappingCartArrayLength = mappingCartArray.length;
    }

    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }

    return (
      <div className="corp-body">
        <div className="over-menu"></div>
        <div id="wrap">
          <header className="header" id="header-sroll">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="desk-menu">
                    <nav className="box-menu">
                      <div className="logo-corp">
                        <h1 className="logo-adn">
                          <a
                            href={
                              this.props.isHomePage
                                ? config.corporatePage.url
                                : "/annualreport"
                            }
                            onClick={this.handleLinkClick}
                          >
                            <Image
                              src={logo}
                              alt="Image"
                              className="corp-navbar-logo"
                            />
                          </a>
                          {/* <Link
                            to={
                              this.props.isAppPortal
                                ? this.props.isHomePage
                                  ? "/"
                                  : "/annualreport"
                                : "/"
                            }
                          >
                            <Image
                              src={logo}
                              alt="Image"
                              className="corp-navbar-logo"
                              onClick={() => this.refresh()}
                            />
                          </Link> */}
                        </h1>
                      </div>
                      {/* <div className={statusBar ? "menu-container":"menu-container1"}> */}
                      <div className="menu-container">
                        <div className={"menu-header-container"}>
                          <ul id="cd-primary-nav" className="menu">
                            <li className="menu-item menu-item-has-children">
                              <div className="corp-div-image-logo">
                                <Image
                                  src={logo}
                                  alt="Image"
                                  className="corp-navbar-logo"
                                />
                              </div>
                            </li>

                            <li className="menu-item menu-item-has-children">
                              <a href="">
                                {/* Online Services */}
                                {
                                  TranslationFile.NavbarOnlineServices[
                                  userSelectedLanguage
                                  ]
                                }
                                <span className="arrow"></span>
                              </a>
                              <ul className="sub-menu">
                                <li className="menu-item">
                                  {/* <a href="/annualreport"> */}
                                  <a
                                    // href="http://apps.rebelskool.com/annualreport"
                                    // href="http://apps.rebelskool.com/"
                                    href="/annualreport"
                                  // onClick={this.handleLinkClick}
                                  >
                                    {/* Annual Report */}
                                    {
                                      TranslationFile.sidebarText1[
                                      userSelectedLanguage
                                      ]
                                    }
                                  </a>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                  <a href="/income-declaration">
                                    {/* Income Declaration */}
                                    {
                                      TranslationFile.NavbarIncomeDeclaration[
                                      userSelectedLanguage
                                      ]
                                    }
                                    <i
                                      className="pi pi-angle-right"
                                      id="arrow_Navbar_IncomeDeclare"
                                      style={{ color: "#708090" }}
                                    ></i>
                                  </a>
                                  <ul className="sub-menu">
                                    <li className="menu-item">
                                      <a
                                        href={
                                          config.apps.url +
                                          "SRU_IncomeDeclaration"
                                        }
                                      >
                                        {/* <a href={config.SRU.url}> */}
                                        {/* SRU Files  */}
                                        {
                                          TranslationFile.NavbarSRUfiles[
                                          userSelectedLanguage
                                          ]
                                        }
                                      </a>
                                    </li>
                                    <li className="menu-item">
                                      <a href="/income-declaration-1">
                                        {/* NE appendix */}
                                        {
                                          TranslationFile.NavbarNEappendix[
                                          userSelectedLanguage
                                          ]
                                        }
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li className="menu-item">
                                  <span
                                  // onClick={() => this.scrollToContactUs()}
                                  >
                                    <a
                                      // to="/for-accounting-companies/"
                                      href={
                                        config.corporatePage.url +
                                        "for-accounting-companies/"
                                      }
                                      target={"_blank"}
                                    >
                                      {/* For Accounting Companies */}
                                      {
                                        TranslationFile
                                          .FooterForAccountingCompany[
                                        userSelectedLanguage
                                        ]
                                      }
                                    </a>
                                  </span>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href={
                                      config.corporatePage.url +
                                      "microsoft-business-central-development/"
                                    }
                                    target={"_blank"}
                                  >
                                    {/* Microsoft Business Central */}
                                    {
                                      TranslationFile
                                        .FooterMicrosoftBusinessCentral[
                                      userSelectedLanguage
                                      ]
                                    }
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <a href="">
                                {/* Prices */}
                                {
                                  TranslationFile.NavbarPrices[
                                  userSelectedLanguage
                                  ]
                                }
                                <span className="arrow"></span>
                              </a>

                              <ul className="sub-menu">
                                <li className="menu-item">
                                  <span
                                    onClick={() => this.scrollToContactUs()}
                                  >
                                    <HashLink to="/annualreport/#prices">
                                      {/* Annual Report */}
                                      {
                                        TranslationFile.sidebarText1[
                                        userSelectedLanguage
                                        ]
                                      }
                                    </HashLink>
                                  </span>
                                </li>
                                {/* <li className="menu-item">
                                  <a
                                    // href="/income-declaration-2/#prices"
                                    href="/annualreport/#prices"
                                    // href={config.SRU.url + "/#prices"}
                                    target={"_blank"}
                                  >
                                    {
                                      TranslationFile.FooterIncomeDeclaration[
                                      userSelectedLanguage
                                      ]
                                    }
                                  </a>
                                </li> */}
                              </ul>
                            </li>


                            <li className="menu-item menu-item-has-children">
                              <a href="">
                                {/* FAQ / References */}
                                {
                                  TranslationFile.FooterFAQReferences[
                                  userSelectedLanguage
                                  ]
                                }
                                <span className="arrow"></span>
                              </a>

                              <ul className="sub-menu">
                                <li className="menu-item menu-item-has-children">
                                  <a href="">
                                    {/* Annual Report */}
                                    {
                                      TranslationFile.sidebarText1[
                                      userSelectedLanguage
                                      ]
                                    }
                                    <i
                                      className="pi pi-angle-right"
                                      id="arrow_Navbar_Annualreport"
                                      style={{ color: "#708090" }}
                                    ></i>
                                  </a>
                                  <ul className="sub-menu">
                                    <li className="menu-item">
                                      <span
                                        onClick={() => this.scrollToContactUs()}
                                      >
                                        <HashLink to="/annualreport/#how-it-works">
                                          {/* How it works */}
                                          {
                                            TranslationFile.NavbarHowItworks[
                                            userSelectedLanguage
                                            ]
                                          }
                                        </HashLink>
                                      </span>
                                    </li>
                                    <li className="menu-item">
                                      <span
                                        onClick={() => this.scrollToContactUs()}
                                      >
                                        <HashLink to="/annualreport/#faq">
                                          FAQ
                                        </HashLink>
                                      </span>
                                    </li>
                                  </ul>
                                </li>

                                {/* <li className="menu-item menu-item-has-children">
                                  <a href="">
                                    SRU
                                    <i
                                      className="pi pi-angle-right"
                                      id="arrow_Navbar_Annualreport2"
                                      style={{ color: "#708090" }}
                                    ></i>
                                  </a>
                                  <ul className="sub-menu">
                                    <li className="menu-item">
                                      <span
                                        onClick={() => this.scrollToContactUs()}
                                      >
                                        <a
                                          href={
                                            config.SRU.url + "/#how-it-works"
                                          }
                                        >
                                          {
                                            TranslationFile.NavbarHowItworks[
                                            userSelectedLanguage
                                            ]
                                          }
                                        </a>
                                      </span>
                                    </li>
                                    <li className="menu-item">
                                      <span
                                        onClick={() => this.scrollToContactUs()}
                                      >
                                        <a href={config.SRU.url + "/#faq"}>
                                          FAQ
                                        </a>
                                      </span>
                                    </li>
                                  </ul>
                                </li> */}


                                <li className="menu-item">
                                  <span>
                                    <a
                                      href={
                                        config.corporatePage.url +
                                        "data-security/"
                                      }
                                      target={"_blank"}
                                    >
                                      {/* Data Security */}
                                      {
                                        TranslationFile.FooterDataSecurity[
                                        userSelectedLanguage
                                        ]
                                      }
                                    </a>
                                  </span>
                                </li>
                                <li className="menu-item">
                                  <span
                                  // onClick={() => this.scrollToContactUs()}
                                  >
                                    <a
                                      href={
                                        config.corporatePage.url +
                                        "customer-references/"
                                      }
                                      target={"_blank"}
                                    >
                                      {/* References */}
                                      {
                                        TranslationFile.NavbarReferences[
                                        userSelectedLanguage
                                        ]
                                      }
                                    </a>
                                  </span>
                                </li>
                                <li className="menu-item">
                                  <span
                                  // onClick={() => this.scrollToContactUs()}
                                  >
                                    <a
                                      href={
                                        config.corporatePage.url +
                                        "for-investors/"
                                      }
                                      target={"_blank"}
                                    >
                                      {/* For Investors */}
                                      {
                                        TranslationFile.FooterForInvestors[
                                        userSelectedLanguage
                                        ]
                                      }
                                    </a>
                                  </span>
                                </li>
                              </ul>
                            </li>

                            {/* {this.props.isCorpPortal && ( */}
                            <li className="menu-item menu-item-has-children">
                              <span>
                                <a
                                  href={config.corporatePage.url + "about-us/"}
                                  target={"_blank"}
                                >
                                  {/* About Us */}
                                  {
                                    TranslationFile.FooterAboutUs[
                                    userSelectedLanguage
                                    ]
                                  }
                                </a>
                              </span>
                            </li>
                            {/* )} */}

                            <li className="menu-item menu-item-has-children">
                              <span
                              // onClick={() => this.scrollToContactUs()}
                              >
                                <a
                                  href={
                                    config.corporatePage.url + "contact-us/"
                                  }
                                  target={"_blank"}
                                >
                                  {/* Contact Us */}
                                  {
                                    TranslationFile.ContactUsHeader[
                                    userSelectedLanguage
                                    ]
                                  }
                                </a>
                              </span>
                            </li>

                            {!this.state.showLoginList && (
                              <li className="menu-item menu-item-has-children">
                                <a
                                  href=""
                                  className="corp-tryfor-free"
                                  id="corp-tryfree-text"
                                >
                                  {/* Try For Free */}
                                  {
                                    TranslationFile.NavbarTryForFree[
                                    userSelectedLanguage
                                    ]
                                  }
                                  <span className="arrow"></span>
                                </a>
                                <ul className="sub-menu" id="corp-tryfree-text">
                                  <li className="menu-item">
                                    <span>
                                      <a onClick={() => this.annualReport()}>
                                        {/* Annual Report */}
                                        {
                                          TranslationFile.sidebarText1[
                                          userSelectedLanguage
                                          ]
                                        }
                                      </a>
                                    </span>
                                  </li>
                                  {/* <li className="menu-item">
                                    <span>
                                      <a onClick={() => this.sruPage()}>SRU</a>
                                    </span>
                                  </li> */}
                                </ul>
                              </li>
                            )}

                            {this.props.isAppPortal && (
                              <li className="menu-item menu-item-has-children">
                                <a href="" className="a-shopping-cart">
                                  <FiShoppingCart className="shopping-cart" />
                                  {mappingCartArrayLength > 0 &&
                                    currentUser["emailID"] &&
                                    currentUser["userName"] && (
                                      <span>{mappingCartArrayLength}</span>
                                    )}
                                </a>
                                {mappingCartArray.length > 0 &&
                                  mappingCartArray != undefined ? (
                                  // &&
                                  // currentUser.emailID &&
                                  // currentUser.userName
                                  <ul className="sub-menu">
                                    <li
                                      className="menu-item"
                                      style={{ padding: "8% -2%" }}
                                    >
                                      <div className="scrollpanel-demo">
                                        <ScrollPanel
                                          style={{ height: "500px" }}
                                          // style={{ maxHeight: '1200px',height:'100%' }}
                                          className="custombar1"
                                        >
                                          {mappingCartArray &&
                                            mappingCartArray.map((item) => (
                                              <div>
                                                <li
                                                  className="menu-item"
                                                  style={{ padding: "8% -2%" }}
                                                >
                                                  <Row>
                                                    <Col className="scroll-col">
                                                      {item.text1}
                                                      <br></br>
                                                      <span>{item.price}</span>
                                                      <span>kr</span>
                                                    </Col>
                                                    <Col className="scroll-col">
                                                      <Button
                                                        icon="pi pi-trash"
                                                        style={{
                                                          color: "black",
                                                        }}
                                                        text
                                                        aria-label="Cancel"
                                                        className="cart-col-4"
                                                        onClick={() =>
                                                          this.handleRemove(
                                                            item.packageId
                                                          )
                                                        }
                                                      />
                                                    </Col>
                                                  </Row>

                                                  <Row>
                                                    <Col className="scroll-col">
                                                      <span
                                                        style={{
                                                          display: "grid",
                                                          gridTemplateColumns:
                                                            "repeat(3,1fr)",
                                                        }}
                                                      >
                                                        <Button
                                                          icon="pi pi-minus"
                                                          text
                                                          outlined
                                                          aria-label="Cancel"
                                                          onClick={() =>
                                                            this.handleChange(
                                                              item,
                                                              -1
                                                            )
                                                          }
                                                        />
                                                        <InputNumber
                                                          style={{
                                                            width: "2em",
                                                            height: "2em",
                                                          }}
                                                          value={item.quantity}
                                                          className="cart-item-amount22"
                                                        />
                                                        <Button
                                                          icon="pi pi-plus"
                                                          text
                                                          aria-label="Cancel"
                                                          className="cart-plus-button"
                                                          onClick={() =>
                                                            this.handleChange(
                                                              item,
                                                              +1
                                                            )
                                                          }
                                                        />
                                                      </span>
                                                    </Col>
                                                    <Col className="scroll-col">
                                                      kr{" "}
                                                      {item.price *
                                                        item.quantity}
                                                    </Col>
                                                  </Row>
                                                </li>
                                              </div>
                                            ))}
                                        </ScrollPanel>
                                      </div>

                                      <div className="cart-total-price-div">
                                        <div className="cart-totalprice">
                                          <div className="cart-total-text">
                                            {/* Total Price */}
                                            {
                                              TranslationFile.TotalPriceText[
                                              userSelectedLanguage
                                              ]
                                            }
                                          </div>
                                          <div className="cart-total-end-price">
                                            <span>{totalPriceValue}</span>
                                            <span> kr </span>
                                          </div>
                                        </div>

                                        <div className="cart-checkout">
                                          {/* <Link to="/payment"> */}
                                          <Button
                                            label="Go To Checkout"
                                            rounded
                                            className="cart-checkout-btn22"
                                            onClick={() =>
                                              this.proceedToPaymentPage()
                                            }
                                          />

                                          {/* </Link> */}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                ) : (
                                  <ul className="sub-menu">
                                    <li
                                      className="menu-item"
                                      style={{ padding: "5%" }}
                                    >
                                      {/* Your Cart is empty */}
                                      {TranslationFile.Cart_Empty_Text[userSelectedLanguage]}

                                    </li>
                                  </ul>
                                )}
                              </li>
                            )}

                            <li className="menu-item menu-item-has-children">
                              {userSelectedLanguage == "sw" ? (
                                <a href="" className="Nav_LangID">
                                  <Image
                                    src={svFlag}
                                    width="40"
                                    height="25"
                                    className="lang-menu-flag"
                                  ></Image>
                                  <span className="arrow"></span>
                                </a>
                              ) : (
                                <a href="" className="Nav_LangID">
                                  <Image
                                    src={enFlag}
                                    width="40"
                                    height="25"
                                    className="lang-menu-flag"
                                  ></Image>
                                  <span className="arrow"></span>
                                </a>
                              )}

                              <ul className="sub-menu" id="lang-flag-ul">
                                <li className="menu-item">
                                  <Image
                                    src={svFlag}
                                    width="40"
                                    height="25"
                                    onClick={() => this.flagOnClick("sw")}
                                  ></Image>
                                </li>
                                <li className="menu-item">
                                  <Image
                                    src={enFlag}
                                    width="40"
                                    height="25"
                                    onClick={() => this.flagOnClick("en")}
                                  ></Image>
                                </li>
                              </ul>
                            </li>

                            {this.state.showLoginList ? (
                              <li className="menu-item menu-item-has-children">
                                <a href="">
                                  {/* About Us */}
                                  {/* Hi,  */}
                                  {TranslationFile.loginHiText[userSelectedLanguage]}
                                  {userNameFromLS}
                                  <span className="arrow"></span>
                                </a>
                                <ul className="sub-menu">
                                  <li className="menu-item">
                                    <a href={dashboardURL}>
                                      {/* Dashboard */}
                                      {TranslationFile.loginDashboardText[userSelectedLanguage]}
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a href={myProfile}>
                                      {/* My Profile */}
                                      {TranslationFile.myProfileText[userSelectedLanguage]}
                                    </a>
                                  </li>
                                  {isPrivateUser && (
                                    <li className="menu-item">
                                      <a href={admin}>
                                        {/* Manage Company Info/Users */}
                                        {TranslationFile.ManageCompanyInfo[userSelectedLanguage]}
                                      </a>
                                    </li>
                                  )}

                                  <li className="menu-item">
                                    <a onClick={(e) => this.signOut(e)}>
                                      {/* Sign Out */}
                                      {TranslationFile.SignOut_Text[userSelectedLanguage]}
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            ) : (
                              <li className="menu-item menu-item-has-children">
                                <div className="corp-login">
                                  <Login callback={this.callback} />
                                </div>
                              </li>
                            )}
                            <li className="line"></li>
                          </ul>
                        </div>
                      </div>
                      <div className="hamburger-menu">
                        <div className="bar"></div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  }
}
// export default connect(mapStateToProps, null)(Test);
export default Test;
