import slideList from "./ImageSlider";
import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import image1 from "../../Assests/image1.png";
import videoplay from "../../Assests/Videos/videoplay.mp4"
import "./ImageSlider.css";
import TranslationFile from "../15-Translation/locale/Translation.json";
import { RxHeight } from "react-icons/rx";

let userSelectedLanguage;
export default function DemoSlide() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  // console.log(TranslationFile.demoTitle1[userSelectedLanguage], "@@@");
  const slideListTemplate = (slideList) => {
    return (
      <div>
        <img src={slideList.img} className="demo-slide-image"></img>
      </div>
    );
  };
  return (
    <div className="caro-demo">
      <div>
        <div>
          <div className="caro-demo-slide">
            <center>
              <br></br>
              <h4 className="demo-title-heading">
                {TranslationFile.demoTitle1[userSelectedLanguage]}
                <br></br>
                <br></br>
                {TranslationFile.demoTitle2[userSelectedLanguage]}
              </h4>
            </center>
            <center>
              {/* <Carousel
                value={slideList}
                numVisible={1}
                numScroll={1}
                className="demo-work-carousel"
                circular={false}
                autoplayInterval={3000}
                itemTemplate={slideListTemplate}
              /> */}
              <video
                className="Demo_slide_video"
                style={{ width: "1050px", height: "590px" }}
                controls
                autoPlay
              >
                <source src={videoplay} />
              </video>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
