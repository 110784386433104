import React, { Component } from "react";
import { Fieldset } from "primereact/fieldset";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import axios from "axios";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import { connect } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import CusomtLabel from "../../Component/SliderLabels/SliderLabels";
import ReactSlider from "react-slider";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Password } from "primereact/password";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation.json";
import { ProgressSpinner } from "primereact/progressspinner";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import "./Merge.css";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import { Messages } from "primereact/messages";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

//Videos
import videoAddDeleteColumn from "../../Assests/Videos/Add_Delete_Column.mp4";
import videoDashboardNavigation from "../../Assests/Videos/Dashboard_Navigation.mp4";
import videoAddDeleteHeader from "../../Assests/Videos/Delete_Add_Header.mp4";

// import Footerpage from "../Footerpage/Footerpage";
// import Header from "../Menu/Header";
// import Upload from "../Upload/Upload";

const mapStateToProps = (state) => {
  return {
    companyInformation: state.companyInformation.companyInformation,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    currentPageName: state.currentPageName.currentPageName,
    readOnly: state.readOnly.readOnly,
    pricesToLogin: state.pricesToLogin.pricesToLogin,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
    // accessStatus: state.accessStatus.accessStatus,
  };
};
let sliderEventValue = 0;
let financialYearObj = {
  from: "",
  to: "",
},
  financialYearArray = [],
  SIEFileContentArray = [],
  sideBarGreenTickPages = [];
let companyInformationFyPropsValue = "";
let dataKeyIn = "";
let selectedFile = "",
  submissionLimitToDate,
  linkSharedUser,
  responsePaymentEntryNo = 0,
  isLoopDone = false,
  sliderCount = 0,
  annualReportHeaderRequestObj = {
    reportGUID: "",
    companyName: "",
    registrationNo: "",
    postalCode: 0,
    city: "",
    currentYear: "",
    previousYear1: "",
    previousYear2: "",
    previousYear3: "",
    createdByUserID: 0,
  },
  localData = "",
  localObject,
  dateValue,
  userDetailsFromLocalStorage,
  currentReportGUIDFromUrl,
  financialYearCalendarDatesArray = [],
  videoList = [];

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let userSelectedLanguage = localStorage.getItem("selectedLanguage");

let steps = [
  {
    content: TranslationFile.TourGuide_CI_Import_Btn[userSelectedLanguage],
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".import-btn-upload",
    title: "Import SIE",
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_CI_YearSelect_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".slider__container",
    title: "Financial Year",
    disableBeacon: true,
  },
];

class CompanyInformation extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      organizationNumber: "",
      disableCompanyNo: false,
      companyName: "",
      postalCode: null,
      city: "",
      isWrongOrganizationNo: false,
      checkCompanyName: false,
      isWrongPostalcodeFormat: false,
      checkCity: false,
      isExistingOrganization: false,
      fetchOrgNoApiCallStatus: false,
      importSIEFile: false,
      isOrganizationNoMissing: false,
      isOrganizationNameMissing: false,
      isPostalCodeMissing: false,
      isCityMissing: false,
      callBackFromMergeYearValue: "",
      displayLabels: false,
      sliderValue: 0,
      financialYearSliderValue: 0,
      financialYearArrayState: [],
      SIEMissingFieldsErrorArrayState: [],
      visible: false,
      showProceedButton: false,
      isNavigated: false,
      showUserNamePasswordDialogBox: false,
      newUserPassword: "",
      newUserConfirmPassword: "",
      newUserName: "",
      newLastName: "",
      newUserUserId: "",
      newUserEmailId: "",
      currentStartingDate: "",
      calendarDateHardCodedValue: "",
      userSelectedDateState: "",
      isProceedButtonClicked: false,
      uploadedSIEFile: "",
      currentReportID: "",
      // hidePage:'',
      reportAccessStatusFromProps: "",
      randomVideoFileName: "",
      randomVideoFileTitle: "",
      tipsDialog: false,
      SIEErrors: "",
      run: false,
      showRemoveFYConfimationDialog: false,
    };

    this.setLabels = this.setLabels.bind(this);
    this.reactSlider = null;
    this.setReactSliderRef = this.setReactSliderRef.bind(this);
    this.callbackSIE = this.callbackSIE.bind(this);
    this.dateOnChange = this.dateOnChange.bind(this);
  }

  callbackRemoveSIE = (SIEError) => {
    this.state({
      SIEMissingFieldsErrorArrayState: [],
    });
  };

  callbackSIE = (SIEFile) => {
    const { financialYearArrayState, organizationNumber, companyName } =
      this.state;
    let { companyInformation, login, sessionDetails } = this.props;

    let SIEFinancialYearArray = [];

    let companyInformationValue = {
      organizationname: "",
      organizationnumber: "",
      postalcode: "",
      city: "",
      financialYear: "",
    };

    let isSIEOrgNoExist = false,
      SIEOrgNoError = "",
      SIEFinancialYearError = "",
      SIEInvalidFinancialYearLineNo = 0,
      isSIEOrgNameExist = false,
      isSIEFinancialYearExist = true;

    SIEFile.length &&
      SIEFile.map((i, idx) => {
        let SIEFinancialYearObj = {
          from: "",
          to: "",
        };

        if (i.includes("#ORGNR")) {
          let SIEOrgNo = i.split("#ORGNR");
          let organizationNo = SIEOrgNo[1].replace(" ", "");
          if (SIEOrgNo.length > 1 && organizationNo != "") {
            let organizationNoCount = organizationNo.length;

            if (organizationNoCount < 10 || organizationNoCount > 11) {
              SIEOrgNoError = "Orgnization No. (#ORGNR) is invalid";
            } else {
              if (organizationNoCount == 11) {
                this.setState({
                  organizationNumber: organizationNo,
                });

                companyInformationValue.organizationnumber = organizationNo;
                annualReportHeaderRequestObj.registrationNo = organizationNo;
              } else {
                var chr = "-";
                var pos = 6;

                organizationNo =
                  organizationNo.substring(0, pos) +
                  chr +
                  organizationNo.substring(pos, organizationNo.length);
                this.setState({
                  organizationNumber: organizationNo,
                });

                annualReportHeaderRequestObj.registrationNo = organizationNo;
              }
              isSIEOrgNoExist = true;
            }
          }
        }

        if (i.includes("#FNAMN")) {
          let SIEOrgName = i.split("#FNAMN");
          if (SIEOrgName.length > 1 && SIEOrgName[1] != "") {
            let orgName = SIEOrgName[1].replace(/['"]+/g, "").substring(1);
            this.setState({
              companyName: orgName,
            });
            isSIEOrgNameExist = true;

            companyInformationValue.organizationname = orgName;
            annualReportHeaderRequestObj.companyName = orgName;
          }
        }

        let formattedFromDateString = "",
          formattedToDateString = "";
        if (i.includes("#RAR")) {
          let SIEFinancialYear = i.split(" ");
          if (SIEFinancialYear.length > 1) {
            let SIEFromDate = SIEFinancialYear[2];
            if (SIEFromDate.length == 8) {
              formattedFromDateString =
                SIEFromDate.slice(0, 4) +
                "-" +
                SIEFromDate.slice(4, 6) +
                "-" +
                SIEFromDate.slice(6, 8);

              SIEFromDate = new Date(formattedFromDateString);
            } else {
              isSIEFinancialYearExist = false;
              SIEInvalidFinancialYearLineNo = idx + 1;

              if (SIEFinancialYearError != "") {
                SIEFinancialYearError += "," + SIEInvalidFinancialYearLineNo;
              } else {
                SIEFinancialYearError =
                  "Invalid Financial Year (#RAR) in line No: " +
                  SIEInvalidFinancialYearLineNo;
              }
            }

            let SIEToDate = SIEFinancialYear[3];

            if (SIEToDate.length == 8) {
              formattedToDateString =
                SIEToDate.slice(0, 4) +
                "-" +
                SIEToDate.slice(4, 6) +
                "-" +
                SIEToDate.slice(6, 8);

              SIEToDate = new Date(formattedToDateString);
            } else {
              isSIEFinancialYearExist = false;
              if (idx + 1 != SIEInvalidFinancialYearLineNo) {
                if (SIEFinancialYearError != "") {
                  SIEFinancialYearError += "," + (idx + 1);
                } else {
                  SIEFinancialYearError =
                    "Invalid Financial Year (#RAR) in line No: " + (idx + 1);
                }
              }
            }

            if (isSIEFinancialYearExist) {
              SIEFinancialYearObj.from = SIEFromDate;
              SIEFinancialYearObj.to = SIEToDate;

              SIEFinancialYearArray.push(SIEFinancialYearObj);
              companyInformationValue.financialYear = SIEFinancialYearArray;
            }

            let dateRange =
              formattedFromDateString + " - " + formattedToDateString;

            switch (SIEFinancialYear[1]) {
              case "0":
                annualReportHeaderRequestObj.currentYear = dateRange;
                break;
              case "-1":
                annualReportHeaderRequestObj.previousYear1 = dateRange;
                break;
              case "-2":
                annualReportHeaderRequestObj.previousYear2 = dateRange;
                break;
              case "-3":
                annualReportHeaderRequestObj.previousYear3 = dateRange;
                break;
            }
          }
        }
      });

    if (!isSIEOrgNoExist) {
      if (SIEOrgNoError == "") {
        SIEOrgNoError = "Orgnization No. (#ORGNR) is missing";
      }
      this.setState((prevState) => ({
        SIEMissingFieldsErrorArrayState: [
          ...prevState.SIEMissingFieldsErrorArrayState,
          SIEOrgNoError,
        ],
      }));
    }

    if (!isSIEOrgNameExist) {
      this.setState((prevState) => ({
        SIEMissingFieldsErrorArrayState: [
          ...prevState.SIEMissingFieldsErrorArrayState,
          "Orgnization Name (#FNAMN)",
        ],
      }));
    }

    if (!isSIEFinancialYearExist) {
      this.setState((prevState) => ({
        SIEMissingFieldsErrorArrayState: [
          ...prevState.SIEMissingFieldsErrorArrayState,
          SIEFinancialYearError,
        ],
      }));
    }

    if (SIEFinancialYearArray.length > 0) {
      this.setState({
        sliderValue: SIEFinancialYearArray.length - 1,
        financialYearArrayState: SIEFinancialYearArray,
      });
    }

    if (!isSIEOrgNoExist || !isSIEOrgNameExist || !isSIEFinancialYearExist) {
      this.setState({
        organizationNumber: "",
        companyName: "",
        sliderValue: "",
        financialYearArrayState: "",
      });
    } else {
      this.setState({
        visible: false,
        showProceedButton: false,
        disableCompanyNo: true,
      });

      this.insertAnnualReportHeader();
    }
    companyInformation.values = companyInformationValue;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;

    setTimeout(() => {
      this.setState({ run: userTourGuide });
    }, 200);

    const { isNavigated } = this.state;

    if (this.reactSlider) {
      this.setLabels();
    }

    this.setState({
      disableCompanyNo: false,
    });

    if (!isNavigated) {
      setTimeout(() => {
        localStorage.setItem("navigateTo", "");
        localStorage.setItem("navigateToGUID", "");
      }, 1000);
    }
  }

  setPlayBack() {
    this.videoRef.current.playbackRate = 0.7;
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    let {
      companyInformation,
      sessionDetails,
      currentPageName,
      sideBarReducer,
      readOnly,
    } = this.props;

    // axios.get("/api/start").then((response) => {
    //   axios.defaults.headers.common["X-CSRF-TOKEN"] = response.data.csrfToken;
    // });

    videoList = [
      {
        videoPath: videoAddDeleteColumn,
        videoTitle: "Add or Delete Column",
      },
      {
        videoPath: videoDashboardNavigation,
        videoTitle: "Navigate to the report from dashboard",
      },
      {
        videoPath: videoAddDeleteHeader,
        videoTitle: "Add or Delete Header",
      },
    ];

    const randomIndex = Math.floor(Math.random() * videoList.length);

    this.setState({
      randomVideoFileName: videoList[randomIndex].videoPath,
      randomVideoFileTitle: videoList[randomIndex].videoTitle,
    });

    currentPageName.values = "Company Information";
    localStorage.setItem("currentPageName", "Income Statement");

    localData = localStorage.getItem("rebelSkoolUser");
    localObject = localData && JSON.parse(localData);
    if (localObject) {
      this.setState({
        newUserUserId: localObject.userID,
        newUserEmailId: localObject.emailID,
      });
      if (localObject.userName == "") {
        this.setState({ showUserNamePasswordDialogBox: true });
      }
    }

    // sideBarGreenTickPages = [];
    sideBarGreenTickPages.push("My Dashboard");
    let updatePossibleNavigationPagesListString = JSON.stringify(
      sideBarGreenTickPages
    );
    localStorage.setItem(
      "possibleAccessablePages",
      updatePossibleNavigationPagesListString
    );

    let navigatedFromDashboardFYs = [];

    annualReportHeaderRequestObj.reportGUID =
      sessionDetails.sessionDetails.values.uuid;

    let navigateToReportGUID = localStorage.getItem("navigateToGUID");
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data.paymentEntryNo != null) {
            responsePaymentEntryNo = response.data.paymentEntryNo;
          }
          let responseData = response.data;
          // if(accessStatus && accessStatus.values != undefined) {

          //   this.setState({reportAccessStatusFromProps:accessStatus.values.reportAccessStatus})
          // }
          if (responseData.reportID != null) {
            // this.setState({hidePage: false})
            let reportAccessReq = {
              reportId: responseData.reportID,
            };
            axios
              .post(
                "/annualReport/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {
                  case 2:
                    linkSharedUser = 2; //Write
                    break;
                  case 3:
                    linkSharedUser = 3; //Read Only
                    break;
                  case 4:
                    linkSharedUser = 4; //No Access
                    break;
                }

                if (responseData.packageEntryNo > 0) {
                  readOnly.values = true;
                }
                if (responseData.currentYear != "")
                  this.formatDateAndCreateFinancialYearArray(
                    responseData.currentYear,
                    navigatedFromDashboardFYs,
                    0
                  );

                if (responseData.previousYear1 != "")
                  this.formatDateAndCreateFinancialYearArray(
                    responseData.previousYear1,
                    navigatedFromDashboardFYs,
                    1
                  );

                if (responseData.previousYear2 != "")
                  this.formatDateAndCreateFinancialYearArray(
                    responseData.previousYear2,
                    navigatedFromDashboardFYs,
                    2
                  );
                if (responseData.postalCode == 0) {
                  this.setState({ postalCode: "" });
                } else {
                  this.setState({ postalCode: responseData.postalCode });
                }
                this.setState({
                  organizationNumber: responseData.registrationNo,
                  companyName: responseData.companyName,
                  postalCode: responseData.postalCode,
                  city: responseData.city,
                  uploadedSIEFile: responseData.sIEFile,
                });

                let navigatedResponseObj = {
                  organizationnumber: responseData.registrationNo,
                  organizationname: responseData.companyName,
                  postalcode: responseData.postalCode,
                  city: responseData.city,
                  financialYear: navigatedFromDashboardFYs,
                };
                annualReportHeaderRequestObj.registrationNo =
                  responseData.registrationNo;
                annualReportHeaderRequestObj.companyName =
                  responseData.companyName;
                annualReportHeaderRequestObj.postalCode =
                  responseData.postalCode;
                annualReportHeaderRequestObj.city = responseData.city;
                annualReportHeaderRequestObj.companyName =
                  responseData.companyName;

                companyInformation.values = navigatedResponseObj;
                if (
                  companyInformation.values.financialYear != undefined &&
                  companyInformation.values.financialYear.length > 0
                ) {
                  submissionLimitToDate = moment(
                    companyInformation.values.financialYear[0].to
                  )
                    .add(7, "month")
                    .subtract(1, "day")._d;
                  submissionLimitToDate = moment(submissionLimitToDate).format(
                    "YYYY-MM-DD"
                  );
                }

                axios
                  .get(
                    "/annualReport/getCompletedPages?reportId=" +
                    responseData.reportID,
                    userSessionAuthKeyReturnValue
                  )
                  .then((completedStatementTypesResponse) => {
                    sideBarReducer.values =
                      completedStatementTypesResponse.data;
                    sideBarGreenTickPages = [];
                    sideBarGreenTickPages =
                      completedStatementTypesResponse.data;
                    sideBarGreenTickPages.push(
                      "My Dashboard",
                      "Company Information"
                    );
                    let updatePossibleNavigationPagesListString =
                      JSON.stringify(sideBarGreenTickPages);
                    localStorage.setItem(
                      "possibleAccessablePages",
                      updatePossibleNavigationPagesListString
                    );
                    this.setState({
                      reRender: true,
                    });
                  });
              })
              .catch((error) => {
                let noAccessURL =
                  "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                this.props.history.push(noAccessURL);
              });
          } else {
          }
          this.setState({ currentReportID: response.data.reportID });

          // }
        })
        .catch((error) => {
          let noAccessURL =
            "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
          this.props.history.push(noAccessURL);
        });
    } else {
      axios.get("/user/saveAnonymousUser").then((response) => {
        if (response.data) {
          let anonymousUser = {
            ["User-Session-Auth-Key"]: response.data,
          };
          localStorage.setItem("rebelSkoolUser", JSON.stringify(anonymousUser));
          userSessionAuthKeyReturnValue = setUserSessionAuthKey();

          axios
            .get(
              "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
              userSessionAuthKeyReturnValue
            )
            .then((response) => { })
            .catch((error) => {
              let noAccessURL =
                "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
              this.props.history.push(noAccessURL);
            });
        }
      });
    }

    if (companyInformation.values != undefined) {
      this.setState({
        organizationNumber: companyInformation.values.organizationnumber,
        companyName: companyInformation.values.organizationname,
        postalCode: companyInformation.values.postalcode,
        city: companyInformation.values.city,
        financialYearArrayState: companyInformation.values.financialYear,
        sliderValue: companyInformation.values.financialYear.length - 1,
      });
    }
  }

  updatePossibleAccessPages() {
    let getPossibleAccessablePages = localStorage.getItem(
      "possibleAccessablePages"
    );
    let getPossibleAccessablePagesArray = JSON.parse(
      getPossibleAccessablePages
    );

    getPossibleAccessablePagesArray = JSON.parse(getPossibleAccessablePages);

    if (getPossibleAccessablePagesArray == null) {
      let possibleNavigationPagesList = ["My Dashboard", "Company Information"];
      let possibleNavigationPagesListString = JSON.stringify(
        possibleNavigationPagesList
      );
      localStorage.setItem(
        "possibleAccessablePages",
        possibleNavigationPagesListString
      );
    } else if (
      getPossibleAccessablePagesArray &&
      getPossibleAccessablePagesArray.length &&
      getPossibleAccessablePagesArray.length <= 2
    ) {
      let possibleNavigationPagesList = ["My Dashboard", "Company Information"];
      let possibleNavigationPagesListString = JSON.stringify(
        possibleNavigationPagesList
      );
      localStorage.setItem(
        "possibleAccessablePages",
        possibleNavigationPagesListString
      );
    }
  }

  formatDateAndCreateFinancialYearArray(
    responseData,
    navigatedFromDashboardFYs,
    arrayIdx
  ) {
    let respDataCurrentYear = responseData && responseData.split(" - ");
    let fromDate = respDataCurrentYear[0];
    let toDate = respDataCurrentYear[1];

    fromDate = new Date(fromDate);
    toDate = new Date(toDate);

    let yearObj = {
      from: fromDate,
      to: toDate,
    };

    navigatedFromDashboardFYs[arrayIdx] = yearObj;

    switch (arrayIdx) {
      case 0:
        annualReportHeaderRequestObj.currentYear = fromDate + " - " + toDate;
        break;

      case 1:
        annualReportHeaderRequestObj.previousYear1 = fromDate + " - " + toDate;
        break;

      case 2:
        annualReportHeaderRequestObj.previousYear2 = fromDate + " - " + toDate;
        break;

      case 3:
        annualReportHeaderRequestObj.previousYear3 = fromDate + " - " + toDate;
        break;
    }

    this.setState({
      sliderValue: arrayIdx,
      financialYearSliderValue: arrayIdx,
      financialYearArrayState: navigatedFromDashboardFYs,
    });

    financialYearCalendarDatesArray = navigatedFromDashboardFYs;
  }

  setReactSliderRef(element) {
    this.reactSlider = element;
  }

  setLabels() {
    this.setState({ displayLabels: true });
  }

  resetCompanyInforState() {
    this.setState({
      organizationNumber: "",
      companyName: "",
      postalCode: "",
      city: "",
    });
  }

  organizationNoOnComplete(e) {
    let { companyInformation, sessionDetails, login } = this.props;

    this.setState({
      organizationNumber: e.value,
    });
    annualReportHeaderRequestObj.registrationNo = e.value;
    annualReportHeaderRequestObj.reportGUID =
      sessionDetails.sessionDetails.values.uuid;
    // (annualReportHeaderRequestObj.createdByUserID =
    //   login == undefined ? 0 : login.userID),

    let companyInformationValue = {
      organizationname: "",
      organizationnumber: "",
      postalcode: "",
      city: "",
    };

    if (userSessionAuthKeyReturnValue) {
      axios
        .get("/organization/get/" + e.value, userSessionAuthKeyReturnValue)
        .then((response) => {
          if (response.data != "") {
            this.setState({
              organizationNumber: response.data.organizationnumber,
              companyName: response.data.organizationname,
              postalCode: response.data.postalcode,
              city: response.data.city,
            });

            companyInformationValue = {
              organizationname: response.data.organizationname,
              organizationnumber: response.data.organizationnumber,
              postalcode: response.data.postalcode,
              city: response.data.city,
            };

            annualReportHeaderRequestObj.companyName =
              response.data.organizationname;
            annualReportHeaderRequestObj.postalCode = response.data.postalcode;
            annualReportHeaderRequestObj.city = response.data.city;
          } else {
            companyInformationValue.organizationnumber = e.value;
          }
          companyInformation.values = companyInformationValue;

          this.insertAnnualReportHeader();
        });
      this.updatePossibleAccessPages();
    }
  }

  organizationNoOnChange(e) {
    if (e.value == "") {
      this.resetCompanyInforState();
    }
  }

  companyNameOnChange(e) {
    this.setState({
      companyName: e.target.value,
    });
  }

  companyNameOnBlur(e) {
    let companyNameValue = e.target.value;
    if (companyNameValue != "") {
      annualReportHeaderRequestObj.companyName = companyNameValue;
      this.insertAnnualReportHeader();
      this.updatePossibleAccessPages();
    }
  }

  PostalcodeOnChange(e) {
    this.setState({
      postalCode: e.target.value,
    });
  }

  postalCodeOnBlur(e) {
    const { companyInformation } = this.props;

    let postalCodeValue = e.target.value;
    // if (postalCodeValue != "") {
    annualReportHeaderRequestObj.postalCode = postalCodeValue;

    companyInformation.values.postalcode = postalCodeValue;

    this.insertAnnualReportHeader();
    this.updatePossibleAccessPages();
    // }
  }

  cityOnChange(e) {
    this.setState({
      city: e.target.value,
    });
  }

  cityOnBlur(e) {
    const { companyInformation } = this.props;
    let cityValue = e.target.value;
    // if (cityValue != "") {
    annualReportHeaderRequestObj.city = cityValue;
    companyInformation.values.city = cityValue;
    this.insertAnnualReportHeader();
    this.updatePossibleAccessPages();
    // }
  }

  dateOnBlurNew(e, idx, isFromDate, isToDate) {
    const { calendarDateHardCodedValue } = this.state;
    dataKeyIn = "";

    if (calendarDateHardCodedValue != "") {
      if (calendarDateHardCodedValue.length == 6) {
        var year = calendarDateHardCodedValue.substr(0, 2);
        var month = calendarDateHardCodedValue.substr(2, 2);
        var date = calendarDateHardCodedValue.substr(4, 4);
        var newdate = "20" + year;
        newdate = newdate + "-" + month + "-" + date;
        dateValue = new Date(newdate);
      } else if (calendarDateHardCodedValue.length == 8) {
        if (calendarDateHardCodedValue.includes("-")) {
          let splittedFormat = calendarDateHardCodedValue.split("-");
          var year = "20" + splittedFormat[0];
          var month = splittedFormat[1];
          var date = splittedFormat[2];
          var newdate = year + "-" + month + "-" + date;

          dateValue = new Date(newdate);
        } else {
          var year = calendarDateHardCodedValue.substr(0, 4);
          var month = calendarDateHardCodedValue.substr(4, 2);
          var date = calendarDateHardCodedValue.substr(6, 5);
          var newdate = year;
          newdate = newdate + "-" + month + "-" + date;
          dateValue = new Date(newdate);
        }
      }

      if (isFromDate) {
        let financialYearDateOnBlurObj = {
          from: dateValue,
          to: moment(dateValue).add(12, "month").subtract(1, "day")._d,
        };

        financialYearCalendarDatesArray[idx] = financialYearDateOnBlurObj;
      }

      if (isToDate) {
        if (financialYearCalendarDatesArray.length) {
          financialYearCalendarDatesArray[idx].to = dateValue;
        }
      }

      financialYearCalendarDatesArray.map((i, idx) => {
        switch (idx) {
          case 0:
            annualReportHeaderRequestObj.currentYear =
              moment(i.from).format("YYYY-MM-DD") +
              " - " +
              moment(i.to).format("YYYY-MM-DD");
            break;
          case 1:
            annualReportHeaderRequestObj.previousYear1 =
              moment(i.from).format("YYYY-MM-DD") +
              " - " +
              moment(i.to).format("YYYY-MM-DD");
            break;
          case 2:
            annualReportHeaderRequestObj.previousYear2 =
              moment(i.from).format("YYYY-MM-DD") +
              " - " +
              moment(i.to).format("YYYY-MM-DD");
            break;
          case 3:
            annualReportHeaderRequestObj.previousYear3 =
              moment(i.from).format("YYYY-MM-DD") +
              " - " +
              moment(i.to).format("YYYY-MM-DD");
            break;
        }
      });

      this.insertAnnualReportHeader();
      this.updatePossibleAccessPages();
    }

    this.setState({
      reRender: true,
    });
  }

  dateOnBlur(e, idx, isFromDate, isToDate) {
    const { financialYearArrayState } = this.state;
    let { currentStartingDate } = this.state;
    dataKeyIn = "";
    if (currentStartingDate == "") {
      submissionLimitToDate = "";

      financialYearObj.from = e.target.value;
      financialYearObj.to = moment(e.target.value)
        .add(12, "month")
        .subtract(1, "day")._d;

      this.setState({
        reRender: true,
        financialYearArrayState: "",
      });
    } else {
      if (currentStartingDate.length == 6) {
        var year = currentStartingDate.substr(0, 2);
        var month = currentStartingDate.substr(2, 2);
        var date = currentStartingDate.substr(4, 4);
        var newdate = "20" + year;
        newdate = newdate + "-" + month + "-" + date;
        dateValue = new Date(newdate);
        this.setState({
          reRender: true,
        });
      } else if (currentStartingDate.length == 8) {
        if (currentStartingDate.includes("-")) {
          let splittedFormat = currentStartingDate.split("-");
          var year = "20" + splittedFormat[0];
          var month = splittedFormat[1];
          var date = splittedFormat[2];
          var newdate = year + "-" + month + "-" + date;

          dateValue = new Date(newdate);
        } else {
          var year = currentStartingDate.substr(0, 4);
          var month = currentStartingDate.substr(4, 2);
          var date = currentStartingDate.substr(6, 5);
          var newdate = year;
          newdate = newdate + "-" + month + "-" + date;
          dateValue = new Date(newdate);
        }

        this.setState({
          reRender: true,
        });
      }

      let fromToDateRange = "";

      if (isFromDate) {
        financialYearObj.from = dateValue;

        financialYearObj.to = moment(financialYearObj.from)
          .add(12, "month")
          .subtract(1, "day")._d;
        let fromDate = moment(financialYearObj.from).format("YYYY-MM-DD");
        let toDate = moment(financialYearObj.to).format("YYYY-MM-DD");

        fromToDateRange = fromDate + " - " + toDate;
      }

      if (isToDate) {
        financialYearObj.from = financialYearArrayState[idx].from;
        financialYearObj.to = dateValue;
        // financialYearObj.from = moment(financialYearObj.to).subtract(12,"month").add(1,"day")._d;
        let fromDate = moment(financialYearObj.from).format("YYYY-MM-DD");
        let toDate = moment(financialYearObj.to).format("YYYY-MM-DD");

        fromToDateRange = fromDate + " - " + toDate;
      }

      let tempFinancialYearObj = [];
      tempFinancialYearObj.push(financialYearObj);
      this.setState({ financialYearArrayState: tempFinancialYearObj });
      // financialYearArrayState[idx] = financialYearObj;

      ////////

      // submissionLimitToDate = moment(financialYearArrayState[0].to)
      //   .add(7, "month")
      //   .subtract(1, "day")._d;
      // submissionLimitToDate = moment(submissionLimitToDate).format(
      //   "YYYY-MM-DD"
      // );

      ////////

      switch (idx) {
        case 0:
          annualReportHeaderRequestObj.currentYear = fromToDateRange;
          annualReportHeaderRequestObj.previousYear1 = "";
          annualReportHeaderRequestObj.previousYear2 = "";
          break;

        case 1:
          annualReportHeaderRequestObj.previousYear1 = fromToDateRange;
          annualReportHeaderRequestObj.previousYear2 = "";
          break;

        case 2:
          annualReportHeaderRequestObj.previousYear2 = fromToDateRange;
          break;
      }

      this.insertAnnualReportHeader();
    }
  }

  dateOnInput(e, idx, isFromDate, isToDate) { }

  dateOnChangeNew(dateEvent, idx, isFromDate, isToDate) {
    const { companyInformation } = this.props;
    const { calendarDateHardCodedValue } = this.state;

    if (dateEvent.value != null) {
      if (isFromDate) {
        let financialYearDateOnChangeObj = {
          from: dateEvent.value,
          to: moment(dateEvent.value).add(12, "month").subtract(1, "day")._d,
        };

        financialYearCalendarDatesArray[idx] = financialYearDateOnChangeObj;
      }

      if (isToDate) {
        if (financialYearCalendarDatesArray.length) {
          financialYearCalendarDatesArray[idx].to = dateEvent.value;
        }
      }

      if (companyInformation.values == undefined) {
        let companyInformationEmptyObj = {
          organizationname: "",
          organizationnumber: "",
          postalcode: "",
          city: "",
          financialYear: "",
        };

        companyInformationEmptyObj.financialYear =
          financialYearCalendarDatesArray;
        companyInformation.values = companyInformationEmptyObj;
      } else {
        companyInformation.values.financialYear =
          financialYearCalendarDatesArray;
      }

      let fromDate = moment(financialYearCalendarDatesArray[idx].from).format(
        "YYYY-MM-DD"
      );
      let toDate = moment(financialYearCalendarDatesArray[idx].to).format(
        "YYYY-MM-DD"
      );

      switch (idx) {
        case 0:
          annualReportHeaderRequestObj.currentYear = fromDate + " - " + toDate;
          break;

        case 1:
          annualReportHeaderRequestObj.previousYear1 =
            fromDate + " - " + toDate;
          break;

        case 2:
          annualReportHeaderRequestObj.previousYear2 =
            fromDate + " - " + toDate;
          break;

        case 3:
          annualReportHeaderRequestObj.previousYear3 =
            fromDate + " - " + toDate;
          break;
      }

      this.insertAnnualReportHeader();
    } else {
      dataKeyIn = dataKeyIn + "" + dateEvent.originalEvent.nativeEvent.data;
      dataKeyIn = dataKeyIn.replace("null", "");

      if (dataKeyIn.length == 6) {
      }

      this.setState({
        calendarDateHardCodedValue: dataKeyIn,
      });
    }

    // let autoDate = {};
    //   for (let i = 0; i < sliderValue; i++) {
    //     let previousFinancialYearValue = i + 1;
    //     autoDate = {};
    //     autoDate.from = moment(financialYearCalendarDatesArray[i].from).subtract(
    //       12,
    //       "month"
    //     )._d;
    //     autoDate.to = moment(financialYearCalendarDatesArray[i].from).subtract(
    //       1,
    //       "day"
    //     )._d;
    //     financialYearCalendarDatesArray.push(autoDate);
    //   }

    this.setState({
      reRender: true,
    });
  }

  dateOnChange(dateEvent, idx, isFromDate, isToDate) {
    const { sliderValue, financialYearArrayState } = this.state;
    // if(dateEvent.originalEvent.nativeEvent.data == null) {
    //   this.setState({financialYearArrayState:''})
    // }
    if (dateEvent.value != null) {
      // userSelectedDate = moment(dateEvent.value).format("YYYY-MM-DD");

      financialYearObj.from = dateEvent.value;
      financialYearObj.to = moment(dateEvent.value)
        .add(12, "month")
        .subtract(1, "day")._d;
      //
      let tempFinancialYearObj = [];
      tempFinancialYearObj.push(financialYearObj);

      let fromDate = moment(financialYearObj.from).format("YYYY-MM-DD");
      let toDate = moment(financialYearObj.to).format("YYYY-MM-DD");

      switch (idx) {
        case 0:
          annualReportHeaderRequestObj.currentYear = fromDate + " - " + toDate;
          break;

        case 1:
          annualReportHeaderRequestObj.previousYear1 =
            fromDate + " - " + toDate;
          break;

        case 2:
          annualReportHeaderRequestObj.previousYear2 =
            fromDate + " - " + toDate;
          break;

        case 3:
          annualReportHeaderRequestObj.previousYear3 =
            fromDate + " - " + toDate;
          break;
      }

      // annualReportHeaderRequestObj[financialYearString] =
      // fromDate + " - " + toDate;

      // this.insertAnnualReportHeader();

      let autoDate = {};
      for (let i = 0; i < sliderValue; i++) {
        let previousFinancialYearValue = i + 1;
        autoDate = {};
        autoDate.from = moment(tempFinancialYearObj[i].from).subtract(
          12,
          "month"
        )._d;
        autoDate.to = moment(tempFinancialYearObj[i].from).subtract(
          1,
          "day"
        )._d;
        // tempFinancialYearObj[previousFinancialYearValue] = autoDate;
        tempFinancialYearObj.push(autoDate);
      }

      this.setState({
        financialYearArrayState: tempFinancialYearObj,
        reRender: true,
      });

      // this.simulatePrevFiancialYearDates(sliderValue);
      this.insertAnnualReportHeader();
    }

    // const { financialYearArrayState } = this.state;
    financialYearObj = {};

    dataKeyIn = dataKeyIn + "" + dateEvent.originalEvent.nativeEvent.data;

    dataKeyIn = dataKeyIn.replace("null", "");
    let userSelectedDate = "";

    this.setState({ currentStartingDate: dataKeyIn, reRender: true });
  }

  simulatePrevFiancialYearDatesNew(count) {
    const { companyInformation } = this.props;
    let calcDate = {};

    let tempFyCalendarDatesArray = [];

    financialYearCalendarDatesArray &&
      financialYearCalendarDatesArray.length &&
      financialYearCalendarDatesArray.map((i, idx) => {
        if (idx <= count) {
          tempFyCalendarDatesArray.push(i);
        }
      });

    financialYearCalendarDatesArray = tempFyCalendarDatesArray;
    for (let i = 0; i <= count; i++) {
      let previousFinancialYearValue = i + 1;
      calcDate = {};

      calcDate.from = moment(financialYearCalendarDatesArray[i].from).subtract(
        12,
        "month"
      )._d;
      calcDate.to = moment(financialYearCalendarDatesArray[i].from).subtract(
        1,
        "day"
      )._d;

      let fromDate = moment(calcDate.from).format("YYYY-MM-DD");
      let toDate = moment(calcDate.to).format("YYYY-MM-DD");

      financialYearCalendarDatesArray[count] = calcDate;
      switch (count) {
        case 0:
          annualReportHeaderRequestObj.currentYear = fromDate + " - " + toDate;
          break;

        case 1:
          annualReportHeaderRequestObj.previousYear1 =
            fromDate + " - " + toDate;
          break;

        case 2:
          annualReportHeaderRequestObj.previousYear2 =
            fromDate + " - " + toDate;
          break;

        case 3:
          annualReportHeaderRequestObj.previousYear3 =
            fromDate + " - " + toDate;
          break;
      }
      companyInformation.values.financialYear = financialYearCalendarDatesArray;
      if (count == 0) {
        annualReportHeaderRequestObj.previousYear1 = "";
        annualReportHeaderRequestObj.previousYear2 = "";
      }
      this.insertAnnualReportHeader();
    }

    this.setState({
      reRender: true,
    });
  }

  simulatePrevFiancialYearDates(count) {
    const { financialYearArrayState } = this.state;
    let autoDate = {};

    let financialYearArrayLoc = financialYearArrayState;

    for (let i = 0; i < count; i++) {
      let previousFinancialYearValue = i + 1;
      autoDate = {};
      autoDate.from = moment(financialYearArrayState[i].from).subtract(
        12,
        "month"
      )._d;
      autoDate.to = moment(financialYearArrayState[i].from).subtract(
        1,
        "day"
      )._d;
      financialYearArrayState[previousFinancialYearValue] = autoDate;

      let fromDate = moment(autoDate.from).format("YYYY-MM-DD");
      let toDate = moment(autoDate.to).format("YYYY-MM-DD");

      let fromToDateRange = fromDate + " - " + toDate;

      if (previousFinancialYearValue == 1) {
        annualReportHeaderRequestObj.previousYear1 = fromToDateRange;
        annualReportHeaderRequestObj.previousYear2 = "";
      }

      if (previousFinancialYearValue == 2) {
        annualReportHeaderRequestObj.previousYear2 = fromToDateRange;
      }
    }

    if (count == 0) {
      annualReportHeaderRequestObj.previousYear1 = "";
      annualReportHeaderRequestObj.previousYear2 = "";
    }

    this.insertAnnualReportHeader();
  }

  insertAnnualReportHeaderNew() {
    const { companyInformation, sessionDetails } = this.props;
    let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/annualReport/insertReportHeader",
          annualReportHeaderRequestObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data != "") {
            let updateJsonTableObj = {
              pageName: "Company Information",
              guid: sessionDetails.sessionDetails.values.uuid,
              json: JSON.stringify(companyInformation),
            };
            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => { });
          }
        });
    }
  }

  insertAnnualReportHeader() {
    let { login, companyInformation, sessionDetails } = this.props;
    const { sliderValue, financialYearArrayState } = this.state;

    // let localData = localStorage.getItem("rebelSkoolUser");
    if (userSessionAuthKeyReturnValue) {
      this.updateFyDatesAndInsertHeaders(
        companyInformation,
        financialYearArrayState,
        sessionDetails
      );
    }

    // if (localData == "null") {
    //   axios.get("/user/saveAnonymousUserAccount").then((response) => {
    //     if (response.data) {
    //       let anonymousUser = {
    //         userSessionAuthKey: response.data,
    //       };
    //       localStorage.setItem("rebelSkoolUser", JSON.stringify(anonymousUser));
    //       this.updateFyDatesAndInsertHeaders(
    //         companyInformation,
    //         financialYearArrayState,
    //         sessionDetails
    //       );
    //     }
    //   });
    // } else {
    //   this.updateFyDatesAndInsertHeaders(
    //     companyInformation,
    //     financialYearArrayState,
    //     sessionDetails
    //   );
    // }
  }

  handleSliderChangeNew = (event, newValue) => {
    const { financialYearSliderValue } = this.state;
    sliderEventValue = event;
    if (financialYearCalendarDatesArray.length > 0) {
      //If financialYearSliderValue is lesser than the event, then the user increased the slider value and we should allow it as usual
      if (financialYearSliderValue < event) {
        this.setState({
          financialYearSliderValue: event,
        });
        this.simulatePrevFiancialYearDatesNew(event);
      }

      //If the financialYearSliderValue is greater than the event, then the user decreased the slider value and we have to remove the Previous FYs
      else {
        //For that, we have to check if the report has any records for that FY by passing the reportGUID to the API
        //In that API, we have to check if the report has any value for that FY in Lines and Details table
        if (userSessionAuthKeyReturnValue) {
          axios
            .get(
              "/annualReportLine/checkAnnualReportLinesExistForFY?reportGUID=" +
              currentReportGUIDFromUrl,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              //If he has, return true and ask that the data will be deleted if he procced.
              if (response.data) {
                this.setState({ showRemoveFYConfimationDialog: true });
                //The Confirmation Dialog box will Open
                //If he click Yes => Call an API to delete the data related to the reportId in Lines, Details and JSONLines table and allow him to decrease the slider
                //If he click No => close the dialog box and stay in the slider current value
              } else {
                //If he does not have, return false and allow him to decrease the slider
                this.setState({
                  financialYearSliderValue: event,
                });
                this.simulatePrevFiancialYearDatesNew(event);
              }
            });
        }
      }
    } else {
      return this.toast.show({
        severity: "error",
        summary: TranslationFile.IncompleteText[userSelectedLanguage],
        detail: TranslationFile.FillStartingAndEndingDate[userSelectedLanguage],
        life: 5000,
      });
    }
  };

  deleteSelectedFY() {
    if (userSessionAuthKeyReturnValue) {
      //Call an API to delete the data related to the reportId in Lines, Details and JSONLines table and allow him to decrease the slider
      axios
        .get(
          "/annualReportLine/deletePreviousFYFromReport?reportGUID=" +
          currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            this.toast.show({
              severity: "success",
              summary: "Previous Financial Year Values Removed",
              detail: "Previous Financial Year Values Removed",
              life: 5000,
            });
            this.setState({
              showRemoveFYConfimationDialog: false,
              financialYearSliderValue: sliderEventValue,
            });
            this.simulatePrevFiancialYearDatesNew(sliderEventValue);
          }
        });
    }
  }

  deleteFormFooter = (
    //If he click Yes => Call an API to delete the data related to the reportId in Lines, Details and JSONLines table and allow him to decrease the slider
    //If he click No => close the dialog box and stay in the slider current value
    <div>
      <Button
        label={TranslationFile.yesBtn[userSelectedLanguage]}
        icon="pi pi-check"
        onClick={() => this.deleteSelectedFY()}
        className="p-button-text"
      />

      <Button
        label={TranslationFile.noBtn[userSelectedLanguage]}
        icon="pi pi-times"
        onClick={() => this.setState({ showRemoveFYConfimationDialog: false })}
        autoFocus
      />
    </div>
  );

  handleSliderChange = (event, newValue) => {
    const { financialYearArrayState } = this.state;
    if (financialYearArrayState.length > 0) {
      this.setState({
        sliderValue: event,
      });
    } else {
      return this.toast.show({
        severity: "error",
        summary: TranslationFile.IncompleteText[userSelectedLanguage],
        detail:
          TranslationFile.ThankFillStartingAndEndingDateForSubscribing[
          userSelectedLanguage
          ],
        life: 5000,
      });
    }

    this.simulatePrevFiancialYearDates(event);
  };

  updateFyDatesAndInsertHeaders(
    companyInformation,
    financialYearArrayState,
    sessionDetails
  ) {
    let fyArray = [];
    this.formatFinancialYearDate(
      annualReportHeaderRequestObj.currentYear,
      fyArray,
      "currentYear"
    );
    this.formatFinancialYearDate(
      annualReportHeaderRequestObj.previousYear1,
      fyArray,
      "previousYear1"
    );
    this.formatFinancialYearDate(
      annualReportHeaderRequestObj.previousYear2,
      fyArray,
      "previousYear2"
    );
    this.formatFinancialYearDate(
      annualReportHeaderRequestObj.previousYear3,
      fyArray,
      "previousYear3"
    );

    let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/annualReport/insertReportHeader",
          annualReportHeaderRequestObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let finalFyArrayObj = [];
            if (companyInformationFyPropsValue != "") {
              companyInformation.values.financialYear =
                companyInformationFyPropsValue;
            }

            switch (sliderCount) {
              case 0:
                finalFyArrayObj = financialYearArrayState.slice(0, 1);
                break;

              case 1:
                finalFyArrayObj = financialYearArrayState.slice(0, 2);
                break;

              case 2:
                finalFyArrayObj = financialYearArrayState.slice(0, 3);
                break;
            }

            if (companyInformation.values == undefined) {
              let companyInformationEmptyObj = {
                organizationname: "",
                organizationnumber: "",
                postalcode: "",
                city: "",
                financialYear: "",
              };

              companyInformationEmptyObj.financialYear = fyArray;

              companyInformation.values = companyInformationEmptyObj;
            } else {
              companyInformation.values.financialYear = fyArray;
            }

            if (finalFyArrayObj.length > 0)
              companyInformation.values.financialYear = finalFyArrayObj;

            if (userSessionAuthKeyReturnValue) {
              let updateJsonTableObj = {
                pageName: "Company Information",
                guid: sessionDetails.sessionDetails.values.uuid,
                json: JSON.stringify(companyInformation),
              };
              axios
                .post(
                  "/annualReportLine/updateLinesJsonTable",
                  updateJsonTableObj,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => { });
            }
          }
        });
    } else {
    }
  }

  formatFinancialYearDate(financialYearDate, fyArray, financialYearString) {
    let respDataCurrentYear = financialYearDate.split(" - ");

    if (respDataCurrentYear.length > 1) {
      let fromDate = respDataCurrentYear[0];
      let toDate = respDataCurrentYear[1];

      if (fromDate.length != 10) {
        fromDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(toDate).format("YYYY-MM-DD");
      }
      annualReportHeaderRequestObj[financialYearString] =
        fromDate + " - " + toDate;

      let dateObj = {
        from: fromDate,
        to: toDate,
      };

      fyArray.push(dateObj);
    }
  }

  createElements(number) {
    const { financialYearArrayState } = this.state;
    const { companyInformation } = this.props;

    var elements = [];
    sliderCount = number;

    for (let i = 0; i < number; i++) {
      elements.push(
        <center>
          <div className="year-div">
            <br></br>
            <div className="year-cal-label-head">
              {/* PREVIOUS YEAR  */}
              {
                TranslationFile.IncomestateYearPrevious[userSelectedLanguage]
              }- {i + 1}
            </div>
            <div className="pre-year-cal-label">
              <span className="pre-year-cal-label-1">
                {/* Starting Date  */}
                {TranslationFile.IncomestateYearStartdate[userSelectedLanguage]}
              </span>
              <span className="pre-year-cal-label-2">
                {/* Ending Date */}
                {TranslationFile.IncomestateYearEnddate[userSelectedLanguage]}
              </span>
            </div>
            <div className="year-cal-box">
              <Calendar
                id="icon"
                value={
                  financialYearCalendarDatesArray[i + 1] != undefined
                    ? financialYearCalendarDatesArray[i + 1].from
                    : ""
                }
                onChange={(e) => {
                  this.dateOnChangeNew(e, i + 1, true, false);
                }}
                minDate={new Date(2020, 0, 1)}
                onBlur={(e) => this.dateOnBlurNew(e, i + 1, true, false)}
                showIcon
                dateFormat="yy-mm-dd"
                placeholder="YYYY-MM-DD"
                maxDate={new Date()}
                disabled={linkSharedUser == 3 || responsePaymentEntryNo > 0}
              />
              <Calendar
                id="icon"
                value={
                  financialYearCalendarDatesArray[i + 1] != undefined
                    ? financialYearCalendarDatesArray[i + 1].to
                    : ""
                }
                onChange={(e) => {
                  this.dateOnChangeNew(e, i + 1, false, true);
                }}
                minDate={new Date(2020, 0, 1)}
                onBlur={(e) => this.dateOnBlurNew(e, i + 1, false, true)}
                showIcon
                dateFormat="yy-mm-dd"
                placeholder="YYYY-MM-DD"
                disabled={linkSharedUser == 3 || responsePaymentEntryNo > 0}
              />
            </div>
          </div>
        </center>
      );
    }
    return elements;
  }

  navigateToIncomeStatementPage() {
    const {
      organizationNumber,
      companyName,
      postalCode,
      city,
      financialYearArrayState,
      sliderValue,
    } = this.state;

    let { companyInformation, sessionDetails, login, sideBarReducer } =
      this.props;
    let financialYearArray = financialYearCalendarDatesArray;
    let isIncomplete = false;

    // let incomeStatementURL =
    //   "/annualreport/" +
    //   sessionDetails["sessionDetails"].values.uuid +
    //   "/incomeStatement";
    // this.props.history.push(incomeStatementURL);

    if (organizationNumber == "") {
      this.setState({
        isOrganizationNoMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isOrganizationNoMissing: false,
      });

    if (companyName == "") {
      this.setState({
        isOrganizationNameMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isOrganizationNameMissing: false,
      });

    if (postalCode == "" || postalCode == 0 || postalCode == null) {
      this.setState({
        isPostalCodeMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isPostalCodeMissing: false,
      });

    if (city == "") {
      this.setState({
        isCityMissing: true,
      });
      isIncomplete = true;
    } else
      this.setState({
        isCityMissing: false,
      });

    if (isIncomplete) {
      this.toast.show({
        severity: "error",
        summary: TranslationFile.IncompleteText[userSelectedLanguage],
        detail: TranslationFile.FillMandatryFields[userSelectedLanguage],
        life: 5000,
      });
    } else {
      let currentyear = "",
        previousyear1 = "",
        previousyear2 = "",
        previousyear3 = "";

      let fyArray = [];

      if (financialYearArray.length !== sliderValue + 1) {
        financialYearArray = financialYearArray.slice(0, sliderValue + 1);
        this.setState({
          financialYearArrayState: financialYearArray,
        });
      }

      financialYearArray.length &&
        financialYearArray.map((i, idx) => {
          let fromDate = moment(i.from).format("YYYY-MM-DD");
          let toDate = moment(i.to).format("YYYY-MM-DD");
          switch (idx) {
            case 0:
              currentyear = fromDate + " - " + toDate;
              fyArray.push(currentyear);
              break;
            case 1:
              previousyear1 = fromDate + " - " + toDate;
              fyArray.push(previousyear1);
              break;
            case 2:
              previousyear2 = fromDate + " - " + toDate;
              fyArray.push(previousyear2);
              break;
            case 3:
              previousyear3 = fromDate + " - " + toDate;
              fyArray.push(previousyear3);
              break;
          }
        });

      if (currentyear == "") {
        this.toast.show({
          severity: "error",
          summary: TranslationFile.IncompleteText[userSelectedLanguage],
          detail:
            TranslationFile.FillFinancialYearDetails[userSelectedLanguage],
          life: 5000,
        });
      } else {
        let companyInformationValue = {
          organizationname: annualReportHeaderRequestObj.companyName,
          organizationnumber: annualReportHeaderRequestObj.registrationNo,
          postalcode: annualReportHeaderRequestObj.postalCode,
          city: annualReportHeaderRequestObj.city,
          financialYear: financialYearCalendarDatesArray,
          SIEIncomeStatement: "",
          SIEBalanceSheet: "",
        };

        // companyInformation.values = companyInformationValue;
        // axios
        // .post("/annualReport/insertReportHeader", companyInformationReqPOST)
        // .then((response) => {
        //   if (response.data != "") {
        let currentPageName = ["Company Information"];
        if (sideBarGreenTickPages.length == 0)
          sideBarReducer.values = currentPageName;

        let possibleAccessablePagesListLSString = localStorage.getItem(
          "possibleAccessablePages"
        );

        if (possibleAccessablePagesListLSString == null) {
          let possibleNavigationPagesList = [
            "My Dashboard",
            "Company Information",
          ];

          let possibleNavigationPagesListString = JSON.stringify(
            possibleNavigationPagesList
          );

          localStorage.setItem(
            "possibleAccessablePages",
            possibleNavigationPagesListString
          );
        } else {
          let possibleAccessablePagesListLSArray = JSON.parse(
            possibleAccessablePagesListLSString
          );

          if (
            !possibleAccessablePagesListLSArray.includes("Income Statement")
          ) {
            possibleAccessablePagesListLSArray.push("Income Statement");

            let updatePossibleNavigationPagesListString = JSON.stringify(
              possibleAccessablePagesListLSArray
            );

            localStorage.setItem(
              "possibleAccessablePages",
              updatePossibleNavigationPagesListString
            );
          }
        }

        let incomeStatementURL =
          "/annualreport/" +
          sessionDetails["sessionDetails"].values.uuid +
          "/incomeStatement";
        this.props.history.push(incomeStatementURL);
        //   }
        // });
      }
    }
  }

  dialogBoxOnClick = (e) => {
    if (e.defaultPrevented) {
      this.setState({
        showProceedButton: false,
      });
    }
  };

  removeFile = () => {
    this.setState({
      SIEMissingFieldsErrorArrayState: [],
      showProceedButton: false,
    });

    this.sieFileErrorMsg.clear();
  };

  selectFile = (selectedFileCallback) => {
    selectedFile = selectedFileCallback.files[0];
    let selectedFileFormat = selectedFileCallback.files[0].name
      .split(".")
      .pop();

    if (selectedFileFormat != "se" && selectedFileFormat != "txt") {
      this.toast.show({
        severity: "error",
        summary: TranslationFile.InvalidFileFormat[userSelectedLanguage],
        detail: TranslationFile.FileFormatWarning[userSelectedLanguage],
        life: 6000,
      });

      this.setState({
        showProceedButton: false,
      });
    } else {
      this.setState({
        showProceedButton: true,
        SIEMissingFieldsErrorArrayState: [],
      });
    }

    // this.setState({
    //   SIEMissingFieldsErrorArrayState: [],
    // });

    this.sieFileErrorMsg.clear();
  };

  proceedBtnOnClick() {
    const {
      organizationNumber,
      companyName,
      postalCode,
      city,
      financialYearArrayState,
      sliderValue,
      isProceedButtonClicked,
    } = this.state;
    let { companyInformation, sessionDetails, login, sideBarReducer } =
      this.props;

    this.setState({
      SIEMissingFieldsErrorArrayState: [],
      showProceedButton: false,
      SIEErrors: "",
    });

    if (!isProceedButtonClicked) {
      this.setState({
        isProceedButtonClicked: true,
      });

      var file = selectedFile;
      const url = "/annualReport/uploadSIEFile";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("reportGUID", currentReportGUIDFromUrl);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "User-Session-Auth-Key":
            userSessionAuthKeyReturnValue.headers["User-Session-Auth-Key"],
        },
      };
      axios
        .post(url, formData, config)
        .then((sieUploadedResponse) => {
          if (sieUploadedResponse.data == "Completed") {
            axios
              .get(
                "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
                userSessionAuthKeyReturnValue
              )
              .then((headerResponse) => {
                let headerResponseData = headerResponse.data;
                let navigatedFromDashboardFYs = [];

                if (headerResponseData.currentYear != "")
                  this.formatDateAndCreateFinancialYearArray(
                    headerResponseData.currentYear,
                    navigatedFromDashboardFYs,
                    0
                  );

                if (headerResponseData.previousYear1 != "")
                  this.formatDateAndCreateFinancialYearArray(
                    headerResponseData.previousYear1,
                    navigatedFromDashboardFYs,
                    1
                  );

                if (headerResponseData.previousYear2 != "")
                  this.formatDateAndCreateFinancialYearArray(
                    headerResponseData.previousYear2,
                    navigatedFromDashboardFYs,
                    2
                  );
                this.setState({
                  organizationNumber: headerResponseData.registrationNo,
                  companyName: headerResponseData.companyName,
                  postalCode:
                    headerResponseData.postalCode == 0
                      ? ""
                      : headerResponseData.postalCode,
                  city: headerResponseData.city,
                  visible: false,
                  isProceedButtonClicked: false,
                  uploadedSIEFile: headerResponseData.sIEFile,
                  currentReportID: headerResponseData.reportID,
                });

                let navigatedResponseObj = {
                  organizationnumber: headerResponseData.registrationNo,
                  organizationname: headerResponseData.companyName,
                  postalcode:
                    headerResponseData.postalCode == 0
                      ? ""
                      : headerResponseData.postalCode,
                  city: headerResponseData.city,
                  financialYear: navigatedFromDashboardFYs,
                };

                annualReportHeaderRequestObj.companyName =
                  headerResponseData.companyName;
                annualReportHeaderRequestObj.registrationNo =
                  headerResponseData.registrationNo;
                annualReportHeaderRequestObj.postalCode =
                  headerResponseData.postalCode == 0
                    ? ""
                    : headerResponseData.postalCode;
                annualReportHeaderRequestObj.city = headerResponseData.city;

                companyInformation.values = navigatedResponseObj;

                if (headerResponseData.registrationNo != "") {
                  axios
                    .get(
                      "/annualReport/getCompletedPages?reportId=" +
                      headerResponseData.reportID,
                      userSessionAuthKeyReturnValue
                    )
                    .then((completedStatementTypesResponse) => {
                      sideBarReducer.values =
                        completedStatementTypesResponse.data;
                      sideBarGreenTickPages =
                        completedStatementTypesResponse.data;
                      sideBarGreenTickPages.push(
                        "My Dashboard",
                        "Company Information"
                      );
                      let updatePossibleNavigationPagesListString =
                        JSON.stringify(sideBarGreenTickPages);
                      localStorage.setItem(
                        "possibleAccessablePages",
                        updatePossibleNavigationPagesListString
                      );
                      this.setState({
                        reRender: true,
                      });
                    });
                }
              });
          }
        })
        .catch((error) => {
          this.setState({
            isProceedButtonClicked: false,
            SIEErrors: error.response.data,
          });

          this.sieFileErrorMsg.show([
            {
              severity: "error",
              detail: "Invalid Format Field : " + error.response.data,
              sticky: true,
            },
          ]);
        });
    }
  }
  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  showStatusAlertFunc(status) { }

  languageValue = (e) => { };

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    let sessionFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      this.props.history.push(annualReport + sessionFromLS + link);
    }
    this.setState({
      reRender: true,
    });
  };

  newUserSaveButton() {
    const {
      newUserEmailId,
      newUserUserId,
      newUserName,
      newLastName,
      newUserPassword,
      newUserConfirmPassword,
    } = this.state;
    if (
      newUserName != "" &&
      newLastName != "" &&
      newUserPassword != "" &&
      newUserConfirmPassword != "" &&
      userSessionAuthKeyReturnValue
    ) {
      let userDetails = {
        userID: newUserUserId,
        userName: newUserName,
        lastName: newLastName,
        password: newUserPassword,
      };
      axios
        .post(
          "/user/updateUsernamePasswordForUserID",
          userDetails,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let userEmailPasswordDetails = {
              emailID: newUserEmailId,
              password: newUserPassword,
            };
            axios
              .post("/user/loginAndGetUser", userEmailPasswordDetails)
              .then((response) => {
                const userAuthKey = response.headers["user-session-auth-key"];
                response.data["User-Session-Auth-Key"] = userAuthKey;
                localStorage.setItem(
                  "rebelSkoolUser",
                  JSON.stringify(response.data)
                );
                this.setState({ showUserNamePasswordDialogBox: false });
                window.location.reload(false);
              });
          }
        });
    }
  }

  redirectToHomePage() {
    window.location.assign("/#/annualreport");
  }

  downloadSIEFile() {
    const { currentReportID } = this.state;
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/downloadSIEFile?reportID=" + currentReportID,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          response["Access-Control-Expose-Headers"] = "Content-Disposition";
          const filename =
            response.headers["content-disposition"].split("filename=")[1];
          const blob = new Blob([response.data]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  closeTipsDialogBox() {
    const randomIndex = Math.floor(Math.random() * videoList.length);

    this.setState({ tipsDialog: false });

    setTimeout(() => {
      this.setState({
        randomVideoFileName: videoList[randomIndex].videoPath,
        randomVideoFileTitle: videoList[randomIndex].videoTitle,
      });
    }, 1000);
  }

  tourStatus = (e) => {
    this.setState({ run: e });
  };

  render() {
    let { currentPageName, readOnly, showWIPStatus } = this.props;
    const {
      isWrongOrganizationNo,
      organizationNumber,
      companyName,
      postalCode,
      city,
      sliderValue,
      isCityMissing,
      isOrganizationNameMissing,
      isOrganizationNoMissing,
      isPostalCodeMissing,
      financialYearArrayState,
      SIEMissingFieldsErrorArrayState,
      showProceedButton,
      visible,
      disableCompanyNo,
      showUserNamePasswordDialogBox,
      newUserPassword,
      newUserConfirmPassword,
      newUserName,
      newLastName,
      newUserEmailId,
      isProceedButtonClicked,
      financialYearSliderValue,
      calendarDateHardCodedValue,
      uploadedSIEFile,
      reportAccessStatusFromProps,
      randomVideoFileName,
      tipsDialog,
      randomVideoFileTitle,
    } = this.state;
    ////////////

    if (
      financialYearCalendarDatesArray.length != undefined &&
      financialYearCalendarDatesArray.length > 0
    ) {
      submissionLimitToDate = moment(financialYearCalendarDatesArray[0].to)
        .add(7, "month")
        .subtract(1, "day")._d;
      submissionLimitToDate = moment(submissionLimitToDate).format(
        "YYYY-MM-DD"
      );
    }
    ////////////
    let pageUnderWIP = false;
    if (showWIPStatus.values != undefined) {
      if (showWIPStatus.values) {
        pageUnderWIP = true;
      } else {
        pageUnderWIP = false;
      }
    }

    const { oddsFilters, sideBarReducer } = this.props;
    const currentDateMinus13Months = this.subtractMonths(new Date(), 13);
    return (
      <div>
        {linkSharedUser !== 4 ? (
          <div>
            <div>
              <Joyride
                continuous
                run={this.state.run}
                disableBeacon={true}
                showProgress
                showSkipButton
                steps={steps}
                scrollToFirstStep
                scrollToSteps
                scrollOffset={100}
                styles={{
                  options: {
                    zIndex: 10000,
                  },
                }}
              />

              <Toast
                ref={(el) => {
                  this.toast = el;
                }}
                className="companyInfoErrorToast"
                style={{ marginTop: "34%" }}
              ></Toast>

              <NavBarHeader
                callBack={this.callback}
                isAppPortal={true}
                isHomePage={false}
              />
              <div
                className={
                  pageUnderWIP
                    ? "companyInfoSideBarWithWIP"
                    : "companyInfoSideBar"
                }
              >
                <Sidebar
                  children="Company Information"
                  sideBarStatus={this.sideBarStatus.bind(this)}
                  sideBarCallback={this.sideBarCallback.bind(this)}
                  tourStatus={this.tourStatus.bind(this)}
                />
              </div>
              <div className="CIcontentParentDiv">
                <div className="CIcontentParentDiv">
                  <div className="createARLabel">
                    {TranslationFile.CreateAR[userSelectedLanguage]}
                  </div>
                  <div
                    className="upload-second-div"
                    style={{ pointerEvents: readOnly.values && "none" }}
                  >
                    <div className="companyIndiImportInsideDiv">
                      {uploadedSIEFile != undefined &&
                        uploadedSIEFile != "" &&
                        uploadedSIEFile != null ? (
                        <Dropdown as={ButtonGroup}>
                          <Button
                            // label="Import SIE"
                            label={
                              TranslationFile.IncomestateImportbtn[
                              userSelectedLanguage
                              ]
                            }
                            icon="pi pi-file-import"
                            onClick={() => this.setState({ visible: true })}
                            disabled={
                              linkSharedUser == 3 || responsePaymentEntryNo > 0
                            }
                          />

                          <Dropdown.Toggle
                            split
                            id="dropdown-split-basic"
                            className="import-sie-dropdown"
                          />

                          <Dropdown.Menu className="download-sie-dropdown">
                            <Dropdown.Item
                              onClick={() => this.downloadSIEFile()}
                            >
                              <span
                                className="pi pi-download"
                                style={{ marginRight: "5px" }}
                              ></span>
                              {TranslationFile.DownloadSIEFile[userSelectedLanguage]}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <Button
                          // label="Import SIE"
                          label={
                            TranslationFile.IncomestateImportbtn[
                            userSelectedLanguage
                            ]
                          }
                          className="import-btn-upload"
                          icon="pi pi-file-import"
                          onClick={() => this.setState({ visible: true })}
                          disabled={linkSharedUser == 3}
                        />
                      )}
                    </div>
                    <Dialog
                      visible={this.state.showRemoveFYConfimationDialog}
                      style={{ width: "32rem" }}
                      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                      header="Remove the Financial Year from your Report?"
                      blockScroll
                      modal
                      footer={this.deleteFormFooter}
                      onHide={() =>
                        this.setState({ showRemoveFYConfimationDialog: false })
                      }
                    >
                      <div className="confirmation-content">
                        <span>
                          <span>
                            The Financial Year data will be deleted from your
                            Report. Click "Yes" to continue
                          </span>
                        </span>
                      </div>
                    </Dialog>

                    <Dialog
                      header={
                        TranslationFile.IncomestateImportText1[
                        userSelectedLanguage
                        ]
                      }
                      blockScroll
                      visible={visible}
                      onHide={() =>
                        this.setState({
                          visible: false,
                          SIEMissingFieldsErrorArrayState: [],
                          SIEErrors: "",
                        })
                      }
                      id="import-dialog"
                      style={{ width: "50vw" }}
                      onClick={this.dialogBoxOnClick}
                      className="import_file-sub_title"
                    >
                      {isProceedButtonClicked ? (
                        <>
                          <div className="sieUploadLoadingDiv">
                            <center>
                              <ProgressSpinner
                                style={{
                                  width: "50px",
                                  height: "40px",
                                  top: "118px",
                                }}
                                className="companyInfoProceedSpinner"
                              />
                            </center>
                          </div>
                          <center>
                            <Button
                              label="click here to know more Tips and Guide, While loading."
                              onClick={() =>
                                this.setState({
                                  tipsDialog: true,
                                })
                              }
                            />
                          </center>
                        </>
                      ) : (
                        <div>
                          <FileUpload
                            maxFileSize={10000000000}
                            name="demo[]"
                            chooseLabel={
                              TranslationFile.ChooseLabel[userSelectedLanguage]
                            }
                            emptyTemplate={
                              <p className="import_file_m-0">
                                {
                                  TranslationFile.IncomestateImportDag[
                                  userSelectedLanguage
                                  ]
                                }
                              </p>
                            }
                            onSelect={this.selectFile}
                            multiple={false}
                            onRemove={this.removeFile}
                          />

                          {showProceedButton != "" && (
                            <div className="proceed-btn-div">
                              <Button
                                onClick={this.proceedBtnOnClick.bind(this)}
                              >
                                {isProceedButtonClicked ? (
                                  <ProgressSpinner
                                    style={{ width: "50px", height: "40px" }}
                                    className="companyInfoProceedSpinner"
                                  />
                                ) : (
                                  TranslationFile.IncomestateImportbtnPrceed[
                                  userSelectedLanguage
                                  ]
                                )}
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                      <Messages ref={(el) => (this.sieFileErrorMsg = el)} />
                    </Dialog>

                    <Dialog
                      header="Tips and Guide"
                      visible={tipsDialog}
                      blockScroll
                      style={{ width: "80vw", height: "100vw" }}
                      onHide={() => this.closeTipsDialogBox()}
                    >
                      <center>
                        <h5>{randomVideoFileTitle}</h5>
                      </center>

                      <video
                        width="100%"
                        height="90%"
                        className="tipsVideoTag"
                        autoPlay
                        muted
                        loop
                        ref={this.videoRef}
                        onCanPlay={() => this.setPlayBack()}
                      >
                        <source src={randomVideoFileName} type="video/mp4" />
                      </video>
                    </Dialog>
                  </div>
                  <div className="info-year-container">
                    <Fieldset
                      style={{ pointerEvents: readOnly.values && "none" }}
                      className="company_fieldset1"
                      // legend="Fill in the company details below"
                      legend={
                        TranslationFile.IncomestateHeader1[userSelectedLanguage]
                      }
                    >
                      <div className="info-number-name">
                        <Row className="info-row">
                          <Col className="info-col">
                            <label
                              htmlFor="OrganizationNumber"
                              className="companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyOrgNo[
                                userSelectedLanguage
                                ]
                              }
                              <p className="companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputMask
                              className={
                                isOrganizationNoMissing
                                  ? "missingFieldsStyle"
                                  : "info-org-no"
                              }
                              mask="999999-9999"
                              value={organizationNumber}
                              onComplete={(e) =>
                                this.organizationNoOnComplete(e)
                              }
                              onChange={(e) => this.organizationNoOnChange(e)}
                              useGrouping={false}
                              disabled={
                                disableCompanyNo ||
                                linkSharedUser == 3 ||
                                responsePaymentEntryNo > 0
                              }
                            />
                          </Col>
                          <Col className="info-col">
                            <label
                              htmlFor="CompanyName"
                              className="companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyOrgName[
                                userSelectedLanguage
                                ]
                              }
                              <p className="companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputText
                              className={
                                isOrganizationNameMissing
                                  ? "missingFieldsStyle"
                                  : "info-org-name"
                              }
                              value={companyName}
                              onChange={(e) => this.companyNameOnChange(e)}
                              onBlur={(e) => this.companyNameOnBlur(e)}
                              disabled={linkSharedUser == 3}
                            />
                          </Col>
                        </Row>
                        <Row className="info-row">
                          <Col className="info-col">
                            <label
                              htmlFor="PostalCode"
                              className="companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyPostalcode[
                                userSelectedLanguage
                                ]
                              }
                              <p className="companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputNumber
                              inputId={
                                isPostalCodeMissing
                                  ? "withoutgroupingIncomplete"
                                  : "withoutgrouping"
                              }
                              value={postalCode}
                              maxLength={5}
                              onValueChange={(e) => this.PostalcodeOnChange(e)}
                              onBlur={(e) => this.postalCodeOnBlur(e)}
                              useGrouping={false}
                              disabled={linkSharedUser == 3}
                            />
                          </Col>
                          <Col className="info-col">
                            <label
                              htmlFor="City"
                              className="companyInformationTextBoxTitle"
                            >
                              {
                                TranslationFile.IncomestateCompanyCity[
                                userSelectedLanguage
                                ]
                              }
                              <p className="companyInformationMandatoryStart">
                                *
                              </p>
                            </label>
                            <InputText
                              className={
                                isCityMissing
                                  ? "missingFieldsStyle"
                                  : "info-org-city"
                              }
                              value={city}
                              onChange={(e) => this.cityOnChange(e)}
                              onBlur={(e) => this.cityOnBlur(e)}
                              disabled={linkSharedUser == 3}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Fieldset>
                    <Fieldset
                      className="company_fieldset2"
                      // legend="Fill in the Financial Year details below"
                      legend={
                        TranslationFile.IncomestateHeader2[userSelectedLanguage]
                      }
                    >
                      <div style={{ pointerEvents: readOnly.values && "none" }}>
                        <div className="year-cal-label-container">
                          <br></br>
                          <div className="year-cal-declarationYearLbl">
                            {/* DECLARATION YEAR */}
                            {
                              TranslationFile.IncomestateYearDeclare[
                              userSelectedLanguage
                              ]
                            }
                          </div>
                          <div className="year-cal-label">
                            <span className="year-cal-label-1">
                              {/* Starting Date */}
                              {
                                TranslationFile.IncomestateYearStartdate[
                                userSelectedLanguage
                                ]
                              }
                            </span>
                            <span className="year-cal-label-2">
                              {/* Ending Date */}
                              {
                                TranslationFile.IncomestateYearEnddate[
                                userSelectedLanguage
                                ]
                              }
                            </span>
                          </div>

                          <div className="year-cal-box">
                            <Calendar
                              id="icon"
                              value={
                                financialYearCalendarDatesArray[0] != undefined
                                  ? financialYearCalendarDatesArray[0].from
                                  : ""
                              }
                              onChange={(e) => {
                                this.dateOnChangeNew(e, 0, true, false);
                              }}
                              onBlur={(e) =>
                                this.dateOnBlurNew(e, 0, true, false)
                              }
                              minDate={new Date(2020, 0, 1)}
                              showIcon
                              dateFormat="yy-mm-dd"
                              placeholder={
                                TranslationFile.YearFormat[
                                userSelectedLanguage
                                ]
                              }
                              viewDate={currentDateMinus13Months}
                              disabled={
                                linkSharedUser == 3 ||
                                responsePaymentEntryNo > 0
                              }
                            />
                            <Calendar
                              id="icon"
                              value={
                                financialYearCalendarDatesArray[0] != undefined
                                  ? financialYearCalendarDatesArray[0].to
                                  : ""
                              }
                              onChange={(e) => {
                                this.dateOnChangeNew(e, 0, false, true);
                              }}
                              onBlur={(e) =>
                                this.dateOnBlurNew(e, 0, false, true)
                              }
                              minDate={new Date(2020, 0, 1)}
                              showIcon
                              dateFormat="yy-mm-dd"
                              placeholder={
                                TranslationFile.YearFormat[
                                userSelectedLanguage
                                ]
                              }

                              disabled={
                                linkSharedUser == 3 ||
                                responsePaymentEntryNo > 0
                              }
                            />
                          </div>
                        </div>
                        {submissionLimitToDate != "" &&
                          submissionLimitToDate != undefined && (
                            <div className="year-limit-info">
                              <center>
                                <i
                                  className="pi pi-info-circle"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <span style={{ marginRight: "2px" }}>
                                  {/* The last date for submission to Bolagsverket
                                  for this financial year :{" "} */}
                                  {
                                    TranslationFile.CI_FY_Summary_Line[
                                    userSelectedLanguage
                                    ]
                                  }{" "}
                                </span>
                                <span>{submissionLimitToDate}</span>
                              </center>
                            </div>
                          )}
                        <div className="year-drop-label-main">
                          <span className="year-drop-label">
                            {/* Number of previous financial years */}
                            {TranslationFile.CI_FY_Select[userSelectedLanguage]}
                          </span>
                        </div>
                        <center>
                          <div className="slider-div">
                            <div className="slider__container">
                              <ReactSlider
                                className="horizontal-slider"
                                min={0}
                                max={2}
                                withBars
                                value={financialYearSliderValue}
                                onChange={this.handleSliderChangeNew.bind()}
                                ref={this.setReactSliderRef}
                                disabled={
                                  linkSharedUser == 3 ||
                                  responsePaymentEntryNo > 0
                                }
                              />
                              {this.state.displayLabels ? (
                                <CusomtLabel oddsFilters={oddsFilters} />
                              ) : null}
                            </div>
                          </div>
                        </center>
                        <br></br>
                        <div>
                          {this.createElements(financialYearSliderValue)}
                        </div>
                      </div>

                      <center>
                        <Button
                          label={TranslationFile.nextBtn[userSelectedLanguage]}
                          aria-label="year-btn-save"
                          onClick={() => this.navigateToIncomeStatementPage()}
                          className="year-btn-save"
                          style={{
                            width: "100px",
                            height: "44px",
                            fontSize: "1.2rem",
                            marginTop: "6%",
                          }}
                        />
                      </center>
                    </Fieldset>
                  </div>
                </div>
                <ScrolltoTop />
                <Footer_contact_tab
                  sideBar={this.state.sideBarStatus}
                  isCorpPortal={false}
                  isHomePage={true}
                />
              </div>
              <div>
                <Dialog
                  header="New User? Login Here"
                  blockScroll
                  visible={showUserNamePasswordDialogBox}
                  onHide={() =>
                    this.setState({ showUserNamePasswordDialogBox: false })
                  }
                  style={{ width: "30vw" }}
                  className="newUserDialogBox"
                >
                  <div>
                    <div style={{ padding: "20px 30px" }}>
                      <div>
                        <div style={{ textAlign: "left" }}>
                          {TranslationFile.LoginFirstName[userSelectedLanguage]}
                        </div>
                        <InputText
                          value={newUserName}
                          onChange={(e) => {
                            this.setState({ newUserName: e.target.value });
                          }}
                          placeholder="First Name"
                        />
                      </div>
                      <br></br>
                      <div>
                        <div style={{ textAlign: "left" }}>
                          {TranslationFile.LoginLastName[userSelectedLanguage]}
                        </div>
                        <InputText
                          value={newLastName}
                          onChange={(e) => {
                            this.setState({ newLastName: e.target.value });
                          }}
                          placeholder="Last Name"
                        />
                      </div>
                      <br></br>
                      <div>
                        <div style={{ textAlign: "left" }}>
                          {
                            TranslationFile.FooterEmailLabel[
                            userSelectedLanguage
                            ]
                          }
                        </div>
                        <InputText
                          value={newUserEmailId}
                          placeholder="Email ID"
                          disabled
                        />
                      </div>
                      <br></br>
                      <div>
                        <div style={{ textAlign: "left" }}>
                          {
                            TranslationFile.IncomestateYearPassword[
                            userSelectedLanguage
                            ]
                          }
                        </div>
                        <Password
                          value={newUserPassword}
                          onChange={(e) => {
                            this.setState({ newUserPassword: e.target.value });
                          }}
                          feedback={false}
                          toggleMask={true}
                          required
                          placeholder="Password"
                        />
                      </div>
                      <br></br>

                      <div>
                        <div style={{ textAlign: "left" }}>
                          {
                            TranslationFile.IncomestateYearConPassword[
                            userSelectedLanguage
                            ]
                          }
                        </div>
                        <Password
                          value={newUserConfirmPassword}
                          onChange={(e) => {
                            this.setState({
                              newUserConfirmPassword: e.target.value,
                            });
                          }}
                          feedback={false}
                          toggleMask={true}
                          required
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                    <br></br>
                    <center>
                      <Button
                        icon="pi pi-check"
                        onClick={() => this.newUserSaveButton()}
                      >
                        {
                          TranslationFile.IncomestateYearsave[
                          userSelectedLanguage
                          ]
                        }
                      </Button>
                    </center>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <center>
              <div className="no-access-div">
                <table>
                  <tr>
                    <td>
                      <i
                        className="pi pi-times-circle"
                        style={{ color: "red", fontSize: "2rem" }}
                      ></i>
                    </td>
                    <td>
                      <label style={{ paddingLeft: "10px", color: "red" }}>
                        {
                          TranslationFile.IncomestateYearNoAccess[
                          userSelectedLanguage
                          ]
                        }
                      </label>
                    </td>
                  </tr>
                </table>
                <Button onClick={() => this.redirectToHomePage()}>
                  {
                    TranslationFile.IncomestateYearGoToHome[
                    userSelectedLanguage
                    ]
                  }
                </Button>
              </div>
            </center>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(CompanyInformation);
