import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import BankIdLogo from "../../Assests/BankID_logo.png";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

import Sidebar from "../Sidebar/Sidebar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import { useHistory } from "react-router-dom";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import axios from "axios";
import QRLib from "qrcode";
import { ProgressSpinner } from "primereact/progressspinner";

import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";

import "./digitalSignature.css";

let userSelectedLanguage;
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let currentReportGUIDFromUrl = "";
export default function Representative() {
  const [qrImage, setQrImage] = useState();
  const [isScanning, setIsScanning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [digitalSignFail, setDigitalSignFail] = useState(false);
  const [digitalSignSuccess, setDigitalSignSuccess] = useState(false);
  const [qrCodeLoading, setQrCodeLoading] = useState(true);

  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  const history = useHistory();

  const [sideBarStatus, setSideBarStatus] = useState(true);
  const [digitalignatureReps, setDigitalignatureReps] = useState([]);
  const [visibleQrCodeDialogBox, setVisibleQrCodeDialogBox] = useState(false);
  const [reportId, setReportId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const toast = useRef(null);

  const [reportHeader, setReportHeader] = useState();

  const [currentRep, setCurrentRep] = useState("");

  const sideBarReducer = useSelector(
    (state) => state.sideBarReducer.sideBarReducer
  );

  const repRoleOptionsObj = [
    { name: "CEO", code: "CEO" },
    { name: "Auditor", code: "Auditor" },
    { name: "Chairman of the Board", code: "Chairman of the Board" },
    { name: "Board member", code: "Board member" },
    {
      name: "Chairman of the board and CEO",
      code: "Chairman of the board and CEO",
    },
    { name: "External CEO", code: "External CEO" },
    { name: "Board deputy", code: "Board deputy" },
    { name: "Liquidator", code: "Liquidator" },
  ];

  const [signTypeOptions] = useState(["Paper", "Digital Signature"]);

  const [repRoleOptions] = useState([
    "CEO",
    "Auditor",
    "Chairman of the Board",
    "Board member",
    "Chairman of the board and CEO",
    "External CEO",
    "Board deputy",
    "Board deputy",
    "Liquidator",
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((getHeaderResponse) => {
          if (getHeaderResponse.data) {
            setReportHeader(getHeaderResponse.data);
            setReportId(getHeaderResponse.data.reportID);
            setCompanyName(getHeaderResponse.data.companyName);
            axios
              .get(
                "/annualReportRepresentative/getByReportId?reportID=" +
                  getHeaderResponse.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((getRepresentativeResponse) => {
                let representativeResponseList = getRepresentativeResponse.data;
                if (
                  representativeResponseList != null &&
                  representativeResponseList != "" &&
                  representativeResponseList.length > 0
                ) {
                  // let digitalSignatureRepsList = [];
                  // digitalSignatureRepsList = representativeResponseList.filter(
                  //   (obj) => obj.signType == 1
                  // );

                  setDigitalignatureReps(representativeResponseList);
                }
              });
          }
        });
    }
  }, []);

  const sideBarStatusFnc = (e) => {
    setSideBarStatus(e);
  };

  const sideBarCallback = (value) => {
    if (value) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const digitalSignBtn = (seletedRepDetails) => {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    renderQRScanner(seletedRepDetails);
    setVisibleQrCodeDialogBox(true);

    // if (userSessionAuthKeyReturnValue) {
    //   seletedRepDetails.companyName = companyName;
    //   seletedRepDetails.language = userSelectedLanguage;
    //   axios
    //     .post(
    //       "/annualReportRepresentative/doDigitalSignature",
    //       seletedRepDetails,
    //       userSessionAuthKeyReturnValue
    //     )
    //     .then((response) => {
    //       if (response.data.reportID > 0) {
    //         renderQRScanner(seletedRepDetails);
    //         setVisibleQrCodeDialogBox(true);
    //       }
    //     });
    // }
  };

  const digitalSignatureStatusTemplate = (product) => {
    setCurrentRep(product);
    if (product.signType == 1) {
      if (product.signStatus) {
        return (
          <div>
            <Button
              label="Signed"
              severity="success"
              rounded
              className="dseBtn"
              disabled
            />
          </div>
        );
      } else {
        return (
          <Button
            label="Pending"
            className="dseBtn"
            rounded
            onClick={() => digitalSignBtn(product)}
          />
        );
      }
    }
  };

  const signatureDialogBoxOnHide = () => {
    setCurrentRep("");
    stopScanning();
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReportRepresentative/getByReportId?reportID=" + reportId,
          userSessionAuthKeyReturnValue
        )
        .then((getRepresentativeResponse) => {
          let representativeResponseList = getRepresentativeResponse.data;
          if (
            representativeResponseList != null &&
            representativeResponseList != "" &&
            representativeResponseList.length > 0
          ) {
            // let digitalSignatureRepsList = [];
            // digitalSignatureRepsList = representativeResponseList.filter(
            //   (obj) => obj.signType == 1
            // );

            setDigitalignatureReps(representativeResponseList);
          }
        });
    }

    setVisibleQrCodeDialogBox(false);

    setDigitalSignFail(false);
    setDigitalSignSuccess(false);
    setQrCodeLoading(true);
    // bankIdCheckStatus(1, 1, true);
  };

  const navigateToReviewAndSend = () => {
    history.push("reviewAndSend");
  };

  // const renderQRScanner = () => {
  //   axios.get("/api/start").then((startApiResponse) => {
  //     console.log(startApiResponse, "?? START RESPONSE");
  //     axios.defaults.headers.common["X-CSRF-TOKEN"] =
  //       startApiResponse.data.csrfToken;

  //     const userVisibleData =
  //       "# Underskrift i BankID demo\n\nFöretag som använder BankID i sina tjänster kan lägga in text som beskriver avsikten med en underskrift.";
  //     const userVisibleDataFormat = "SIMPLE_MARKDOWN_V1";

  //     // Call the sign API
  //     axios
  //       .post("/api/sign", { userVisibleData, userVisibleDataFormat })
  //       .then((signApiResponse) => {
  //         console.log(signApiResponse, "?? SIGN RESPONSE");

  //         // Start polling to check the status
  //         const maxAttempts = 30;
  //         let attempts = 0;

  //         let isCompleted = false;
  //         const interval = setInterval(() => {
  //           if (attempts >= maxAttempts) {
  //             clearInterval(interval); // Stop if max attempts reached
  //             return;
  //           }

  //           if (!isCompleted) {
  //             axios
  //               .post("/api/check", {})
  //               .then((checkApiResponse) => {
  //                 console.log(checkApiResponse, "?? CHECK RESPONSE");

  //                 // Check the response status
  //                 if (checkApiResponse.data.status === "PENDING") {
  //                   attempts++;

  //                   let qrCode = checkApiResponse.data.qrCode;

  //                   console.log(qrCode, "@@ QR 1");

  //                   if (qrCode != undefined)
  //                     QRLib.toDataURL(qrCode).then((url) => {
  //                       setQrImage(url);
  //                     });

  //                   console.log(`Attempt ${attempts}: Still PENDING`);
  //                 } else {
  //                   console.log(checkApiResponse, "$$ ELSE");
  //                   clearInterval(interval);
  //                   isCompleted = true;
  //                   // delay();
  //                   console.log(isCompleted, "$$ IS COMPLETE");
  //                 }
  //               })
  //               .catch((error) => {
  //                 console.error("Error fetching data:", error);
  //                 clearInterval(interval); // Stop polling on error
  //               });
  //           }
  //         }, 2000); // Adjust the interval time as needed
  //         return () => clearInterval(interval);

  //         // Cleanup function to clear the interval
  //         // return () => clearInterval(interval);
  //       })
  //       .catch((error) => {
  //         console.error("Error signing:", error);
  //       });
  //   });
  // };

  const renderQRScanner = (seletedRepDetails) => {
    setIsScanning(true); // Set scanning state to true
    setQrCodeLoading(true);
    axios.get("/api/start").then((startApiResponse) => {
      axios.defaults.headers.common["X-CSRF-TOKEN"] =
        startApiResponse.data.csrfToken;

      const userVisibleData =
        "# Signature in BankID\n\n Here I digitaly sign for the annual report of " +
        reportHeader.companyName +
        " for the financial year of " +
        reportHeader.currentYear;
      const userVisibleDataFormat = "SIMPLE_MARKDOWN_V1";

      // Call the sign API
      axios
        .post("/api/sign", { userVisibleData, userVisibleDataFormat })
        .then((signApiResponse) => {
          // const maxAttempts = 30;
          const maxAttempts = 15;
          let attempts = 0;
          let isCompleted = false;

          const newIntervalId = setInterval(() => {
            if (attempts >= maxAttempts) {
              clearInterval(newIntervalId); // Stop if max attempts reached
              setIsScanning(false); // Reset scanning state
              setVisibleQrCodeDialogBox(false);
              return;
            }

            if (!isCompleted) {
              axios
                .post("/api/check", signApiResponse.data)
                .then((checkApiResponse) => {
                  attempts++;
                  // Check the response status
                  if (checkApiResponse.data.status === "PENDING") {
                    let qrCode = checkApiResponse.data.qrCode;

                    if (qrCode !== undefined) {
                      QRLib.toDataURL(qrCode).then((url) => {
                        setQrCodeLoading(false);
                        setQrImage(url);
                      });
                    }
                  } else if (checkApiResponse.data.status === "COMPLETE") {
                    clearInterval(newIntervalId);
                    isCompleted = true;
                    setIsScanning(false); // Reset scanning state

                    const hiffanRemovedFromSSN = seletedRepDetails.ssn.replace(
                      /-/g,
                      ""
                    );

                    // const mergeFirstAndLastName =
                    //   seletedRepDetails.firstName +
                    //   " " +
                    //   seletedRepDetails.lastName;

                    if (
                      checkApiResponse.data.completionResponse.personalNumber ==
                      hiffanRemovedFromSSN
                      //   &&
                      // checkApiResponse.data.completionResponse.name ==
                      //   mergeFirstAndLastName
                    ) {
                      setDigitalSignFail(false);
                      setDigitalSignSuccess(true);

                      const currentEpochTime = Math.floor(Date.now() / 1000);
                      setCurrentRep("");
                      axios
                        .get(
                          "/annualReportRepresentative/updateDigitalSignatureStatus?no=" +
                            seletedRepDetails.no +
                            "&signedDateTime=" +
                            currentEpochTime +
                            "&reportId=" +
                            seletedRepDetails.reportID
                        )
                        .then((getHeaderResponse) => {});

                      // axios
                      //   .get(
                      //     "/annualReportRepresentative/updateDigitalSignatureStatus?name=" +
                      //       mergeFirstAndLastName,
                      //     "&personalNumber=" +
                      //       hiffanRemovedFromSSN +
                      //       '&signedText=""' +
                      //       "&signedDateTime=" +
                      //       signedDateTime
                      //   )
                      //   .then((getHeaderResponse) => {});
                    } else {
                      setDigitalSignFail(true);
                      setDigitalSignSuccess(false);
                    }

                    // setVisibleQrCodeDialogBox(false);
                    stopScanning();
                  }
                })
                .catch((error) => {
                  console.error("Error fetching data:", error);
                  clearInterval(newIntervalId); // Stop polling on error
                  setIsScanning(false); // Reset scanning state
                });
            }
          }, 2000); // Adjust the interval time as needed

          setIntervalId(newIntervalId); // Save the interval ID for later use
        })
        .catch((error) => {
          console.error("Error signing:", error);
        });
    });
  };

  const stopScanning = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIsScanning(false); // Reset scanning state
      setQrImage("");
    }
  };

  // const bankIdCheckStatus = (attempts, maxAttempts, isCompleted) => {
  //   const interval = setInterval(() => {
  //     if (attempts >= maxAttempts) {
  //       clearInterval(interval); // Stop if max attempts reached
  //       return;
  //     }

  //     if (!isCompleted) {
  //       axios
  //         .post("/api/check", {})
  //         .then((checkApiResponse) => {
  //           console.log(checkApiResponse, "?? CHECK RESPONSE");

  //           // Check the response status
  //           if (checkApiResponse.data.status === "PENDING") {
  //             attempts++;

  //             let qrCode = checkApiResponse.data.qrCode;

  //             console.log(qrCode, "@@ QR 1");

  //             if (qrCode != undefined)
  //               QRLib.toDataURL(qrCode).then((url) => {
  //                 setQrImage(url);
  //               });

  //             console.log(`Attempt ${attempts}: Still PENDING`);
  //           } else {
  //             console.log(checkApiResponse, "$$ ELSE");
  //             clearInterval(interval);
  //             isCompleted = true;
  //             // delay();
  //             console.log(isCompleted, "$$ IS COMPLETE");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching data:", error);
  //           clearInterval(interval); // Stop polling on error
  //         });
  //     }
  //   }, 2000); // Adjust the interval time as needed
  //   return () => clearInterval(interval);
  // };

  const onRowEditComplete = (rowData, fieldName) => {
    if (userSessionAuthKeyReturnValue) {
      let updateRepToDB = {
        firstName: rowData.firstName,
        lastName: rowData.lastName,
        sSN: rowData.sSN,
        role: rowData.role,
        reportID: reportId,
        no: rowData.no,
        signType: rowData.signType == "Paper" || rowData.signType == 0 ? 0 : 1,
      };

      axios
        .post(
          "/annualReportRepresentative/update",
          updateRepToDB,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessfulText[userSelectedLanguage],
              detail: TranslationFile.RepUpdateSucess[userSelectedLanguage],
              life: 3000,
            });
            axios
              .get(
                "/annualReportRepresentative/getByReportId?reportID=" +
                  reportId,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let representativeResponseList = response.data;
                if (
                  representativeResponseList != null &&
                  representativeResponseList != "" &&
                  representativeResponseList.length > 0
                ) {
                  // let digitalSignatureRepsList = [];
                  // digitalSignatureRepsList = representativeResponseList.filter(
                  //   (obj) => obj.signType == 1
                  // );

                  setDigitalignatureReps(representativeResponseList);
                }
                // totalRepList = response.data;
                // setTotalRepListArray(totalRepList);
                // checkForGreenTick(totalRepList);
              });
          }

          if (
            document.getElementsByClassName("p-row-editor-save p-link")[0] !=
            undefined
          ) {
            document
              .getElementsByClassName("p-row-editor-save p-link")[0]
              .click();
          }
        });
    }
  };

  const representativeInputOnBlur = (e, field, rowData) => {
    let getRepResponse = "";
    if (userSessionAuthKeyReturnValue) {
      if (field == "SSN" && rowData.signType == 1 && e.target.value == "") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: (
            <>
              SSN is mandatory for <b>Digital Signature</b> Sign Type
            </>
          ),

          life: 6000,
        });
      } else {
        axios
          .get(
            "/annualReportRepresentative/getByReportId?reportID=" + reportId,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            getRepResponse = response.data;
            getRepResponse =
              getRepResponse &&
              getRepResponse.filter((res) => res.no == rowData.no);

            if (getRepResponse[0].firstName !== rowData.firstName) {
              onRowEditComplete(rowData, "firstName");
            } else if (getRepResponse[0].lastName !== rowData.lastName) {
              onRowEditComplete(rowData, "lastName");
            } else if (getRepResponse[0].sSN !== rowData.sSN) {
              onRowEditComplete(rowData, "sSN");
            } else if (getRepResponse[0].role !== rowData.role) {
              onRowEditComplete(rowData, "role");
            } else if (getRepResponse[0].signType !== rowData.signType) {
              onRowEditComplete(rowData, "signType");
            }
          });
      }
    }
  };

  const textEditorFName = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onBlur={(e) => representativeInputOnBlur(e, "FName", options.rowData)}
      />
    );
  };

  const textEditorLName = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onBlur={(e) => representativeInputOnBlur(e, "LName", options.rowData)}
      />
    );
  };

  const textEditorMask = (options) => {
    return (
      <InputMask
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        mask="99999999-9999"
        onBlur={(e) => representativeInputOnBlur(e, "SSN", options.rowData)}
        // required
        placeholder="YYYYMMDD-NNNN"
      />
    );
  };

  const roleEditOnChange = (e, options) => {
    options.rowData.role = e;
  };

  const roleEditOnBlur = (e, options) => {
    representativeInputOnBlur(e, "role", options);
  };

  const roleEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        editable
        options={repRoleOptions}
        onChange={(e) => roleEditOnChange(e.target.value, options)}
        onBlur={(e) => roleEditOnBlur(e, options.rowData)}
        placeholder="Select a Status"
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const signTypeBodyTemplate = (rowData) => {
    let signType = "Paper";
    if (rowData.signType > 0) signType = "Digital Signature";
    return <Tag value={signType}></Tag>;
  };

  const signTypeEditor = (options) => {
    let signType = "Paper";
    if (options.value > 0) signType = "Digital Signature";
    return (
      <Dropdown
        value={signType}
        // editable
        className="signTypeEditDropdown"
        options={signTypeOptions}
        onChange={(e) => signTypeEditOnChange(e.target.value, options)}
        onBlur={(e) => signTypeEditOnBlur(e, options.rowData)}
        placeholder="Select a Sign Type"
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const signTypeEditOnChange = (e, options) => {
    if (e == "Digital Signature" && options.rowData.sSN != "") {
      options.rowData.signType = e;
    } else if (e == "Paper") {
      options.rowData.signType = e;
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: (
          <>
            Please fill the SSN before you change the Sign Type to{" "}
            <b>Digital Signature</b>
          </>
        ),
        life: 6000,
      });
    }
  };

  const signTypeEditOnBlur = (e, options) => {
    representativeInputOnBlur(e, "signType", options);
  };

  return (
    <div>
      <NavBarHeader isAppPortal={true} isHomePage={false} />
      <div className="representative-sidebar-div">
        <Sidebar
          children="Digital Signature"
          sideBarStatus={(e) => sideBarStatusFnc(e)}
          sideBarCallback={(e) => sideBarCallback(e)}
        />
      </div>
      <Toast ref={toast} />
      <div
        className={
          sideBarStatus
            ? "digitalSignature-div"
            : "digitalSignature-div-sidebar"
        }
      >
        <div className="digitalSignPdfDiv">
          <Button
            type="button"
            icon="pi pi-file-pdf"
            outlined
            style={{ borderRadius: "70px" }}
            className="pdf_view_rep"
            tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
            tooltipOptions={{ position: "bottom" }}
            onClick={() => previewPdf(currentReportGUIDFromUrl)}
          />
        </div>

        <Row style={{ width: "100%" }}>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ width: "100%", marginLeft: "12px" }}
          >
            <center>
              <DataTable
                value={digitalignatureReps}
                className="digitalSignatureDataTable"
                editMode="row"
              >
                <Column
                  field="firstName"
                  header="First Name"
                  style={{ width: "20%" }}
                  editor={(options) => textEditorFName(options)}
                ></Column>
                <Column
                  field="lastName"
                  header="Last Name"
                  style={{ width: "20%" }}
                  editor={(options) => textEditorLName(options)}
                ></Column>
                <Column
                  field="sSN"
                  header="SSN"
                  style={{ width: "20%" }}
                  editor={(options) => textEditorMask(options)}
                ></Column>

                <Column
                  field="role"
                  header="Role"
                  style={{ width: "20%" }}
                  editor={(options) => roleEditor(options)}
                ></Column>

                <Column
                  field="signType"
                  header={TranslationFile.signType[userSelectedLanguage]}
                  className="representative-signType"
                  style={{ width: "20%" }}
                  body={signTypeBodyTemplate}
                  editor={(signTypeObj) => signTypeEditor(signTypeObj)}
                ></Column>

                <Column
                  header="Digital Signature Status"
                  body={(e) => digitalSignatureStatusTemplate(e)}
                ></Column>

                <Column
                  rowEditor
                  headerStyle={{ width: "20%", minWidth: "8rem" }}
                  bodyStyle={{ textAlign: "center" }}
                  className="representativeRowEdit"
                ></Column>
              </DataTable>
              <Dialog
                // header="Digital Signature via BankID"
                visible={visibleQrCodeDialogBox}
                blockScroll
                // style={{ width: "23vw", height: "30vw" }}
                onHide={() => {
                  signatureDialogBoxOnHide();
                }}
              >
                <div className="bankIdIframe">
                  {digitalSignSuccess && !digitalSignFail ? (
                    <div class="centerQQ">
                      <center>
                        <div className="digitalSignCheckRespCardDiv">
                          {/* <i
                            className="digitalSignCheckRespIcon"
                            style={{ color: "#9ABC66" }}
                          >
                            ✓
                          </i> */}
                          <i
                            className="pi pi-check-circle"
                            // style={{ color: "slateblue" }}
                            id="digitalSignCheckRespIcon"
                            style={{ color: "#9ABC66" }}
                          ></i>
                        </div>

                        <h1
                          className="digitalSignCheckRespHeading"
                          style={{ color: "#88B04B" }}
                        >
                          Success
                        </h1>

                        <p className="digitalSignCheckRespDetails">
                          BankID digital signature is successfully done!
                        </p>
                      </center>
                    </div>
                  ) : !digitalSignSuccess && digitalSignFail ? (
                    <div class="centerQQ">
                      <center>
                        <div className="digitalSignCheckRespCardDiv">
                          {/* <i
                            className="digitalSignCheckRespIcon"
                            style={{ color: "red" }}
                          >
                            X
                          </i> */}
                          <i
                            className="pi pi-times"
                            id="digitalSignCheckRespIcon"
                            style={{ color: "red" }}
                          ></i>
                        </div>

                        <h1
                          className="digitalSignCheckRespHeading"
                          style={{ color: "red" }}
                        >
                          Authentication Failed !
                        </h1>

                        <p className="digitalSignCheckRespDetails">
                          Please verify the Representative info and try again
                        </p>
                        <div className="digitalSignatureQRFailSSN">
                          <b>SSN : {currentRep.ssn}</b>
                        </div>
                      </center>
                    </div>
                  ) : (
                    !digitalSignFail &&
                    !digitalSignSuccess && (
                      <div class="centerQQ">
                        <center>
                          <div className="bankIdLogoDiv">
                            <img
                              src={BankIdLogo}
                              className="bankIdLogo"
                              width={100}
                              height={100}
                            />
                            <b> Digital Signature via BankID</b>

                            <br />
                            <br />
                          </div>

                          <div className="digitalSignDialogScannerDiv">
                            {/* <b className="digitalSignDialogTitle">
                              Sign with BankID
                            </b>
                            <br /> */}
                            {/* <p className="digitalSignDialogSubTitle">
                              Start the BankID app and scan the QR code.
                            </p> */}

                            <div className="bankIdQRScanner">
                              {qrCodeLoading ? (
                                <div className="digitalSignQrCodeLoadingDiv">
                                  <ProgressSpinner className="digitalSignQrCodeProgress" />
                                </div>
                              ) : (
                                <img src={qrImage} />
                                // <>BASHA</>
                              )}
                            </div>
                          </div>
                        </center>
                      </div>
                    )
                  )}
                </div>
              </Dialog>
            </center>
          </Col>
        </Row>
      </div>
      <center>
        <Button
          label="Review & Send"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={() => navigateToReviewAndSend()}
        />
      </center>
    </div>
  );
}
