import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Menu/Header";
import { Button } from "primereact/button";
import Switch from "react-switch";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import Footerpage from "../Footerpage/Footerpage";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { connect } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import $, { camelCase } from "jquery";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import "./Notes.css";
import Select from "react-select";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation.json";
import { ProgressSpinner } from "primereact/progressspinner";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let notesTemplateReqArray = [],
  notesTemplateResponse = {},
  tabTitleArray = [],
  pdfLink = "",
  sideBarGreenTickPages = [],
  currentReportGUIDFromUrl = "";

let addedNewCustHeader = {};
let addedNewCusLine = {};
let addedNewCusLineList = [];
let addedNewCusFirstLine = {};

//NEW
let custUsedLineNos = [],
  custHeaderArray = [],
  custFirstLineArray = [],
  custLineListArray = [],
  removedDuplicateLineNo = [],
  notesTemplate = [],
  gCurrentHeaderNo = 0,
  gCurrentLineNo = 0,
  gCurrentLineNoOnly = 0,
  isLineSumAlreadyExists = true,
  addNewBtnLbl = "Lägga till nya anteckningar",
  accordionHeadersArray = [],
  mergedDialogAllLines = [],
  showLinesPlusInDialog = true,
  linkSharedUser;

let dialogTemplateOptions = [],
  emptyTextAreaLineNosFromTemplate = [];

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    incomeStatement: state.incomeStatement.incomeStatement,
    balanceSheet: state.balanceSheet.balanceSheet,
    notes: state.notes.notes,
    notesTemplateReqArrayReducer: state.notes.notesTemplateReqArrayReducer,
  };
};

let steps = [
  {
    content: TranslationFile.TourGuide_AddNewColumn_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".NotesAddNewColumnLbl",
    title: "Add New Record",
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_DeleteRecord_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#notesDeleteHeader",
    title: "Delete Field",
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_Add_NewNotes_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#notesAddNewBtn",
    title: "Add New Notes",
    disableBeacon: true,
    delay: 500,
  },
];

class NotesNew extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      notesValue: {},
      activeIndex: [0],
      sideBarStatus: true,
      latestCustomLineNo: 0,
      toggleOpen: false,
      companyInformation: "",
      companyName: "",
      currentFinancialYear: "",
      nextEnable: false,
      lastTemplateLineSumNo: 0,
      lastTemplateLineObj: {},
      lastCustLineNo: 0,
      lastTemplateHeaderNo: 0,
      lastTemplateHeaderObj: {},
      lastCustHeaderNo: 0,
      currentCustHeaderNo: 0,
      isAddNewNotesDialog: false,
      isAddNewNotesFromTemplateDialog: false,
      custNotesHeader: "",
      addNewCustLineTxt: "",
      lastCustDialogLineNo: 0,

      //NEW
      currentHeaderNo: 0,
      lastCustLineNo: 0,
      custNotesFirstLine: "",
      isCustNotesFirstLineHeader: false,
      selectedTemplateHeader: "",
      isShowFirstLine: true,
      selectedNoteType: "",
      selectedNoteTypeObj: "",
      isLoading: false,
      showPageInCompleteToast: false,
      run: false,
    };
    this.accept = this.accept.bind(this);
  }

  convert = (str) =>
    str.split(",").flatMap((s) => {
      if (!s.includes("-")) return +s;
      const [min, max] = s.split("-");
      return Array.from({ length: max - min + 1 }, (_, n) => n + +min);
    });

  openAll(resp) {
    let headerIdxList = [];
    Object.keys(resp).map((heading, idx) => {
      headerIdxList.push(idx);
    });
    let open = headerIdxList;
    this.setState({ activeIndex: open });
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    let {
      companyInformation,
      notes,
      notesTemplateReqArrayReducer,
      sessionDetails,
      sideBarReducer,
    } = this.props;

    localStorage.setItem("currentPageName", "Notes");

    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    pdfLink = "#annualreport/" + currentReportGUIDFromUrl + "/notesPdf";
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseDate = response.data;

          if (responseDate != "") {
            let reportAccessReq = {
              reportId: responseDate.reportID,
            };
            axios
              .post(
                "/annualReport/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {
                  case 2:
                    linkSharedUser = 2; //Write
                    break;
                  case 3:
                    linkSharedUser = 3; //Read Only
                    break;
                  case 4:
                    linkSharedUser = 4; //No Access
                    break;
                }
              })
              .catch((error) => {
                let noAccessURL =
                  "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                this.props.history.push(noAccessURL);
              });
          }
          // }
          if (responseDate.companyName != "") {
            this.setState({
              companyName: responseDate.companyName,
            });
          }

          if (responseDate.currentYear != "")
            this.setState({
              currentFinancialYear: responseDate.currentYear,
            });
        });

      let currentReportCompanyName = localStorage.getItem(
        "navigatedCompanyName"
      );
      if (currentReportCompanyName != "") {
        this.setState({
          companyInformation: currentReportCompanyName,
        });
      }

      let getJsonLines = {
        guid: currentReportGUIDFromUrl,
        pageName: "Notes",
      };

      axios
        .post(
          "/annualReportLine/getLinesJson",
          getJsonLines,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseData = response.data.notes;

          if (responseData == undefined) {
            responseData = "";
          }

          if (responseData != "") {
            let jsonConvertedResponseData = JSON.parse(responseData);
            let financialYearFromJsonResponse = [];
            Object.keys(jsonConvertedResponseData).map((i, idx) => {
              if (i == "financialYear") {
                financialYearFromJsonResponse = [
                  ...jsonConvertedResponseData[i],
                ];
              }
            });

            notesTemplateReqArray = financialYearFromJsonResponse;

            axios
              .post(
                "/user/getAnnualReportTemplateDetails",
                notesTemplateReqArray,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                notesTemplateResponse = response.data;
                notesTemplate = notesTemplateResponse;
                Object.keys(notesTemplate).map((i, idx) => {
                  let lines = [...notesTemplate[i]];
                  lines = lines.filter(
                    (obj) => obj.isLineFromDialog == undefined
                  );
                  notesTemplate[i] = lines;

                  let emptyTextAreaLineNo = lines.filter(
                    (obj) => obj.dataType == "Textarea" && obj.name == ""
                  );

                  if (emptyTextAreaLineNo.length > 0) {
                    emptyTextAreaLineNo &&
                      emptyTextAreaLineNo.length &&
                      emptyTextAreaLineNo.map(
                        (emptyTextAreaObj, emptyTextAreaObjIdx) => {
                          emptyTextAreaLineNosFromTemplate.push(
                            emptyTextAreaObj.lineNo
                          );
                        }
                      );
                  }
                });
              });

            delete jsonConvertedResponseData.financialYear;

            tabTitleArray = jsonConvertedResponseData;
            this.openAll(jsonConvertedResponseData);
            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                  response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                sideBarReducer.values = completedStatementTypesResponse.data;
                sideBarGreenTickPages = completedStatementTypesResponse.data;
                this.setState({
                  reRender: true,
                });
              });

            Object.keys(jsonConvertedResponseData).map((i, idx) => {
              // if (Object.keys(jsonConvertedResponseData).length == idx + 1) {
              let sortedRespArray = [...jsonConvertedResponseData[i]].sort(
                (a, b) => a.lineNo - b.lineNo
              );

              setTimeout(() => {
                let arry = [];
                sortedRespArray.map((obj, objIdx) => {
                  if (obj.column1 == "") {
                    obj.name = "";
                  }
                  arry.push(obj);
                });

                let getDialogAddedNotes = sortedRespArray.filter(
                  (obj) => obj.isLineFromDialog == true
                );

                let filterLastTemplateLineSumNo = [],
                  filterLastTemplateHeaderNo = [];

                if (getDialogAddedNotes.length > 0) {
                  filterLastTemplateLineSumNo = getDialogAddedNotes.filter(
                    (obj) => obj.isHeader == false
                  );
                  filterLastTemplateHeaderNo = getDialogAddedNotes.filter(
                    (obj) => obj.isHeader
                  );

                  filterLastTemplateLineSumNo = [
                    ...filterLastTemplateLineSumNo,
                  ].sort((a, b) => a.lineNo - b.lineNo);

                  filterLastTemplateHeaderNo = [
                    ...filterLastTemplateHeaderNo,
                  ].sort((a, b) => a.lineNo - b.lineNo);
                } else {
                  filterLastTemplateLineSumNo = sortedRespArray.filter(
                    (obj) => obj.isLineSum
                  );
                  filterLastTemplateHeaderNo = sortedRespArray.filter(
                    (obj) => obj.isHeader
                  );

                  filterLastTemplateLineSumNo = [
                    ...filterLastTemplateLineSumNo,
                  ].sort((a, b) => a.lineNo - b.lineNo);

                  filterLastTemplateHeaderNo = [
                    ...filterLastTemplateHeaderNo,
                  ].sort((a, b) => a.lineNo - b.lineNo);
                }
                this.setState({
                  lastTemplateLineSumNo:
                    filterLastTemplateLineSumNo[
                      filterLastTemplateLineSumNo.length - 1
                    ] &&
                    filterLastTemplateLineSumNo[
                      filterLastTemplateLineSumNo.length - 1
                    ].lineNo,

                  lastTemplateLineObj:
                    filterLastTemplateLineSumNo[
                      filterLastTemplateLineSumNo.length - 1
                    ],

                  lastTemplateHeaderNo:
                    filterLastTemplateHeaderNo[
                      filterLastTemplateHeaderNo.length - 1
                    ].lineNo,

                  lastTemplateHeaderObj:
                    filterLastTemplateHeaderNo[
                      filterLastTemplateHeaderNo.length - 1
                    ],
                });

                this.setState({
                  reRender: true,
                });
                // }
              }, 500);
            });

            this.setState({
              notesValue: jsonConvertedResponseData,
              // isLoading: false,
            });
          } else {
            notesTemplateReqArray = [];

            if (companyInformation != undefined) {
              companyInformation != undefined &&
                companyInformation.financialYear.length &&
                companyInformation.financialYear.map((financialYear, idx) => {
                  let notesTemplateReq = {
                    validFromDate: moment(financialYear.from).format(
                      "YYYY-MM-DD"
                    ),
                    validToDate: moment(financialYear.to).format("YYYY-MM-DD"),
                    statementType: 1,
                  };
                  notesTemplateReqArray.push(notesTemplateReq);
                });

              let companyValues = companyInformation.organizationname;
              this.setState({
                companyInformation: companyValues,
              });

              axios
                .post(
                  "/user/getAnnualReportTemplateDetails",
                  notesTemplateReqArray,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  notesTemplateResponse = response.data;
                  notesTemplate = notesTemplateResponse;
                  Object.keys(notesTemplate).map((i, idx) => {
                    let lines = [...notesTemplate[i]];
                    lines = lines.filter(
                      (obj) => obj.isLineFromDialog == undefined
                    );
                    notesTemplate[i] = lines;

                    let emptyTextAreaLineNo = lines.filter(
                      (obj) => obj.dataType == "Textarea" && obj.name == ""
                    );

                    if (emptyTextAreaLineNo.length > 0) {
                      emptyTextAreaLineNo &&
                        emptyTextAreaLineNo.length &&
                        emptyTextAreaLineNo.map(
                          (emptyTextAreaObj, emptyTextAreaObjIdx) => {
                            emptyTextAreaLineNosFromTemplate.push(
                              emptyTextAreaObj.lineNo
                            );
                          }
                        );
                    }
                  });

                  this.dataManipulation(notesTemplateResponse, true, false);
                  tabTitleArray = notesTemplateResponse;
                  this.openAll(notesTemplateResponse);
                });

              if (notesTemplateReqArrayReducer.values != undefined) {
                notesTemplateReqArray = notesTemplateReqArrayReducer.values;
              }
            } else {
              let getJsonLines = {
                guid: currentReportGUIDFromUrl,
                pageName: "Company Information",
              };

              axios
                .post(
                  "/annualReportLine/getLinesJson",
                  getJsonLines,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  let responseData = response.data.companyInformation;

                  if (responseData == undefined) {
                    responseData = "";
                  }

                  if (responseData != "") {
                    let jsonConvertedResponseData = JSON.parse(responseData);

                    companyInformation = jsonConvertedResponseData.values;

                    jsonConvertedResponseData.values.financialYear.map(
                      (financialYear, idx) => {
                        let balanceSheetTemplateReq = {
                          validFromDate: moment(financialYear.from).format(
                            "YYYY-MM-DD"
                          ),
                          validToDate: moment(financialYear.to).format(
                            "YYYY-MM-DD"
                          ),
                          statementType: 1,
                        };
                        notesTemplateReqArray.push(balanceSheetTemplateReq);
                      }
                    );

                    axios
                      .post(
                        "/user/getAnnualReportTemplateDetails",
                        notesTemplateReqArray,
                        userSessionAuthKeyReturnValue
                      )
                      .then((response) => {
                        notesTemplateResponse = response.data;
                        notesTemplate = notesTemplateResponse;
                        Object.keys(notesTemplate).map((i, idx) => {
                          let lines = [...notesTemplate[i]];
                          lines = lines.filter(
                            (obj) => obj.isLineFromDialog == undefined
                          );
                          notesTemplate[i] = lines;

                          let emptyTextAreaLineNo = lines.filter(
                            (obj) =>
                              obj.dataType == "Textarea" && obj.name == ""
                          );

                          if (emptyTextAreaLineNo.length > 0) {
                            emptyTextAreaLineNo &&
                              emptyTextAreaLineNo.length &&
                              emptyTextAreaLineNo.map(
                                (emptyTextAreaObj, emptyTextAreaObjIdx) => {
                                  emptyTextAreaLineNosFromTemplate.push(
                                    emptyTextAreaObj.lineNo
                                  );
                                }
                              );
                          }
                        });
                        this.dataManipulation(
                          notesTemplateResponse,
                          true,
                          false
                        );
                        tabTitleArray = notesTemplateResponse;
                        this.openAll(notesTemplateResponse);
                      });
                  }
                });
            }
          }
        });
    }
  }

  dataManipulation(resp, isInitialRender, isOnChangeCall) {
    let { companyInformation, sessionDetails, login, notes } = this.props;
    let fieldExistsInMultiYear = [],
      lineNoArray = [],
      lineDetailsArray = [];
    if (userSessionAuthKeyReturnValue) {
      delete resp.financialYear;
      Object.keys(resp).map((i, idx) => {
        // if (Object.keys(resp).length == idx + 1) {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);

        let filterLastTemplateLineSumNo = sortedRespArray.filter(
          (obj) => obj.isLineSum
        );
        let filterLastTemplateHeaderNo = sortedRespArray.filter(
          (obj) => obj.isHeader
        );

        filterLastTemplateLineSumNo = [...filterLastTemplateLineSumNo].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        filterLastTemplateHeaderNo = [...filterLastTemplateHeaderNo].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        if (
          filterLastTemplateLineSumNo &&
          filterLastTemplateLineSumNo.length > 0 &&
          filterLastTemplateHeaderNo &&
          filterLastTemplateHeaderNo.length > 0
        ) {
          this.setState({
            lastTemplateLineSumNo:
              filterLastTemplateLineSumNo[
                filterLastTemplateLineSumNo.length - 1
              ].lineNo,

            lastTemplateLineObj:
              filterLastTemplateLineSumNo[
                filterLastTemplateLineSumNo.length - 1
              ],
            lastTemplateHeaderNo:
              filterLastTemplateHeaderNo[filterLastTemplateHeaderNo.length - 1]
                .lineNo,

            lastTemplateHeaderObj:
              filterLastTemplateHeaderNo[filterLastTemplateHeaderNo.length - 1],
          });
        }
        // }
      });

      delete resp.financialYear;
      if (notes.values != undefined && !isOnChangeCall) {
        resp = notes.values;
      } else {
        Object.keys(resp).map((i, idx) => {
          let sortedRespArray = [...resp[i]].sort(
            (a, b) => a.lineNo - b.lineNo
          );
          sortedRespArray.map((sortedResp, sortedRespIdx) => {
            sortedResp.reportGUID = currentReportGUIDFromUrl;
            if (
              sortedResp.isHeader &&
              sortedResp.lineLevel == 2 &&
              sortedResp.dataType == "Label"
            ) {
              if (sortedResp.financialYearValue == 0) {
                sortedResp.column1 = sortedResp.name;
                sortedResp.column2 = sortedResp.financialYearRange;
              } else {
                let columnNo = sortedResp.financialYearValue * -1 + 2;
                if (sortedResp.financialYearRange != undefined) {
                  sortedResp["column" + columnNo] =
                    sortedResp.financialYearRange;

                  let cc = sortedRespArray.filter(
                    (obj) => obj.lineNo == sortedResp.lineNo
                  );

                  cc.map((i, idx) => {
                    return (i["column" + columnNo] =
                      sortedResp.financialYearRange);
                  });
                }
              }
            } else {
              let columnNo = sortedResp.financialYearValue * -1 + 2;
              if (sortedResp.lineAmount != undefined) {
                sortedResp["column" + columnNo] = sortedResp.lineAmount;
              }
            }
          });
        });

        if (isInitialRender) {
          // calculating lineAmount
          Object.keys(resp).map((i, idx) => {
            let sortedRespArray = [...resp[i]].sort(
              (a, b) => a.lineNo - b.lineNo
            );
            sortedRespArray.map((sortedResp, sortedRespIdx) => {
              sortedResp["nameTemp"] = sortedResp.name;
              let range = this.convert(sortedResp.range, "-");
              if (range[0] == 0) {
                range = this.convert(sortedResp.lineSumRange, "-");
              }
              // if (companyInformation != undefined) {
              //   let SIELine =
              //     companyInformation.SIEBalanceSheet[sortedResp.year];

              //   if (SIELine != undefined) {
              //     SIELine.map((SIELineValue, SIELineIdx) => {
              //       if (range.includes(SIELineValue.accountNo)) {
              //         if (
              //           sortedRespArray[sortedRespIdx]["lineAmount"] ==
              //           undefined
              //         ) {
              //           sortedRespArray[sortedRespIdx]["lineAmount"] =
              //             SIELineValue.amount;
              //         } else {
              //           sortedRespArray[sortedRespIdx]["lineAmount"] +=
              //             SIELineValue.amount;
              //         }
              //       }
              //     });
              //   }
              // }
            });
          });
        }
      }

      // calculating yearwise lineAmount
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);

        sortedRespArray = sortedRespArray.filter(
          (obj) => obj.lineSumRange != ""
        );
        sortedRespArray.length &&
          sortedRespArray.map((sortedResp, sortedRespIdx) => {
            let range = this.convert(sortedResp.lineSumRange, "-");
            let fyYearValue = sortedResp.year;
            let lineSumValue = 0;

            Object.keys(resp).map((innerRespObj, innerRespIdx) => {
              let innerSortedRespArray = [...resp[innerRespObj]].sort(
                (a, b) => a.lineNo - b.lineNo
              );

              innerSortedRespArray = innerSortedRespArray.filter(
                (obj) =>
                  obj.year == fyYearValue &&
                  obj.lineType == "Line" &&
                  obj.lineAmount != undefined
              );

              innerSortedRespArray.map(
                (innerSortedRespValue, innerSortedRespIdx) => {
                  if (range.includes(innerSortedRespValue.lineNo)) {
                    lineSumValue += innerSortedRespValue.lineAmount;
                  }
                }
              );
            });
            sortedResp["lineAmount"] = lineSumValue;
            let columnNo = sortedResp.financialYearValue * -1 + 2;
            sortedResp["column" + columnNo] = lineSumValue;
          });
      });

      //merging muti year object
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
        sortedRespArray.map((sortedResp, sortedRespIdx) => {
          sortedResp["reportGUID"] = currentReportGUIDFromUrl;
          sortedResp["createdByUserID"] = login == undefined ? 0 : login.userID;

          const filtered = sortedRespArray.filter(
            (obj) => obj.lineNo == sortedResp.lineNo
          );

          let arr = [];
          filtered.length &&
            filtered.map((j, jdx) => {
              let cc = JSON.parse(JSON.stringify(j));

              Object.keys(cc).forEach((key) => {
                if (cc[key] === null || cc[key] == 0) {
                  delete cc[key];
                }
              });

              arr.push(cc);
            });

          fieldExistsInMultiYear.push(Object.assign({}, ...arr));
        });
      });

      fieldExistsInMultiYear.length &&
        fieldExistsInMultiYear.map((i, idx) => {
          if (!lineNoArray.includes(i.lineNo)) {
            lineNoArray.push(i.lineNo);
            if (i.customTextAreaValue != undefined) {
              i.column1 = i.nameTemp + i.customTextAreaValue;
            } else {
              if (i.nameTemp != undefined) {
                i.column1 = i.nameTemp;
              } else {
                i.column1 = i.name;
              }
            }

            if (i.dataType != "Hide" || i.dataType != "Button") {
              i.reportGUID = currentReportGUIDFromUrl;
              lineDetailsArray.push(i);
            }
          }
        });

      if (lineDetailsArray.length > 0) {
        axios
          .post(
            "/annualReportLine/insertLines",
            lineDetailsArray,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {});
      }

      this.setState({
        notesValue: resp,
        // isLoading: false,
      });

      notes.values = resp;

      let responseJson = resp;
      responseJson["financialYear"] = notesTemplateReqArray;

      // if (userSessionAuthKeyReturnValue) {
      let currentPageName = localStorage.getItem("currentPageName");

      let splittedUrl = window.location.href.split("/");
      currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

      let updateJsonTableObj = {
        pageName: "Notes",
        guid: currentReportGUIDFromUrl,
        json: JSON.stringify(responseJson),
      };
      axios
        .post(
          "/annualReportLine/updateLinesJsonTable",
          updateJsonTableObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {});
      // }
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  stringInputOnChange(e, parentHeading, lineNo) {
    const { notesValue } = this.state;
    notesValue[parentHeading]
      .filter((obj) => obj.lineNo == lineNo)
      .map((line, lineIdx) => {
        line.column2 = e.target.value;
      });

    this.setState({
      reRender: true,
    });
  }

  stringInputOnBlur(e, parentHeading, lineNo) {
    const { notesValue } = this.state;
    let isStringInputUpdated = false;

    let sessionDetailsLS = localStorage.getItem("sessionDetails");
    let sessionDetailsParse = JSON.parse(sessionDetailsLS);
    if (userSessionAuthKeyReturnValue) {
      notesValue[parentHeading]
        .filter((obj) => obj.lineNo == lineNo)
        .map((line, lineIdx) => {
          if (!isStringInputUpdated) {
            isStringInputUpdated = true;
            line.column2 = e.target.value;
            line.column1 = line.name;

            axios
              .post(
                "/annualReportDetails/updateDetailsTextArea",
                line,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                if (response.data) {
                  let responseJson = notesValue;
                  responseJson["financialYear"] = notesTemplateReqArray;

                  // if (userSessionAuthKeyReturnValue) {
                  let currentPageName = localStorage.getItem("currentPageName");

                  let splittedUrl = window.location.href.split("/");
                  let currentReportGUIDFromUrl =
                    splittedUrl[splittedUrl.length - 2];

                  let updateJsonTableObj = {
                    pageName: "Notes",
                    guid: currentReportGUIDFromUrl,
                    json: JSON.stringify(responseJson),
                  };
                  axios
                    .post(
                      "/annualReportLine/updateLinesJsonTable",
                      updateJsonTableObj,
                      userSessionAuthKeyReturnValue
                    )
                    .then((response) => {});
                  // }
                }
              });
          }
        });

      this.setState({
        reRender: true,
      });
    }
  }

  onTextAreaFocusOut(e, parentHeading, lineNo) {
    const { notesValue } = this.state;
    let custText = e.target.value;

    let textAreaValuesOfFys = [];

    let sessionDetailsLS = localStorage.getItem("sessionDetails");
    let sessionDetailsParse = JSON.parse(sessionDetailsLS);
    if (userSessionAuthKeyReturnValue) {
      notesValue[parentHeading]
        .filter((obj) => obj.lineNo == lineNo)
        .map((sentance, sentanceIdx) => {
          sentance["column1"] = custText;

          textAreaValuesOfFys.push(sentance);
        });

      let mergedTextAreaValuesOfFys = [];
      mergedTextAreaValuesOfFys.push(Object.assign({}, ...textAreaValuesOfFys));

      this.setState({
        reRender: true,
      });

      axios
        .post(
          "/annualReportDetails/updateDetailsTextArea",
          mergedTextAreaValuesOfFys[0],
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseJson = notesValue;
            responseJson["financialYear"] = notesTemplateReqArray;

            // if (userSessionAuthKeyReturnValue) {
            let currentPageName = localStorage.getItem("currentPageName");
            let splittedUrl = window.location.href.split("/");
            let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

            let updateJsonTableObj = {
              pageName: "Notes",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };

            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
            // }
          }
        });
    }
  }

  validateForm(
    e,
    parentHeading,
    idx,
    prevousObject,
    BSTemplateFieldOutsideMap
  ) {
    const { latestCustomLineNo, reportGUID, notesValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      let responseArray = notesValue[parentHeading];
      responseArray.map((respValue, respIdx) => {
        if (respValue.lineNo == BSTemplateFieldOutsideMap.lineNo) {
          respValue.name = e.target.value;
        }
      });

      let sessionDetailsLS = localStorage.getItem("sessionDetails");
      let sessionDetailsParse = JSON.parse(sessionDetailsLS);

      let newRowObj = {
        name: e.target.value,
        column1: e.target.value,
        reportGUID: currentReportGUIDFromUrl,
        lineNo:
          latestCustomLineNo != 0
            ? latestCustomLineNo
            : BSTemplateFieldOutsideMap.lineNo,
        parentLineNo: prevousObject.parentLineNo,
        statementType: "Notes",
      };

      axios
        .post(
          "/annualReportLine/updateCustomLineName",
          newRowObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseJson = notesValue;
            responseJson["financialYear"] = notesTemplateReqArray;

            // if (userSessionAuthKeyReturnValue) {
            let currentPageName = localStorage.getItem("currentPageName");

            let splittedUrl = window.location.href.split("/");
            let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

            let updateJsonTableObj = {
              pageName: "Notes",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };

            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
            // }
          }
        });
    }
  }

  customOnChange(value, template, heading) {
    const { notesValue } = this.state;

    Object.keys(notesValue).map((i, idx) => {
      let filteredObjectsToRemove = notesValue[i].filter(
        (item) => item.lineNo == template.lineNo
      );

      filteredObjectsToRemove.map((j, jdx) => {
        j.name = value;
        j.column1 = value;
      });
    });

    this.setState({
      reRender: true,
    });
  }

  accordionHeader(header, parentIdx, headerObj) {
    if (parentIdx > 0 && headerObj.isLineFromDialog == undefined) {
      let dataObj = {
        value: header,
        label: header,
        lineNo: headerObj.lineNo,
      };

      accordionHeadersArray.push(dataObj);
    }

    accordionHeadersArray = accordionHeadersArray.reduce(
      (accumulator, current) => {
        if (!accumulator.find((item) => item.value === current.value)) {
          accumulator.push(current);
        }
        return accumulator;
      },
      []
    );

    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "100%", marginTop: "7px" }}
        >
          <div>
            <label className="BSFieldsStyle">{header}</label>
            {/* <div className="inner">
              <Button label="Add new column" className="notesAddColumnBtn" />
            </div> */}
          </div>
        </Col>
      </Row>
    );
  }

  AmountOnBlur(e, mutilYear, heading) {
    const { notesValue } = this.state;

    let custEnteredValue = e.target.value;
    custEnteredValue = custEnteredValue.replaceAll(",", "");

    notesValue[heading].map((i, idx) => {
      if (i.lineNo == mutilYear.lineNo && i.year == mutilYear.year) {
        // let convertedToNumb = Number(custEnteredValue.replaceAll(/\s/g, ""));
        let spacesRemoved = custEnteredValue.replaceAll(/\s/g, "");

        const firstChar = spacesRemoved.charAt(0);
        let minusArray = ["-", "−", "‑", "−"];
        if (minusArray.includes(firstChar)) {
          const newStr = spacesRemoved.slice(1);
          return (i["lineAmount"] = -1 * newStr);
        } else return (i["lineAmount"] = Number(spacesRemoved));
        // return (i["lineAmount"] = convertedToNumb);
      }
    });
    this.setState({
      notesValue: "",
    });

    this.dataManipulation(notesValue, false, true);
  }

  AmountOnChange(e, mutilYear, heading) {
    const { notesValue } = this.state;
    if (e.value != null) {
      notesValue[heading].map((i, idx) => {
        if (i.lineNo == mutilYear.lineNo && i.year == mutilYear.year) {
          return (i["lineAmount"] = e.value);
        }
      });

      this.setState({
        notesValue: "",
      });

      this.dataManipulation(notesValue, false, true);
    }
  }

  onClickAddNewRow(
    name,
    prevousObject,
    currentValue,
    takeNextLineNoFromCurrentValue,
    headingValue
  ) {
    const { notesValue } = this.state;
    let tempNotesValue = notesValue;
    if (userSessionAuthKeyReturnValue) {
      Object.keys(tempNotesValue).map((heading, idx) => {
        let sortedRespArray = [...tempNotesValue[heading]].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        const emptyCustRowArray = sortedRespArray.filter(
          (obj) =>
            obj.name == "" &&
            !emptyTextAreaLineNosFromTemplate.includes(obj.lineNo)
        );
        if (emptyCustRowArray.length) {
          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              emptyCustRowArray[0],
              userSessionAuthKeyReturnValue
            )
            .then((response) => {});
        }

        const emptyCustRowRemovedArray = sortedRespArray.filter(
          (obj) =>
            obj.name != "" ||
            emptyTextAreaLineNosFromTemplate.includes(obj.lineNo)
        );
        tempNotesValue[heading] = emptyCustRowRemovedArray;
      });

      let isCustLineInsertNeeded = false;
      let newRowObj = {};

      let existingFYforHeader = [],
        existingFYObjforHeader = [];
      Object.keys(tempNotesValue).map((i, idx) => {
        if (i == name) {
          let responseArray = tempNotesValue[i];
          responseArray.length &&
            responseArray.map((j, jdx) => {
              if (!existingFYforHeader.includes(j.year)) {
                existingFYforHeader.push(j.year);
              }
            });
        }
      });

      Object.keys(tempNotesValue).map((heading, idx) => {
        if (heading == name) {
          let linesList = [];
          linesList = tempNotesValue[heading].filter((obj) => !obj.isLineSum);

          let emptyNameRowList = [];
          emptyNameRowList = linesList.filter(
            (obj) =>
              obj.name == "" &&
              !emptyTextAreaLineNosFromTemplate.includes(obj.lineNo)
          );

          if (emptyNameRowList.length == 0) {
            linesList = tempNotesValue[heading].filter(
              (obj) => !obj.isLineSum && !obj.isFinalSum
            );

            let lineNo = prevousObject.lineNo + 1;
            let calcNextLineNo = false;

            for (
              let i = prevousObject.lineNo;
              i <= prevousObject.lineNo + 1000;
              i++
            ) {
              let lineNoObj = [];
              lineNoObj = [...tempNotesValue[heading]].filter(
                (obj) => obj.lineNo == i
              );
              if (lineNoObj.length == 0 && !calcNextLineNo) {
                lineNo = i;
                calcNextLineNo = true;
              }
            }

            this.setState({
              latestCustomLineNo: lineNo,
            });

            existingFYforHeader.map((selectedYear, fyIdx) => {
              newRowObj = {
                name: "",
                range: "",
                year: selectedYear,
                statementType: "Notes",
                statementSubType: prevousObject.statementSubType,
                isLineSum: false,
                isHeader: false,
                lineSumRange: "",
                isFinalSum: false,
                no: prevousObject.no,
                lineNo: lineNo,
                parentLineNo: prevousObject.parentLineNo,
                lineType: prevousObject.lineType,
                dataType: prevousObject.dataType,
                column1: "",
                column2: "",
                column3: "",
                column4: "",
                column5: "",
                column6: "",
                lineLevel: prevousObject.lineLevel,
                financialYearValue: fyIdx == 0 ? 0 : fyIdx * -1,
                nameTemp: "",
                reportGUID: currentReportGUIDFromUrl,
                createdByUserID: prevousObject.createdByUserID,
                isCustomRow: true,
              };

              this.setState({
                reportGUID: currentReportGUIDFromUrl,
              });

              linesList.push(newRowObj);

              let responseArray = tempNotesValue[heading];
              responseArray.push(newRowObj);

              responseArray = [...responseArray].sort(
                (a, b) => a.lineNo - b.lineNo
              );

              tempNotesValue[heading] = responseArray;
              this.setState({
                notesValue: tempNotesValue,
              });
            });
            isCustLineInsertNeeded = true;
          }
        }
      });

      this.setState({
        notesValue: tempNotesValue,
      });

      if (isCustLineInsertNeeded) {
        axios
          .post(
            "/annualReportLine/insertCustomLine",
            newRowObj,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {});
      }

      this.setState({
        reRender: true,
      });
    }
  }

  onClickAddNewColumn(tabHeadingValue) {
    const { notesValue } = this.state;
    const { companyInformation, notes, notesTemplateReqArrayReducer } =
      this.props;
    let notesValueClone;

    let uniqueObj = [],
      existingObj = [],
      headerWiseFy = [],
      addColumnCountArray = [];

    if (userSessionAuthKeyReturnValue) {
      addColumnCountArray = notesTemplateReqArray.filter(
        (obj) => obj.heading == undefined || obj.heading == tabHeadingValue
      );

      if (addColumnCountArray.length == 5) {
        this.toast.show({
          severity: "error",
          summary: TranslationFile.LimitReachedText[userSelectedLanguage],
          // detail: "Please fill the starting date and ending date",
          life: 5000,
        });
      } else {
        notesTemplateReqArray.map((i, idx) => {
          if (i.heading != undefined && i.heading == tabHeadingValue) {
            headerWiseFy.push(i);
          }
        });

        if (headerWiseFy.length == 0) {
          headerWiseFy = notesTemplateReqArray.filter(
            (obj) => obj.heading == undefined
          );
        }

        let notesTemplateReq = {
          validFromDate: moment(
            headerWiseFy[headerWiseFy.length - 1].validFromDate
          ),
          validToDate: moment(
            headerWiseFy[headerWiseFy.length - 1].validFromDate
          ),
          statementType: 1,
          isCustAddedColumn: true,
          heading: tabHeadingValue,
        };

        notesTemplateReq.validFromDate = moment(
          notesTemplateReq.validFromDate
        ).subtract(12, "month")._d;

        notesTemplateReq.validFromDate = moment(
          notesTemplateReq.validFromDate
        ).format("YYYY-MM-DD");
        notesTemplateReq.validToDate = moment(
          notesTemplateReq.validToDate
        ).subtract(1, "day")._d;

        notesTemplateReq.validToDate = moment(
          notesTemplateReq.validToDate
        ).format("YYYY-MM-DD");
        notesTemplateReqArray.push(notesTemplateReq);

        notesTemplateReqArrayReducer.values = notesTemplateReqArray;

        this.setState({
          reRender: true,
        });

        let notesValues = "";
        if (notes.values != undefined) {
          notesValues = notes.values;
        } else {
          notesValues = notesValue;
        }
        Object.keys(notesValues).map((heading, idx) => {
          if (tabHeadingValue == heading) {
            let sortedRespArray = [...notesValues[heading]].sort(
              (a, b) => a.lineNo - b.lineNo
            );

            const cloneSortedRespArray = [...sortedRespArray];
            cloneSortedRespArray.map((i, idx) => {
              if (!existingObj.includes(i.lineNo)) {
                uniqueObj.push(i);
                existingObj.push(i.lineNo);
              }
            });
          }
        });

        let addedObj = [];
        let fyToUpdateInColumnArray = [];

        uniqueObj.length &&
          uniqueObj.map((i, idx) => {
            let headerWiseFyArray = [];

            notesTemplateReqArray.map((j, jdx) => {
              if (j.heading == undefined || j.heading == tabHeadingValue) {
                headerWiseFyArray.push(j);
              }
            });

            let fyIdx = headerWiseFyArray.findIndex(
              (x) => x.validFromDate === notesTemplateReq.validFromDate
            );

            let newRowObj = {
              name: i.name,
              range: i.range,
              year: notesTemplateReq.validFromDate,
              statementType: "Notes",
              statementSubType: i.statementSubType,
              isLineSum: i.isLineSum,
              isHeader: i.isHeader,
              lineSumRange: i.lineSumRange,
              isFinalSum: i.isFinalSum,
              no: i.no,
              lineNo: i.lineNo,
              parentLineNo: i.parentLineNo,
              lineType: i.lineType,
              dataType: i.dataType,
              lineLevel: i.lineLevel,
              financialYearValue: fyIdx * -1,
              nameTemp: i.nameTemp,
              reportGUID: i.reportGUID,
              createdByUserID: i.createdByUserID,
            };

            if (i.isHeader && i.lineLevel == 2 && i.dataType == "Label") {
              let fyToUpdateInColumnObj = {
                columnNo: headerWiseFyArray.length + 1,
                financialYear:
                  notesTemplateReq.validFromDate +
                  " - " +
                  notesTemplateReq.validToDate,
                toLineNo: i.lineNo,
                reportGUID: i.reportGUID,
              };

              i["column" + fyToUpdateInColumnObj.columnNo] =
                fyToUpdateInColumnObj.financialYear;

              newRowObj["column" + fyToUpdateInColumnObj.columnNo] =
                fyToUpdateInColumnObj.financialYear;

              fyToUpdateInColumnArray.push(fyToUpdateInColumnObj);
            }

            addedObj.push(newRowObj);
          });

        Object.keys(notesValue).map((heading, idx) => {
          if (tabHeadingValue == heading) {
            let sortedRespArray = [...notesValue[heading]].sort(
              (a, b) => a.lineNo - b.lineNo
            );
            sortedRespArray = [...sortedRespArray, ...addedObj];
            notesValue[heading] = sortedRespArray;
          }
        });

        notes.values = notesValue;

        let sessionDetailsLS = localStorage.getItem("sessionDetails");
        let sessionDetailsParse = JSON.parse(sessionDetailsLS);

        axios
          .post(
            "/annualReportDetails/updateFinancialYearInColumnInNotes",
            fyToUpdateInColumnArray,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              let responseJson = notesValue;
              responseJson["financialYear"] = notesTemplateReqArray;

              if (userSessionAuthKeyReturnValue) {
                let currentPageName = localStorage.getItem("currentPageName");

                let splittedUrl = window.location.href.split("/");
                let currentReportGUIDFromUrl =
                  splittedUrl[splittedUrl.length - 2];

                let updateJsonTableObj = {
                  pageName: "Notes",
                  guid: currentReportGUIDFromUrl,
                  json: JSON.stringify(responseJson),
                };

                axios
                  .post(
                    "/annualReportLine/updateLinesJsonTable",
                    updateJsonTableObj,
                    userSessionAuthKeyReturnValue
                  )
                  .then((response) => {});
              }
            }
          });
      }
    }
  }

  deleteCustAddedColumn(dateToDelete, headingValue, multiYearIdx) {
    const { notesValue } = this.state;
    const { companyInformation, notesTemplateReqArrayReducer } = this.props;

    let fyDeletedArray = [];
    let lineNosToDelete = [];
    let reportGUID = "";

    this.toast.clear();
    this.setState({ nextEnable: false });
    if (userSessionAuthKeyReturnValue) {
      const commonFy = notesTemplateReqArray.filter(
        (obj) => obj.heading == undefined
      );

      const headerWiseFyArray = notesTemplateReqArray.filter(
        (obj) => obj.heading == headingValue
      );

      const mergedFy = commonFy.concat(headerWiseFyArray);

      let fyIndex = mergedFy.findIndex(
        (x) => x.validFromDate == dateToDelete && x.heading == headingValue
      );

      Object.keys(notesValue).map((heading, parentIdx) => {
        if (headingValue == heading) {
          let responseArray = notesValue[heading];

          let updatedArrayResp = [];

          responseArray.map((BSTemplateFieldOutsideMap, idx) => {
            if (BSTemplateFieldOutsideMap.year != dateToDelete) {
              let columnNoToDelete = multiYearIdx + 2;
              BSTemplateFieldOutsideMap["column" + columnNoToDelete] = 0;
              updatedArrayResp.push(BSTemplateFieldOutsideMap);
            }

            if (idx == 0) {
              BSTemplateFieldOutsideMap.financialYearValue = 0;
            } else {
              BSTemplateFieldOutsideMap.financialYearValue = idx * -1;
            }

            reportGUID = BSTemplateFieldOutsideMap.reportGUID;
            lineNosToDelete.push(BSTemplateFieldOutsideMap.lineNo);
          });

          let updatedArrayRespTemp = [...updatedArrayResp];
          updatedArrayRespTemp.sort((a, b) => {
            return a.lineNo - b.lineNo;
          });

          let updatedArrayHeadingResp = [];
          let fyCount = 0;
          updatedArrayRespTemp.map((i, idx) => {
            if (!updatedArrayHeadingResp.includes(i.lineNo + "-" + i.name)) {
              updatedArrayHeadingResp.push(i.lineNo + "-" + i.name);
              fyCount = 0;
              i.financialYearValue = 0;
              i.column1 = i.name;
              if (i.lineAmount != undefined) {
                i.column2 = i.lineAmount;
              }
            } else {
              fyCount += 1;
              i.financialYearValue = fyCount * -1;

              i.column3 = 0;
              i.column4 = 0;
              i.column5 = 0;
              i.column6 = 0;

              i["previousYear1Value"] = undefined;
              i["previousYear2Value"] = undefined;
              i["previousYear3Value"] = undefined;
              i["previousYear4Value"] = undefined;

              switch (i.financialYearValue) {
                case -1:
                  i.column3 = i.lineAmount;
                  i["previousYear1Value"] = i.lineAmount;
                  break;

                case -2:
                  i.column4 = i.lineAmount;
                  i["previousYear2Value"] = i.lineAmount;
                  break;

                case -3:
                  i.column5 = i.lineAmount;
                  i["previousYear3Value"] = i.lineAmount;
                  break;

                case -4:
                  i.column6 = i.lineAmount;
                  i["previousYear4Value"] = i.lineAmount;
                  break;
              }
            }
          });

          notesValue[heading] = updatedArrayRespTemp;

          notesTemplateReqArray.length &&
            notesTemplateReqArray.map((i, idx) => {
              if (i.heading == undefined) {
                fyDeletedArray.push(i);
              } else {
                if (i.heading == headingValue) {
                  if (i.validFromDate != dateToDelete) {
                    fyDeletedArray.push(i);
                  }
                } else {
                  fyDeletedArray.push(i);
                }
              }
            });

          notesTemplateReqArray = [];
          notesTemplateReqArray = fyDeletedArray;

          this.setState({
            reRender: true,
          });
        }
      });

      lineNosToDelete.sort(function (a, b) {
        return a - b;
      });
      lineNosToDelete = [...new Set(lineNosToDelete)];

      Object.keys(notesValue).map((heading, parentIdx) => {
        if (headingValue == heading) {
          let responseArray = notesValue[heading];
          responseArray.map((i, idx) => {
            if (i.isHeader && i.lineLevel == 2 && i.dataType == "Label") {
              switch (fyIndex + 2) {
                case 3:
                  if (i.column4 != undefined) {
                    i.column3 = i.column4;
                    i.column4 = 0;
                  }

                  if (i.column5 != undefined) {
                    i.column4 = i.column5;
                    i.column5 = 0;
                  }

                  if (i.column6 != undefined) {
                    i.column5 = i.column6;
                    i.column6 = 0;
                  }

                  break;

                case 4:
                  if (i.column5 != undefined) {
                    i.column4 = i.column5;
                    i.column5 = 0;
                  }

                  if (i.column6 != undefined) {
                    i.column5 = i.column6;
                    i.column6 = 0;
                  }

                  break;

                case 5:
                  if (i.column6 != undefined) {
                    i.column5 = i.column6;
                    i.column6 = 0;
                  }

                  break;
              }
            }
          });
        }
      });

      let fyStringArray = [];
      notesTemplateReqArray.length &&
        notesTemplateReqArray.map((i, idx) => {
          fyStringArray.push(i.validFromDate);
        });

      let responseArray = notesValue[headingValue];
      let updatedResponseArray = responseArray.filter((obj) =>
        fyStringArray.includes(obj.year)
      );
      notesValue[headingValue] = updatedResponseArray;

      let deleteColumnReq = {
        fromLineNo: lineNosToDelete[0],
        toLineNo: lineNosToDelete[lineNosToDelete.length - 1],
        columnNo: fyIndex + 2,
        reportGUID: reportGUID,
      };

      // let notesReqArrayReducerValue = notesTemplateReqArrayReducer.values;
      // notesReqArrayReducerValue.splice(multiYearIdx);
      notesTemplateReqArrayReducer.values = notesTemplateReqArray;

      let sessionDetailsLS = localStorage.getItem("sessionDetails");
      let sessionDetailsParse = JSON.parse(sessionDetailsLS);

      axios
        .post(
          "/annualReportDetails/deleteAddedColumnInNotes",
          deleteColumnReq,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseJson = notesValue;
            responseJson["financialYear"] = notesTemplateReqArray;

            // if (userSessionAuthKeyReturnValue) {
            let currentPageName = localStorage.getItem("currentPageName");

            let splittedUrl = window.location.href.split("/");
            let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

            let updateJsonTableObj = {
              pageName: "Notes",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };

            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
            // }
          }
        });
    }
  }

  deleteCustomRow(customRowToDelete, heading) {
    const { notesValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      let objectToDelete = Object.assign({}, ...customRowToDelete);
      Object.keys(notesValue).map((i, idx) => {
        if (i == heading) {
          let filteredObjectsToRemove = notesValue[i].filter(
            (item) => item.lineNo == objectToDelete.lineNo
          );

          filteredObjectsToRemove.length &&
            filteredObjectsToRemove.map((ObjToRemove, ObjToRemoveIdx) => {
              notesValue[i].splice(notesValue[i].indexOf(ObjToRemove), 1);
            });

          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              objectToDelete,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              if (response.data) {
                this.dataManipulation(notesValue, false, true);
                this.setState({
                  reRender: true,
                });
              }
            });
        }
      });

      this.setState({
        reRender: true,
      });
    }
  }

  setDefaultText(BSTemplateFieldOutsideMap, heading) {
    const { notesValue } = this.state;
    let sessionDetailsLS = localStorage.getItem("sessionDetails");
    let sessionDetailsParse = JSON.parse(sessionDetailsLS);

    if (userSessionAuthKeyReturnValue) {
      notesValue[heading]
        .filter((obj) => obj.lineNo == BSTemplateFieldOutsideMap.lineNo)
        .map((line, lineIdx) => {
          line.name = line.nameTemp;
          line.column1 = line.nameTemp;

          axios
            .post(
              "/annualReportDetails/updateDetailsTextArea",
              line,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              if (response.data) {
                let responseJson = notesValue;
                responseJson["financialYear"] = notesTemplateReqArray;

                // if (userSessionAuthKeyReturnValue) {
                let currentPageName = localStorage.getItem("currentPageName");

                let splittedUrl = window.location.href.split("/");
                let currentReportGUIDFromUrl =
                  splittedUrl[splittedUrl.length - 2];

                let updateJsonTableObj = {
                  pageName: "Notes",
                  guid: currentReportGUIDFromUrl,
                  json: JSON.stringify(responseJson),
                };

                axios
                  .post(
                    "/annualReportLine/updateLinesJsonTable",
                    updateJsonTableObj,
                    userSessionAuthKeyReturnValue
                  )
                  .then((response) => {});
                // }
              }
            });
        });

      this.setState({
        reRender: true,
      });
    }
  }

  dateOnComplete(e) {
    const { notesValue } = this.state;
    let dateRows = [];
    let isEnableNextBtn = true;
    {
      Object.keys(notesValue).map((heading, parentIdx) => {
        let responseArray = notesValue[heading];
        responseArray = [...responseArray].sort((a, b) => a.lineNo - b.lineNo);

        let dateRow = responseArray.filter(
          (obj) => obj.templateColumn2 == "Date1"
        );
        dateRows.push(dateRow);
      });
    }
    dateRows = dateRows.flat();

    dateRows &&
      dateRows.length &&
      dateRows.map((i, idx) => {
        if (
          i.column2 == "" ||
          i.column3 == "" ||
          i.column4 == "" ||
          i.column5 == "" ||
          i.column6 == ""
        ) {
          isEnableNextBtn = false;
        }
      });

    if (isEnableNextBtn) {
      this.toast.clear();
      this.setState({ nextEnable: false });
    }
  }

  custAddedDateOnChange(e, parentHeading, lineNo, multiYearIdx) {
    const { notesValue } = this.state;
    let headerWiseFyArray = [];
    let value = e.target.value;
    let valueSplittedByHiffan = value.split(" - ");

    headerWiseFyArray = notesTemplateReqArray.filter(
      (obj) => obj.heading == parentHeading || obj.heading == undefined
    );

    if (valueSplittedByHiffan.length == 2) {
      headerWiseFyArray[multiYearIdx].validFromDate = valueSplittedByHiffan[0];
      headerWiseFyArray[multiYearIdx].validToDate = valueSplittedByHiffan[1];

      let responseArray = notesValue[parentHeading];
      let dateRow = responseArray.filter(
        (obj) => obj.templateColumn2 == "Date1"
      );

      dateRow.map((respValue, respIdx) => {
        let colNo = multiYearIdx + 2;
        respValue["column" + colNo] = value;
      });

      this.setState({
        reRender: true,
      });
    }
  }

  custFyOnBlur(e, parentHeading, lineNo, fyIdxObject, multiYearIdx, mutilYear) {
    const { notesValue } = this.state;
    const { sessionDetails } = this.props;
    if (userSessionAuthKeyReturnValue) {
      let responseArray = notesValue[parentHeading];
      let dateRow = responseArray.filter(
        (obj) => obj.templateColumn2 == "Date1"
      );

      let fyToUpdateInColumnArray = [];
      dateRow.map((respValue, respIdx) => {
        let fyToUpdateInColumnObj = {
          columnNo: multiYearIdx + 2,
          financialYear: e.target.value,
          toLineNo: respValue.lineNo,
          reportGUID: currentReportGUIDFromUrl,
        };
        respValue["column" + fyToUpdateInColumnObj.columnNo] =
          fyToUpdateInColumnObj.financialYear;
        fyToUpdateInColumnArray.push(fyToUpdateInColumnObj);
      });

      let sessionDetailsLS = localStorage.getItem("sessionDetails");
      let sessionDetailsParse = JSON.parse(sessionDetailsLS);

      axios
        .post(
          "/annualReportDetails/updateFinancialYearInColumnInNotes",
          fyToUpdateInColumnArray,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseJson = notesValue;
            responseJson["financialYear"] = notesTemplateReqArray;

            // if (userSessionAuthKeyReturnValue) {
            let currentPageName = localStorage.getItem("currentPageName");

            let splittedUrl = window.location.href.split("/");
            let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

            let updateJsonTableObj = {
              pageName: "Notes",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };

            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
            // }
          }
        });

      this.setState({
        reRender: true,
      });

      if (e.target.value == "") {
        this.setState({ nextEnable: true });
        this.toast.show({
          severity: "error",
          summary: TranslationFile.errorText2[userSelectedLanguage],
          detail: TranslationFile.FillDateRange[userSelectedLanguage],
          // life: 5000,
          sticky: true,
        });
      }
    }
  }

  pageNavigation(navigateToPageName) {
    const { sessionDetails } = this.props;
    let { sideBarReducer } = this.props;
    const annualReport = "/annualreport/";

    let sideBarReducerValues = [];
    sideBarReducerValues = sideBarReducer.values;
    sideBarReducerValues.push("Notes");
    if (sideBarGreenTickPages.length == 0)
      sideBarReducer.values = sideBarReducerValues;

    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );

    let possibleAccessablePagesListLSArray = JSON.parse(
      possibleAccessablePagesListLSString
    );

    if (!possibleAccessablePagesListLSArray.includes("Management Statement")) {
      possibleAccessablePagesListLSArray.push(
        "Company Information",
        "Income Statement",
        "Balance Sheet",
        "Notes",
        "Management Statement"
      );

      let updatePossibleNavigationPagesListString = JSON.stringify(
        possibleAccessablePagesListLSArray
      );

      localStorage.setItem(
        "possibleAccessablePages",
        updatePossibleNavigationPagesListString
      );
    }

    this.props.history.push(
      annualReport + currentReportGUIDFromUrl + "/" + navigateToPageName
    );
  }

  handleChange(toggleOpen) {
    window.scroll(0, 0);
    if (toggleOpen == true) {
      let headerIdxList = [];
      Object.keys(tabTitleArray).map((heading, idx) => {
        headerIdxList.push(idx);
      });
      this.setState({ activeIndex: headerIdxList });
      this.setState({ toggleOpen: false });
    } else {
      let collapse = [];
      this.setState({ activeIndex: collapse });
      this.setState({ toggleOpen: true });
    }
  }
  languageValue = (e) => {};

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    if (status) {
      this.props.history.push(annualReport + session + link);
    }
    this.setState({
      reRender: true,
    });
  };

  accept(headingToDelete, heading, resp) {
    this.deleteHeadingByParentLineNo(headingToDelete, heading, resp);
  }

  deleteHeadingByParentLineNoConfirmDialog(e, headingToDelete, heading, resp) {
    confirmPopup({
      target: e.currentTarget,
      message: "Do you want to delete this header?",
      icon: "pi pi-exclamation-triangle",
      accept: this.accept.bind(this, headingToDelete, heading, resp),
    });
  }

  deleteHeadingByParentLineNo(headingToDelete, heading, resp) {
    const { notesValue } = this.state;
    let notesTemplateTemp = { ...notesTemplate };
    if (userSessionAuthKeyReturnValue) {
      let lineObjsToDelete = [headingToDelete];

      Object.keys(resp).map((i, idx) => {
        if (i == heading) {
          let sortedRespArray = [...resp[i]].sort(
            (a, b) => a.lineNo - b.lineNo
          );

          let childLine = sortedRespArray.filter(
            (obj) => obj.parentLineNo == headingToDelete.lineNo
          );

          lineObjsToDelete = [...lineObjsToDelete, ...childLine];

          sortedRespArray.map((i, idx) => {
            let headerFromChildLine = childLine.filter((obj) => obj.isHeader);

            headerFromChildLine.map((j, jdx) => {
              childLine = sortedRespArray.filter(
                (obj) => obj.parentLineNo == j.lineNo
              );

              lineObjsToDelete = [...lineObjsToDelete, ...childLine];
            });
          });
        }
      });

      const uniqueLineObjsToDelete = lineObjsToDelete.filter((obj, index) => {
        return (
          index === lineObjsToDelete.findIndex((o) => obj.lineNo === o.lineNo)
        );
      });

      let linesAfterRemove = [];
      Object.keys(resp).map((i, idx) => {
        if (i == heading) {
          let sortedRespArray = [...resp[i]].sort(
            (a, b) => a.lineNo - b.lineNo
          );

          sortedRespArray.map((j, jdx) => {
            let lineObj = uniqueLineObjsToDelete.filter(
              (obj) => obj.lineNo == j.lineNo
            );
            if (lineObj.length == 0) {
              linesAfterRemove.push(j);
            }
          });
        }
      });

      resp[heading] = linesAfterRemove;

      let lineNosToDelete = [];
      uniqueLineObjsToDelete &&
        uniqueLineObjsToDelete.length &&
        uniqueLineObjsToDelete.map((i, idx) => {
          lineNosToDelete.push(i.lineNo);

          if (uniqueLineObjsToDelete.length == idx + 1) {
            let deleteLinesByParentLineNoObj = {
              parentLineNo: headingToDelete.parentLineNo,
              reportGUID: currentReportGUIDFromUrl,
              lineNos: lineNosToDelete,
            };

            axios
              .post(
                "/annualReportDetails/deleteNotesByLineNos",
                deleteLinesByParentLineNoObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                if (response.data) {
                  let currentPageName = localStorage.getItem("currentPageName");
                  let splittedUrl = window.location.href.split("/");
                  currentReportGUIDFromUrl =
                    splittedUrl[splittedUrl.length - 2];
                  resp.financialYear = notesTemplateReqArray;
                  let updateJsonTableObj = {
                    pageName: "Notes",
                    guid: currentReportGUIDFromUrl,
                    json: JSON.stringify(resp),
                  };
                  axios
                    .post(
                      "/annualReportLine/updateLinesJsonTable",
                      updateJsonTableObj,
                      userSessionAuthKeyReturnValue
                    )
                    .then((response) => {});
                  this.setState({
                    notesValue: resp,
                  });
                }
              });
          }
        });

      notesTemplate = notesTemplateTemp;
    }
  }

  renderFooter(name) {
    const { custNotesHeader } = this.state;

    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.dialogOnHide(false)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.dialogOnHide(true)}
          autoFocus
          disabled={custNotesHeader != "" ? false : true}
        />
      </div>
    );
  }

  dialogCustNotesOnHide() {
    this.setState({
      isAddNewNotesDialog: false,
      custNotesFirstLine: "",
      isCustNotesFirstLineHeader: false,
      isShowFirstLine: true,
    });
    custFirstLineArray = [];
    custLineListArray = [];
    removedDuplicateLineNo = [];
    gCurrentLineNoOnly = 0;
  }

  componentDidMount() {
    mergedDialogAllLines = [];

    window.scrollTo(0, 0);
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    setTimeout(() => {
      this.setState({ run: userTourGuide });
    }, 500);
  }
  addNewNotesOnClick() {
    this.setState({
      isAddNewNotesDialog: true,
      custNotesHeader: "",
    });
    mergedDialogAllLines = [];
    showLinesPlusInDialog = true;
    if (accordionHeadersArray.length > 0) {
      this.setState({
        selectedNoteTypeObj: accordionHeadersArray[1],
        selectedNoteType: accordionHeadersArray[1].value,
      });
    }

    this.addNotesFromTemplateDialogBtnOnClick(
      false,
      accordionHeadersArray[1].value
    );
  }

  addNewCustNotesOnClick(custLineObj, calcLineNoFromLastLineSumNo) {
    const {
      currentHeaderNo,
      lastTemplateLineObj,
      lastCustLineNo,
      custNotesFirstLine,
      selectedNoteType,
    } = this.state;

    let customLine = { ...lastTemplateLineObj };

    let emptyObjArray = [];
    emptyObjArray = custLineListArray.filter((obj) => {
      return obj.name == "";
    });

    let calcLineNo = 0;
    if (custLineObj != undefined && calcLineNoFromLastLineSumNo) {
      let lineSumLineNo = custLineObj.lineNo;
      let lineArray = custLineListArray.filter(
        (obj) => obj.lineNo < lineSumLineNo
      );
      lineArray = lineArray.sort((a, b) => a.lineNo - b.lineNo);
      let lastLineNo = lineArray.slice(-1)[0].lineNo;
      calcLineNo = lastLineNo + 1;
    }

    if (custLineObj == undefined && !calcLineNoFromLastLineSumNo) {
      gCurrentLineNo = gCurrentLineNo + 1;
    }

    if (emptyObjArray.length == 0 && custNotesFirstLine != "") {
      let headerWiseFyValue = [];

      headerWiseFyValue = notesTemplateReqArray.filter(
        (obj) =>
          (obj.heading &&
            obj.heading
              .toLowerCase()
              .includes(selectedNoteType.toLowerCase())) ||
          obj.heading == null
      );

      headerWiseFyValue &&
        headerWiseFyValue.length &&
        headerWiseFyValue.map((i, idx) => {
          // setTimeout(() => {
          let customLineClone = { ...customLine };
          let financialYearRangeLoc = i.validFromDate + " - " + i.validToDate;

          customLineClone.column1 = "";
          customLineClone.name = "";
          customLineClone.nameTemp = "";
          customLineClone.lineNo =
            calcLineNo != 0 ? calcLineNo : gCurrentLineNo;
          customLineClone.parentLineNo = gCurrentHeaderNo;
          customLineClone.financialYearRange = financialYearRangeLoc;
          customLineClone.financialYearValue = idx > 0 ? -idx : 0;
          customLineClone.year = i.validFromDate;
          customLineClone.dataType = "";
          customLineClone.lineType = "Line";
          customLineClone.lineLevel = 3;
          customLineClone.isLineSum = false;
          customLineClone.isCustomRow = true;
          customLineClone.isLineFromDialog = true;

          if (custLineObj != undefined) {
            customLineClone.lineSumRange =
              custLineObj.lineSumRange != "" &&
              gCurrentHeaderNo + " - " + gCurrentLineNo;

            customLineClone.isHeader = custLineObj.isHeader;
            customLineClone.dataType = custLineObj.dataType;
          }

          custLineListArray.push(customLineClone);
          // mergedDialogAllLines.push(customLineClone);
          mergedDialogAllLines = [...custFirstLineArray, ...custLineListArray];
          this.setState({
            reRender: true,
          });
          // }, 10);
        });
    }
  }

  deleteFirstLine() {
    this.setState({
      isShowFirstLine: false,
      custNotesFirstLine: "",
      isCustNotesFirstLineHeader: false,
    });
  }

  deleteDialogLineList(custLineIdx) {
    let objectToRemoveLineNo = removedDuplicateLineNo[custLineIdx].lineNo;

    custLineListArray = custLineListArray.filter((obj) => {
      return obj.lineNo != objectToRemoveLineNo;
    });

    mergedDialogAllLines = [...custFirstLineArray, ...custLineListArray];

    removedDuplicateLineNo.splice(custLineIdx, 1);
    this.setState({
      reRender: true,
    });
  }

  dialogLineListOnChange(e, custLine) {
    let custLineListArrayFilteredByLineNo = custLineListArray.filter(
      (obj) => obj.lineNo == custLine.lineNo
    );

    custLineListArrayFilteredByLineNo.map((i, idx) => {
      i.column1 = e.target.value;
      i.name = e.target.value;
      i.nameTemp = e.target.value;
      i.isCustomRow = true;
      i.isLineFromDialog = true;

      custLine.column1 = e.target.value;
      custLine.name = e.target.value;
      custLine.nameTemp = e.target.value;
      custLine.isCustomRow = true;
      custLine.isLineFromDialog = true;
    });

    this.setState({ reRender: true });
  }

  dialogLineListOnBlur(e, custLine, isFromRender) {
    const { currentHeaderNo, lastCustLineNo } = this.state;

    let lineNoTemp = gCurrentLineNo;

    if (custLine.lineNo == lineNoTemp || !isFromRender) {
      this.setState({
        lastCustLineNo: lineNoTemp,
      });
      let custLineListArrayFilteredByLineNo = custLineListArray.filter(
        (obj) => obj.lineNo == lineNoTemp
      );

      custLineListArrayFilteredByLineNo.map((i, idx) => {
        custLine.column1 = e;
        custLine.name = e;
        custLine.nameTemp = e;
        custLine.isCustomRow = true;
        custLine.isLineFromDialog = true;
        i.column1 = e;
        i.name = e;
        i.nameTemp = e;
        i.dataType = custLine.dataType;
        i.isHeader = custLine.isHeader;
        i.isLineSum = custLine.isLineSum;
        i.lineType = custLine.lineType;
        i.isCustomRow = true;
        i.isLineFromDialog = true;
      });
    }
  }

  dialogHeaderOnChange(e) {
    const {} = this.state;

    this.setState({ custNotesHeader: e.target.value });
  }

  dialogHeadingOnBlur(e) {
    const {
      lastTemplateHeaderNo,
      lastTemplateHeaderObj,
      notesValue,
      lastTemplateLineSumNo,
      lastCustHeaderNo,
      lastCustDialogLineNo,
      currentHeaderNo,
      lastCustLineNo,
      selectedNoteType,
      selectedNoteTypeObj,
    } = this.state;
    let customHeader = { ...lastTemplateHeaderObj };
    let tempCurParentLinedNo = 0;

    if (custHeaderArray.length > 0) {
      custHeaderArray.map((i, idx) => {
        i.column1 = e;
        i.name = e;
        i.nameTemp = e;
      });
    } else {
      if (e != "") {
        let headerWiseFyValue = [];

        headerWiseFyValue = notesTemplateReqArray.filter(
          (obj) =>
            (obj.heading &&
              obj.heading
                .toLowerCase()
                .includes(selectedNoteType.toLowerCase())) ||
            obj.heading == null
        );

        headerWiseFyValue &&
          headerWiseFyValue.length &&
          headerWiseFyValue.map((i, idx) => {
            let customHeaderClone = { ...customHeader };
            let financialYearRangeLoc = i.validFromDate + " - " + i.validToDate;
            let colNo = idx + 2;

            customHeaderClone.column1 = e;
            customHeaderClone.name = e;
            customHeaderClone.nameTemp = e;
            customHeaderClone.isLineFromDialog = true;
            if (custHeaderArray.length == 0) {
              Object.keys(notesValue).map((head, headIidx) => {
                let tabHeader = head.split(" - ")[1];
                if (tabHeader == selectedNoteType) {
                  let sortedRespArray = [...notesValue[head]].sort(
                    (a, b) => a.lineNo - b.lineNo
                  );
                  gCurrentLineNo =
                    sortedRespArray[sortedRespArray.length - 1].lineNo;
                }
              });

              custUsedLineNos.push(gCurrentLineNo + 1);
              this.setState({
                currentHeaderNo: gCurrentLineNo + 1,
              });
              gCurrentHeaderNo = gCurrentLineNo + 1;
              gCurrentLineNo = gCurrentLineNo + 1;
              customHeaderClone.lineNo = gCurrentLineNo;
              customHeaderClone.parentLineNo = gCurrentLineNo;
              tempCurParentLinedNo = gCurrentLineNo;
            } else {
              customHeaderClone.lineNo = gCurrentLineNo;
              customHeaderClone.parentLineNo = selectedNoteTypeObj.lineNo;
            }
            customHeaderClone.lineType = "Header";
            this.setCustomHeaderObj(
              customHeaderClone,
              e,
              financialYearRangeLoc,
              i,
              colNo,
              idx
            );

            custHeaderArray.push(customHeaderClone);
          });
      }
    }
  }

  dialogFirstLineOnChange(e, custLine) {
    const { custNotesHeader } = this.state;

    if (custNotesHeader != "") {
      this.setState({
        custNotesFirstLine: e.target.value,
      });

      if (custLine != undefined) {
        custLine.name = e.target.value;
        custLine.column1 = e.target.value;
      }

      //
    } else {
      alert("ERROR");
    }
  }

  dialogFirstLineOnBlur(e, firstLineObj, isFromRender) {
    const {
      currentHeaderNo,
      lastTemplateLineObj,
      lastTemplateLineSumNo,
      selectedNoteType,
    } = this.state;

    let customLine = { ...lastTemplateLineObj };

    gCurrentLineNo = gCurrentLineNo + 1;

    if (isFromRender) {
      let mnm = mergedDialogAllLines.filter(
        (obj) => obj.lineNo == firstLineObj.lineNo
      );
      mnm &&
        mnm.length &&
        mnm.map((mnmObj, mnmIdx) => {
          mnmObj.name = e;
          mnmObj.column1 = e;
        });
    }

    let headerWiseFyValue = [];

    headerWiseFyValue = notesTemplateReqArray.filter(
      (obj) =>
        (obj.heading &&
          obj.heading.toLowerCase().includes(selectedNoteType.toLowerCase())) ||
        obj.heading == null
    );

    headerWiseFyValue &&
      headerWiseFyValue.length &&
      headerWiseFyValue.map((i, idx) => {
        let customLineClone = { ...customLine };
        let financialYearRangeLoc = i.validFromDate + " - " + i.validToDate;

        customLineClone.column1 = e;
        customLineClone.name = e;
        customLineClone.nameTemp = e;
        customLineClone.financialYearRange = financialYearRangeLoc;
        customLineClone.financialYearValue = idx > 0 ? -idx : 0;
        customLineClone.year = i.validFromDate;
        customLineClone.dataType = "";
        customLineClone.lineType = "Line";

        customLineClone.isLineSum = false;
        customLineClone.isCustomRow = true;
        customLineClone.isLineFromDialog = true;

        customLineClone.lineNo =
          custFirstLineArray.length > 0
            ? custFirstLineArray[0].lineNo
            : gCurrentLineNo;

        customLineClone.parentLineNo =
          custFirstLineArray.length > 0
            ? custFirstLineArray[0].parentLineNo
            : gCurrentHeaderNo;

        let CustFirstLineArrayFilter = custFirstLineArray.filter(
          (obj) => obj.lineNo == gCurrentHeaderNo + 1
        );

        if (isFromRender) {
          CustFirstLineArrayFilter = custFirstLineArray;
        }

        if (gCurrentHeaderNo == 0) {
          this.setState({
            lastCustLineNo: lastTemplateLineSumNo + 1,
          });
        } else {
          this.setState({
            lastCustLineNo: gCurrentHeaderNo + 1,
          });
        }

        if (firstLineObj != undefined && !isFromRender) {
          customLineClone.isHeader = firstLineObj.isHeader;
          customLineClone.dataType = firstLineObj.dataType;
        }

        if (firstLineObj != undefined) {
          customLineClone.lineLevel = firstLineObj.lineLevel;
        }

        custFirstLineArray.push(customLineClone);
        // mergedDialogAllLines.push(customLineClone);

        CustFirstLineArrayFilter.length &&
          CustFirstLineArrayFilter.map((firstLineOnbj, firstLineObjIdx) => {
            firstLineOnbj.column1 = e;
            firstLineOnbj.name = e;
            firstLineOnbj.nameTemp = e;
          });
      });

    custFirstLineArray = this.unique(custFirstLineArray, "year");
    // mergedDialogAllLines = this.unique(mergedDialogAllLines, "year");
  }

  setCustomHeaderObj(
    customHeaderClone,
    e,
    financialYearRangeLoc,
    i,
    colNo,
    idx
  ) {
    customHeaderClone.templateColumn2 = "Date1";
    customHeaderClone.templateColumn3 = "Date2";
    customHeaderClone.templateColumn4 = "Date3";
    customHeaderClone.templateColumn5 = "Date4";
    customHeaderClone.templateColumn6 = "Date5";
    customHeaderClone.dataType = "Label";
    customHeaderClone.financialYearRange = financialYearRangeLoc;
    customHeaderClone.financialYearValue = idx > 0 ? -idx : 0;
    customHeaderClone.year = i.validFromDate;
    customHeaderClone["column" + colNo] = financialYearRangeLoc;
    customHeaderClone.lineLevel = 2;
  }

  dialogOnHide(saveStatus) {
    const { notesValue, selectedNoteType } = this.state;
    let fieldExistsInMultiYear = [];
    if (userSessionAuthKeyReturnValue) {
      this.setState({
        isShowFirstLine: true,
      });

      gCurrentLineNoOnly = 0;

      if (saveStatus) {
        var mergeHeaderAndFirstLineArray = [
          ...custHeaderArray,
          ...custFirstLineArray,
        ];

        var removeEmptyCustLineListArray = custLineListArray.filter((obj) => {
          if (obj.dataType != "Textarea") {
            return obj.name != "";
          } else {
            return obj;
          }
        });

        var mergeAllCustArray = [
          ...mergeHeaderAndFirstLineArray,
          ...removeEmptyCustLineListArray,
        ];

        mergeAllCustArray.sort((a, b) => {
          return a.lineNo - b.lineNo;
        });

        mergeAllCustArray.map((i, idx) => {
          if (!i.isHeader) {
            return (
              (i.column2 = null),
              (i.column3 = null),
              (i.column4 = null),
              (i.column5 = null),
              (i.column6 = null),
              delete i.lineAmount
            );
          }
        });

        let mergeAllCustOutsideArray = mergeAllCustArray;
        let mergeAllCustInsideArray = mergeAllCustArray;
        let filteredByLineNo = [];

        mergeAllCustOutsideArray.map((outside, outsideIdx) => {
          if (outsideIdx % 2 == 0) {
            filteredByLineNo = mergeAllCustInsideArray.filter((inside) => {
              return inside.lineNo == outside.lineNo;
            });

            let removedNullKeyArray = [];
            filteredByLineNo.length &&
              filteredByLineNo.map((j, jdx) => {
                let removedNullKeyObj = JSON.parse(JSON.stringify(j));
                Object.keys(removedNullKeyObj).forEach((key) => {
                  if (removedNullKeyObj[key] === null) {
                    delete removedNullKeyObj[key];
                  }
                });

                removedNullKeyArray.push(removedNullKeyObj);
              });

            fieldExistsInMultiYear.push(
              Object.assign({}, ...removedNullKeyArray)
            );
          }
        });

        axios
          .post(
            "/annualReportLine/insertLines",
            fieldExistsInMultiYear,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {});

        Object.keys(notesValue).map((heading, parentIdx) => {
          let sortedRespArray = [...notesValue[heading]].sort(
            (a, b) => a.lineNo - b.lineNo
          );

          let tabHeader = heading.split(" - ")[1];
          if (tabHeader == selectedNoteType) {
            sortedRespArray = [...sortedRespArray, ...mergeAllCustArray];

            this.setState({
              custNotesFirstLine: "",
              isCustNotesFirstLineHeader: false,
              custNotesHeader: "",
              isAddNewNotesDialog: false,
            });

            notesValue[heading] = sortedRespArray;

            custUsedLineNos = [];
            custLineListArray = [];
            custHeaderArray = [];
            custFirstLineArray = [];
            removedDuplicateLineNo = [];

            let responseJson = notesValue;

            responseJson["financialYear"] = notesTemplateReqArray;

            let currentPageName = localStorage.getItem("currentPageName");
            let splittedUrl = window.location.href.split("/");
            currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

            let updateJsonTableObj = {
              pageName: "Notes",
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };

            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
          }
        });
      } else {
        this.dialogCustNotesOnHide();
        custHeaderArray = [];
      }
    }
  }

  unique(custLineListArray, key) {
    let result = custLineListArray.filter((obj, index) => {
      return index === custLineListArray.findIndex((o) => obj[key] === o[key]);
    });

    result = [...result].sort((a, b) => a.lineNo - b.lineNo);

    return result;
  }

  addNotesFromTemplateDialogBtnOnClick(
    showHeaderOptionDialogBox,
    defaultSelectedNoteHeader
  ) {
    const { notesValue, selectedNoteType } = this.state;

    delete notesTemplate.financialYear;

    this.setState({
      custNotesFirstLine: "",
      isCustNotesFirstLineHeader: false,
    });

    custFirstLineArray = [];
    custLineListArray = [];
    removedDuplicateLineNo = [];

    let selectedNoteHeader = "";
    if (!showHeaderOptionDialogBox) {
      selectedNoteHeader = defaultSelectedNoteHeader;
    } else {
      selectedNoteHeader = selectedNoteType;
    }

    Object.keys(notesTemplate).map((i, idx) => {
      let tabHeader = i.split(" - ")[1];
      if (tabHeader == selectedNoteHeader) {
        let sortedRespArray = [...notesTemplate[i]].sort(
          (a, b) => a.lineNo - b.lineNo
        );
        let sortedNotesStatepArray = [];
        Object.keys(notesValue).map((notesValuesObj, notesValuesIdx) => {
          let tabHeader = notesValuesObj.split(" - ")[1];
          if (tabHeader == selectedNoteHeader) {
            sortedNotesStatepArray = [...notesValue[notesValuesObj]].sort(
              (a, b) => a.lineNo - b.lineNo
            );

            sortedNotesStatepArray = sortedNotesStatepArray.filter(
              (obj) => obj.isHeader && obj.lineLevel == 2
            );
          }
        });

        sortedRespArray = sortedRespArray.filter(
          (obj) =>
            obj.isHeader &&
            obj.lineLevel == 2 &&
            obj.isLineFromDialog == undefined
        );

        dialogTemplateOptions = [];
        let addedLineNos = [];
        sortedRespArray.map((result, resultIdx) => {
          // if (resultIdx % 2 == 0) {
          let checkHeaderAlreadyExistsInState = sortedNotesStatepArray.filter(
            (obj) => obj.name == result.name
          );
          if (checkHeaderAlreadyExistsInState.length == 0) {
            let dataObj = {
              value: result.name,
              label: result.name,
              lineNo: result.lineNo,
            };

            if (!addedLineNos.includes(result.lineNo)) {
              addedLineNos.push(result.lineNo);
              dialogTemplateOptions.push(dataObj);
            }
          }
          // }
        });
      }
    });

    this.setState({
      isAddNewNotesFromTemplateDialog: showHeaderOptionDialogBox,
    });
  }

  noteTypeOnChange(e) {
    const { notesValue, selectedNoteType } = this.state;

    this.setState({
      selectedNoteType: e.value,
      selectedNoteTypeObj: e,
      isCustNotesFirstLineHeader: false,
      custNotesFirstLine: "",
      custNotesHeader: "",
    });
    custLineListArray = [];
    custHeaderArray = [];
    custFirstLineArray = [];
    custUsedLineNos = [];
    removedDuplicateLineNo = [];
    mergedDialogAllLines = [];

    console.log(e, "** Selected Obj", notesTemplate);

    delete notesTemplate.financialYear;

    this.setState({
      custNotesFirstLine: "",
      isCustNotesFirstLineHeader: false,
    });

    custFirstLineArray = [];
    custLineListArray = [];
    removedDuplicateLineNo = [];

    Object.keys(notesTemplate).map((i, idx) => {
      let tabHeader = i.split(" - ")[1];
      if (tabHeader == e.value) {
        let sortedRespArray = [...notesTemplate[i]].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        let sortedNotesStatepArray = [];
        Object.keys(notesValue).map((notesValuesObj, notesValuesIdx) => {
          let tabHeader = notesValuesObj.split(" - ")[1];
          if (tabHeader == e.value) {
            sortedNotesStatepArray = [...notesValue[notesValuesObj]].sort(
              (a, b) => a.lineNo - b.lineNo
            );

            sortedNotesStatepArray = sortedNotesStatepArray.filter(
              (obj) => obj.isHeader && obj.lineLevel == 2
            );
          }
        });

        sortedRespArray = sortedRespArray.filter(
          (obj) =>
            obj.isHeader &&
            obj.lineLevel == 2 &&
            obj.isLineFromDialog == undefined
        );

        dialogTemplateOptions = [];
        let addedLineNos = [];
        sortedRespArray.map((result, resultIdx) => {
          let checkHeaderAlreadyExistsInState = sortedNotesStatepArray.filter(
            (obj) => obj.name == result.name
          );
          if (checkHeaderAlreadyExistsInState.length == 0) {
            let dataObj = {
              value: result.name,
              label: result.name,
              lineNo: result.lineNo,
            };

            if (!addedLineNos.includes(result.lineNo)) {
              addedLineNos.push(result.lineNo);
              dialogTemplateOptions.push(dataObj);
            }
          }
        });

        console.log(sortedRespArray, "** LIST", notesTemplateReqArray);
      }
    });
  }

  selectTemplateHeaderOnChange(e) {
    const { selectedNoteType } = this.state;
    this.setState({
      selectedTemplateHeader: e,
      custNotesHeader: e.label,
      isLoading: true,
    });
    custHeaderArray = [];
    setTimeout(() => {
      this.dialogHeadingOnBlur(e.label);
      let notesTemplateTemp = { ...notesTemplate };

      Object.keys(notesTemplateTemp).map((i, idx) => {
        let tabHeader = i.split(" - ")[1];
        if (tabHeader == selectedNoteType) {
          let sortedRespArray = [...notesTemplate[i]].sort(
            (a, b) => a.lineNo - b.lineNo
          );

          let sortedRespArrayTemp = [...notesTemplate[i]].sort(
            (a, b) => a.lineNo - b.lineNo
          );

          sortedRespArray = sortedRespArray.filter(
            (obj) => obj.dataType != "Hide"
          );

          sortedRespArray = sortedRespArray.filter(
            (obj) => obj.parentLineNo == e.lineNo && obj.lineNo != e.lineNo
          );

          let getSubHeader = sortedRespArray.filter((obj) => obj.isHeader);

          getSubHeader &&
            getSubHeader.length &&
            getSubHeader.map((j, jdx) => {
              let subHeadeLine = sortedRespArrayTemp.filter(
                (obj) => obj.parentLineNo == j.lineNo
              );
              sortedRespArray = [...sortedRespArray, ...subHeadeLine];
            });

          sortedRespArray.sort((a, b) => {
            return a.lineNo - b.lineNo;
          });

          let removedDuplicateLineNoTemp = this.unique(
            sortedRespArray,
            "lineNo"
          );

          if (
            removedDuplicateLineNoTemp &&
            removedDuplicateLineNoTemp.length > 0
          ) {
            let firstLineObj = removedDuplicateLineNoTemp[0];
            this.setState({
              custNotesFirstLine: firstLineObj.name,
            });

            //isCustNotesFirstLineHeader

            if (firstLineObj.isHeader) {
              this.setState({
                isCustNotesFirstLineHeader: true,
              });
            } else {
              this.setState({
                isCustNotesFirstLineHeader: false,
              });
            }
            this.dialogFirstLineOnBlur(firstLineObj.name, firstLineObj, false);
            let custLineListArrayTemp;
            if (removedDuplicateLineNoTemp.length == 1) {
              custLineListArrayTemp = removedDuplicateLineNoTemp;
            } else {
              custLineListArrayTemp = removedDuplicateLineNoTemp.splice(1);
            }

            custLineListArrayTemp &&
              custLineListArrayTemp.length &&
              custLineListArrayTemp.map((custLineObj, custLineIdx) => {
                gCurrentLineNo = gCurrentLineNo + 1;
                if (custLineObj.lineType == "Line Sum") {
                  gCurrentLineNoOnly = gCurrentLineNo + 100;
                  gCurrentLineNo = gCurrentLineNoOnly;
                }
                this.addNewCustNotesOnClick(custLineObj, false);
                this.dialogLineListOnBlur(custLineObj.name, custLineObj, false);
              });

            if (sortedRespArray.length == notesTemplateReqArray.length) {
              custLineListArray = [];
            }

            mergedDialogAllLines = [
              ...custFirstLineArray,
              ...custLineListArray,
            ];
          }

          this.setState({
            isAddNewNotesFromTemplateDialog: false,
            selectedTemplateHeader: "",
            isLoading: false,
          });
        }
      });
    }, 1000);
  }

  selectTemplateHeaderOnHide() {
    this.setState({
      isAddNewNotesFromTemplateDialog: false,
    });
    isLineSumAlreadyExists = true;
  }

  navigateToPDF() {
    window.open(`#/annualreport/${currentReportGUIDFromUrl}/pdf`, "_blank");
  }

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  tourStatus = (e) => {
    this.setState({ run: e });
  };

  render() {
    const {
      notesValue,
      activeIndex,
      sideBarStatus,
      toggleOpen,
      companyName,
      currentFinancialYear,
      nextEnable,
      lastTemplateLineSumNo,
      lastTemplateLineObj,
      lastTemplateHeaderNo,
      lastTemplateHeaderObj,
      isAddNewNotesDialog,
      isAddNewNotesFromTemplateDialog,
      addNewCustLineTxt,
      custNotesFirstLine,
      isCustNotesFirstLineHeader,
      selectedTemplateHeader,
      isShowFirstLine,
      selectedNoteType,
      selectedNoteTypeObj,
      isLoading,
    } = this.state;
    const { companyInformation } = this.props;
    let BSFieldsArrayWithoutDuplicate = [];
    let isFieldExistsAlready = false;

    delete notesValue.financialYear;

    removedDuplicateLineNo = this.unique(mergedDialogAllLines, "lineNo");

    let totalLblInDialog = false;

    console.log(notesValue, "$$ RENDER");

    return (
      <div>
        <Joyride
          continuous
          run={this.state.run}
          disableBeacon={true}
          showProgress
          showSkipButton
          steps={steps}
          scrollToFirstStep
          scrollToSteps
          scrollOffset={100}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          className="companyInfoErrorToast"
          style={{ marginTop: "34%" }}
        ></Toast>
        <div className="notesHeader">
          <NavBarHeader
            callBack={this.callback}
            isAppPortal={true}
            isHomePage={false}
          />
        </div>
        <div className="notesSideBar">
          <Sidebar
            children="Notes"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={this.sideBarCallback}
            tourStatus={this.tourStatus.bind(this)}
          />
        </div>

        <div></div>

        <ConfirmPopup />

        <Dialog
          visible={isAddNewNotesFromTemplateDialog}
          style={{ width: "30vw", height: "20vw" }}
          onHide={() => this.selectTemplateHeaderOnHide()}
          closable={isLoading ? false : true}
          blockScroll
        >
          {isLoading ? (
            <center>
              <ProgressSpinner
                style={{ width: "50px", height: "50px", paddingTop: "37%" }}
              />
            </center>
          ) : (
            <Select
              menuPortalTarget={document.body} // Or document.getElementById('scrollContainer')
              options={dialogTemplateOptions} // set list of the data
              isOptionDisabled={(option) => option.isdisabled} // disable an
              value={selectedTemplateHeader} // set selected value
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              onChange={(e) => this.selectTemplateHeaderOnChange(e)} // assign onChange function
              closeMenuOnScroll={(event) => {
                return event.target.id === "scrollContainer";
              }}
            />
          )}
        </Dialog>

        <Dialog
          visible={isAddNewNotesDialog}
          header={addNewBtnLbl}
          style={{ width: "40vw" }}
          footer={this.renderFooter()}
          blockScroll
          onHide={() => this.dialogCustNotesOnHide()}
        >
          <div className="notesDialogContentParentDiv">
            <div className="notesCompanyNameAndFYInsideDialog">
              <div className="notesCompanyNameInsideDialog">{companyName}</div>
              <div className="notesFinancialYearInsideDialog">
                {currentFinancialYear}
              </div>
            </div>

            <div>
              <label className="notesDialogHeadingLbl">Note Type</label>
              <div className="notesDialogNoteTypeDiv">
                <Select
                  options={accordionHeadersArray} // set list of the data
                  value={selectedNoteTypeObj} // set selected value
                  onChange={(e) => this.noteTypeOnChange(e)} // assign onChange function
                />
              </div>
            </div>

            <div>
              <label className="notesDialogHeadingLbl">HEADING</label>
              <div className="notesDialogHeadingTxtBoxDiv">
                <InputText
                  value={this.state.custNotesHeader}
                  // autoFocus
                  onChange={(e) => this.dialogHeaderOnChange(e)}
                  className="notesDialogHeadingTxtBox"
                  onBlur={(e) => this.dialogHeadingOnBlur(e.target.value)}
                  disabled={linkSharedUser == 3}
                  placeholder="Write your own heading..."
                />
                <Button
                  label="..."
                  onClick={() => {
                    this.addNotesFromTemplateDialogBtnOnClick(true);
                  }}
                  className="notesAddNotesFromTemplateBtn"
                  tooltip="Choose Notes"
                  disabled={dialogTemplateOptions.length > 0 ? false : true}
                />
              </div>

              <div className="notesDialogLinesTitle">
                <label className="notesDialogHeadingLbl">LINES</label>
              </div>
              <div className="notesDialogHeadingTxtBoxDiv">
                {removedDuplicateLineNo && removedDuplicateLineNo.length > 0 ? (
                  <div>
                    {removedDuplicateLineNo.map((custLine, custLineIdx) => {
                      if (custLineIdx > 0) {
                        if (custLine.isHeader) {
                          return (
                            <div
                              style={{
                                height: "45px",
                                padding: "30px",
                              }}
                            >
                              <label className="notesHeaderBelowAddNew">
                                {custLine.name}
                              </label>
                            </div>
                          );
                        } else {
                          let prevObj = removedDuplicateLineNo[custLineIdx - 1];
                          return (
                            <div>
                              {!prevObj.isLineSum &&
                                custLine.isLineSum &&
                                !totalLblInDialog && (
                                  <div className="notesInDialogLineSumFromTemplate">
                                    {(showLinesPlusInDialog = false)}
                                    <Button
                                      label="Add New Line"
                                      onClick={() =>
                                        this.addNewCustNotesOnClick(
                                          custLine,
                                          true
                                        )
                                      }
                                    />
                                    <br />
                                    Total :
                                  </div>
                                )}

                              {custLine.isLineSum &&
                              !totalLblInDialog &&
                              !prevObj.isLineSum
                                ? (totalLblInDialog = true)
                                : (totalLblInDialog = false)}

                              <InputTextarea
                                value={custLine.column1}
                                // autoFocus
                                onChange={(e) =>
                                  this.dialogLineListOnChange(e, custLine)
                                }
                                onBlur={(e) =>
                                  this.dialogLineListOnBlur(
                                    e.target.value,
                                    custLine,
                                    true
                                  )
                                }
                                rows={5}
                                cols={30}
                                className={
                                  custLine.isLineSum
                                    ? "notesDialogLineSum"
                                    : "notesDialogTextArea"
                                }
                                disabled={
                                  (custLine.isLineSum || linkSharedUser == 3) &&
                                  true
                                }
                                placeholder="Write your own lines..."
                              />

                              {(!custLine.isLineSum ||
                                linkSharedUser !== 3) && (
                                <i
                                  id="notesDialogAddDeleteIcon"
                                  className="pi pi-trash"
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.deleteDialogLineList(custLineIdx)
                                  }
                                  title="Add New Line"
                                  style={{
                                    display: linkSharedUser == 3 && "none",
                                  }}
                                ></i>
                              )}
                            </div>
                          );
                        }
                      } else {
                        // if (isShowFirstLine) {
                        return (
                          <div className="notesDialogFirstLineDiv">
                            {custLine.isHeader ? (
                              <div className="notesInDialogHeaderFromTemplate">
                                {custLine.name}
                              </div>
                            ) : (
                              <InputTextarea
                                value={custLine.name}
                                onChange={(e) =>
                                  this.dialogFirstLineOnChange(e, custLine)
                                }
                                onBlur={(e) =>
                                  this.dialogFirstLineOnBlur(
                                    e.target.value,
                                    custLine,
                                    true
                                  )
                                }
                                rows={5}
                                cols={30}
                                className="notesDialogTextArea"
                                disabled={linkSharedUser == 3}
                              />
                            )}
                          </div>
                        );
                        // }
                      }
                    })}
                  </div>
                ) : (
                  <div>
                    <InputTextarea
                      value={custNotesFirstLine}
                      onChange={(e) => this.dialogFirstLineOnChange(e)}
                      onBlur={(e) =>
                        this.dialogFirstLineOnBlur(
                          e.target.value,
                          undefined,
                          true
                        )
                      }
                      rows={5}
                      cols={30}
                      className="notesDialogFirstTextArea"
                      disabled={linkSharedUser == 3}
                      placeholder="Write you own lines..."
                    />
                  </div>
                )}
              </div>

              <div>
                {showLinesPlusInDialog && (
                  <label
                    className="notesDialogAddNewLineLbl"
                    onClick={() =>
                      this.addNewCustNotesOnClick(undefined, false)
                    }
                  >
                    + Add New Line
                  </label>
                )}
              </div>
            </div>
          </div>
        </Dialog>

        <Row
          className={
            sideBarStatus ? "NotesFYStyle" : "NotesFYStyle-sidebar-close"
          }
          // className="NotesParent"
        >
          <Col>
            <div>
              <div className="Page_Title_Notes">
                <div className="Page_Title_T1_Notes">{companyName}</div>

                <div className="Page_Title_notes_Year">
                  {currentFinancialYear}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <Button
              type="button"
              icon="pi pi-file-pdf"
              outlined
              style={{ borderRadius: "70px" }}
              className="pdf_view_notes"
              tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
              tooltipOptions={{ position: "bottom" }}
              // onClick={() => this.navigateToPDF()}
              onClick={() => previewPdf(currentReportGUIDFromUrl)}
            />
          </Col>

          <div className="NotesExapndCollapsToggleLbl">
            {toggleOpen == true ? (
              <Button
                value={toggleOpen}
                icon="pi pi-chevron-down"
                title="Expand"
                onClick={(e) => this.handleChange(toggleOpen)}
                className="toggle-open"
              />
            ) : (
              <Button
                value={toggleOpen}
                icon="pi pi-chevron-up"
                title="Collapse"
                onClick={(e) => this.handleChange(toggleOpen)}
                className="toggle-open"
              />
            )}
          </div>
        </Row>

        <div className={sideBarStatus ? "BSSideBarOpen" : "BSSideBarClose"}>
          <Row className="Notes_RowMainDiv">
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              style={{ width: "100%", marginLeft: "12px" }}
            >
              {notesValue != undefined && (
                <Accordion
                  multiple
                  activeIndex={activeIndex}
                  onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  ref={this.myRef}
                >
                  {Object.keys(notesValue).map((heading, parentIdx) => {
                    BSFieldsArrayWithoutDuplicate = [];
                    let lineSumCount = 0;
                    let responseArray = notesValue[heading];
                    responseArray = [...responseArray].sort(
                      (a, b) => a.lineNo - b.lineNo
                    );

                    let header = "",
                      headerObj = "";
                    let buttonLbl = "";
                    if (responseArray[0].dataType != "Button") {
                      header = responseArray[0].name;
                      headerObj = responseArray[0];
                    } else {
                      header = heading.split(" - ")[1];
                      buttonLbl = responseArray[0].name;
                    }

                    let addNewRowBtn = false;
                    let addNewRowBtnIdx = 0;
                    let isPreviousLineSum = false;
                    let addNewColumnIsExists = false;
                    let addNewColumnCount = 0;
                    let fyRemoveBtnCount = 0;

                    return (
                      <AccordionTab
                        header={this.accordionHeader(
                          header,
                          parentIdx,
                          headerObj
                        )}
                        ref={this.myRef}
                        className="IS-Accordion-Tab"
                      >
                        {responseArray.map((BSTemplateFieldOutsideMap, idx) => {
                          if (
                            idx != 0 &&
                            header != BSTemplateFieldOutsideMap.name
                          ) {
                            if (
                              BSFieldsArrayWithoutDuplicate.includes(
                                BSTemplateFieldOutsideMap.name +
                                  BSTemplateFieldOutsideMap.lineNo
                              )
                            ) {
                              isFieldExistsAlready = true;
                            } else {
                              isFieldExistsAlready = false;
                              BSFieldsArrayWithoutDuplicate.push(
                                BSTemplateFieldOutsideMap.name +
                                  BSTemplateFieldOutsideMap.lineNo
                              );
                            }

                            let fieldExistsInMultiYear = [];

                            responseArray.map(
                              (
                                BSTemplateFieldInsideMap,
                                BSTemplateFieldInsideMapIdx
                              ) => {
                                if (
                                  BSTemplateFieldInsideMap.name ==
                                    BSTemplateFieldOutsideMap.name &&
                                  BSTemplateFieldInsideMap.lineNo ==
                                    BSTemplateFieldOutsideMap.lineNo &&
                                  !isFieldExistsAlready
                                ) {
                                  fieldExistsInMultiYear.push(
                                    BSTemplateFieldInsideMap
                                  );
                                }
                              }
                            );

                            let prevousObject = responseArray[idx - 1];

                            if (prevousObject.isLineSum) {
                              isPreviousLineSum = true;
                            } else {
                              isPreviousLineSum = false;
                            }

                            if (
                              BSTemplateFieldOutsideMap.isLineSum &&
                              !isFieldExistsAlready &&
                              !isPreviousLineSum
                            ) {
                              addNewRowBtnIdx = 0;
                            } else {
                              addNewRowBtnIdx = addNewRowBtnIdx + 1;
                            }

                            let IsStringInputExists = true;

                            if (
                              BSTemplateFieldOutsideMap.lineType == "Header" &&
                              BSTemplateFieldOutsideMap.dataType == "Label" &&
                              BSTemplateFieldOutsideMap.lineLevel == 2 &&
                              !addNewColumnIsExists
                            ) {
                              addNewColumnIsExists = true;
                              addNewColumnCount = 1;
                            } else {
                              addNewColumnCount += addNewColumnCount;
                            }

                            fyRemoveBtnCount += 1;

                            return (
                              <div
                                style={{
                                  marginBottom: "2px",
                                }}
                              >
                                {addNewColumnCount == 1 &&
                                  linkSharedUser != 3 && (
                                    <div className="NotesAddNewColumnDiv">
                                      <i
                                        className="NotesAddNewColumnLbl"
                                        onClick={() =>
                                          this.onClickAddNewColumn(heading)
                                        }
                                        style={{
                                          display:
                                            linkSharedUser == 3 && "none",
                                        }}
                                      >
                                        + Add new column
                                      </i>
                                    </div>
                                  )}
                                {!isFieldExistsAlready && (
                                  <div>
                                    {BSTemplateFieldOutsideMap.dataType !=
                                    "Button" ? (
                                      <Row
                                        className="BSFields"
                                        style={{
                                          marginTop:
                                            isPreviousLineSum &&
                                            BSTemplateFieldOutsideMap.isHeader &&
                                            BSTemplateFieldOutsideMap.lineLevel ==
                                              3 &&
                                            "-15px",
                                        }}
                                      >
                                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                          {/* {!isFieldExistsAlready && ( */}
                                          <div className="NotesLblRowMainsideDiv">
                                            {BSTemplateFieldOutsideMap.isCustomRow ? (
                                              <div>
                                                {BSTemplateFieldOutsideMap.isLineSum ? (
                                                  <div
                                                    className="BSTotalFieldsStyle"
                                                    id={
                                                      !addNewRowBtn &&
                                                      "notesLblRowInsideDivId"
                                                    }
                                                  >
                                                    <label className="BSTotalFieldsStyle">
                                                      {BSTemplateFieldOutsideMap.dataType !=
                                                        "Hide" && (
                                                        <div>
                                                          {
                                                            BSTemplateFieldOutsideMap.name
                                                          }
                                                        </div>
                                                      )}
                                                    </label>
                                                  </div>
                                                ) : BSTemplateFieldOutsideMap.isHeader ? (
                                                  <div
                                                    style={{
                                                      height: "45px",
                                                      background:
                                                        BSTemplateFieldOutsideMap.lineLevel ==
                                                        2
                                                          ? "#DBE2EF"
                                                          : "",
                                                    }}
                                                  >
                                                    <label className="notesHeaderBelowAddNew">
                                                      {
                                                        BSTemplateFieldOutsideMap.name
                                                      }
                                                    </label>
                                                  </div>
                                                ) : (
                                                  <InputTextarea
                                                    // autoFocus
                                                    // className="NotescustomFieldText"
                                                    className={
                                                      BSTemplateFieldOutsideMap.dataType ==
                                                        "Textarea" &&
                                                      BSTemplateFieldOutsideMap.isLineFromDialog ==
                                                        true
                                                        ? "NotesCustomFieldTextFromDialogTextArea"
                                                        : "NotescustomFieldText"
                                                    }
                                                    value={
                                                      BSTemplateFieldOutsideMap.name
                                                    }
                                                    onBlur={(e) =>
                                                      this.validateForm(
                                                        e,
                                                        heading,
                                                        idx,
                                                        prevousObject,
                                                        BSTemplateFieldOutsideMap
                                                      )
                                                    }
                                                    onChange={(e) => {
                                                      this.customOnChange(
                                                        e.target.value,
                                                        BSTemplateFieldOutsideMap,
                                                        heading
                                                      );
                                                    }}
                                                    placeholder={
                                                      "Enter your description"
                                                    }
                                                    disabled={
                                                      linkSharedUser == 3
                                                    }
                                                  />
                                                )}
                                              </div>
                                            ) : BSTemplateFieldOutsideMap.isLineSum ? (
                                              <div
                                                className="BSTotalFieldsStyle"
                                                id={
                                                  !addNewRowBtn &&
                                                  "notesLblRowInsideDivId"
                                                }
                                              >
                                                <label className="BSTotalFieldsStyle">
                                                  {BSTemplateFieldOutsideMap.dataType !=
                                                    "Hide" && (
                                                    <div>
                                                      {
                                                        BSTemplateFieldOutsideMap.name
                                                      }
                                                    </div>
                                                  )}
                                                </label>
                                              </div>
                                            ) : BSTemplateFieldOutsideMap.lineType ==
                                                "Header" &&
                                              BSTemplateFieldOutsideMap.lineLevel >
                                                1 ? (
                                              <div>
                                                {prevousObject.lineType ==
                                                  "Line" &&
                                                prevousObject.dataType !=
                                                  "Textarea" ? (
                                                  <div>
                                                    <div>
                                                      <div
                                                        style={{
                                                          background: "#DBE2EF",
                                                          height: "45px",
                                                          marginLeft: "-22px",
                                                          // marginLeft: "-57px",
                                                          // marginTop: "12px",
                                                        }}
                                                      >
                                                        <label className="notesHeaderBelowAddNew">
                                                          {
                                                            BSTemplateFieldOutsideMap.name
                                                          }
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {BSTemplateFieldOutsideMap.lineType ==
                                                      "Header" &&
                                                    BSTemplateFieldOutsideMap.lineLevel ==
                                                      3 ? (
                                                      <div>
                                                        <label className="notesHeaderBelowAddNew">
                                                          {
                                                            BSTemplateFieldOutsideMap.name
                                                          }
                                                        </label>
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <div
                                                          style={{
                                                            background:
                                                              "#DBE2EF",
                                                            height: "50px",
                                                          }}
                                                        >
                                                          <label className="notesSubHeadingStyle">
                                                            {
                                                              BSTemplateFieldOutsideMap.name
                                                            }
                                                          </label>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            ) : BSTemplateFieldOutsideMap.dataType ==
                                              "Textarea" ? (
                                              <div>
                                                {BSTemplateFieldOutsideMap.name !==
                                                  BSTemplateFieldOutsideMap.nameTemp &&
                                                  BSTemplateFieldOutsideMap.nameTemp !=
                                                    "" && (
                                                    <div className="notesSetDefaultTxtDiv">
                                                      <i
                                                        className="notesSetDefaultTxtITag"
                                                        onClick={() =>
                                                          this.setDefaultText(
                                                            BSTemplateFieldOutsideMap,
                                                            heading
                                                          )
                                                        }
                                                        style={{
                                                          display:
                                                            linkSharedUser ==
                                                              3 && "none",
                                                        }}
                                                      >
                                                        Set default text
                                                      </i>
                                                    </div>
                                                  )}
                                                <InputTextarea
                                                  className="NotescustomFieldTextArea"
                                                  value={
                                                    BSTemplateFieldOutsideMap.column1 ==
                                                    null
                                                      ? BSTemplateFieldOutsideMap.name
                                                      : BSTemplateFieldOutsideMap.column1
                                                  }
                                                  onBlur={(e) =>
                                                    this.onTextAreaFocusOut(
                                                      e,
                                                      heading,
                                                      BSTemplateFieldOutsideMap.lineNo
                                                    )
                                                  }
                                                  onChange={(e) => {
                                                    this.customOnChange(
                                                      e.target.value,
                                                      BSTemplateFieldOutsideMap,
                                                      heading
                                                    );
                                                  }}
                                                  disabled={linkSharedUser == 3}
                                                />
                                              </div>
                                            ) : (
                                              <label
                                                className="BSFieldsStyle"
                                                style={{ marginLeft: "4px" }}
                                              >
                                                {BSTemplateFieldOutsideMap.name}
                                              </label>
                                            )}
                                          </div>
                                        </Col>

                                        {!isFieldExistsAlready &&
                                          BSTemplateFieldOutsideMap.dataType !=
                                            "Textarea" && (
                                            <Col
                                              xs={3}
                                              sm={3}
                                              md={3}
                                              lg={3}
                                              xl={3}
                                              className="NotesAmountBoxCol"
                                            >
                                              {fieldExistsInMultiYear.map(
                                                (mutilYear, multiYearIdx) => {
                                                  let fyIdxObject = {};
                                                  let headerWiseFyValue = [];

                                                  headerWiseFyValue =
                                                    notesTemplateReqArray.filter(
                                                      (obj) =>
                                                        obj.heading ==
                                                          undefined ||
                                                        obj.heading == heading
                                                    );

                                                  {
                                                    console.log(
                                                      headerWiseFyValue,
                                                      "$$",
                                                      multiYearIdx,
                                                      "$$",
                                                      fieldExistsInMultiYear,
                                                      "$$",
                                                      notesTemplateReqArray,
                                                      "$$",
                                                      heading
                                                    );
                                                  }

                                                  fyIdxObject =
                                                    headerWiseFyValue[
                                                      multiYearIdx
                                                    ];

                                                  let fyValue =
                                                    fyIdxObject.validFromDate +
                                                    " - " +
                                                    fyIdxObject.validToDate;

                                                  let isCustAddedColumnStatus =
                                                    fyIdxObject.isCustAddedColumn;

                                                  let showFyDeleteIcon = false;
                                                  if (
                                                    isCustAddedColumnStatus !=
                                                      undefined &&
                                                    isCustAddedColumnStatus &&
                                                    fyRemoveBtnCount == 1
                                                  ) {
                                                    showFyDeleteIcon = true;
                                                  }

                                                  if (
                                                    mutilYear.dataType ==
                                                      "String" &&
                                                    IsStringInputExists
                                                  ) {
                                                    IsStringInputExists = false;
                                                    return (
                                                      <div className="notesAmountBoxRowDiv">
                                                        <InputText
                                                          value={
                                                            mutilYear.column2 !=
                                                            null
                                                              ? mutilYear.column2
                                                              : undefined
                                                          }
                                                          className="notesStringBox"
                                                          onChange={(e) => {
                                                            this.stringInputOnChange(
                                                              e,
                                                              heading,
                                                              mutilYear.lineNo
                                                            );
                                                          }}
                                                          onBlur={(e) => {
                                                            this.stringInputOnBlur(
                                                              e,
                                                              heading,
                                                              mutilYear.lineNo
                                                            );
                                                          }}
                                                          disabled={
                                                            linkSharedUser == 3
                                                          }
                                                        />
                                                        {!BSTemplateFieldOutsideMap.isCustomRow &&
                                                          linkSharedUser !=
                                                            3 && (
                                                            <i
                                                              id="notesAddNewLineIconBelowTextBox"
                                                              className="fa fa-plus"
                                                              aria-hidden="true"
                                                              onClick={(e) =>
                                                                this.onClickAddNewRow(
                                                                  heading,
                                                                  BSTemplateFieldOutsideMap
                                                                )
                                                              }
                                                              title="Add New Line"
                                                              style={{
                                                                display:
                                                                  linkSharedUser ==
                                                                    3 && "none",
                                                              }}
                                                            ></i>
                                                          )}
                                                      </div>
                                                    );
                                                  } else {
                                                    if (
                                                      !mutilYear.isFinalSum &&
                                                      mutilYear.dataType !=
                                                        "String"
                                                    ) {
                                                      if (
                                                        mutilYear.dataType !=
                                                        "Hide"
                                                      ) {
                                                        return (
                                                          <div className="notesAmountBoxRowDiv">
                                                            {mutilYear.isLineSum &&
                                                            mutilYear.dataType !=
                                                              "Textarea" ? (
                                                              <InputNumber
                                                                inputId="integeronly"
                                                                className="NotesAmountBoxDisabled"
                                                                disabled={true}
                                                                value={
                                                                  mutilYear.lineAmount
                                                                }
                                                                id={
                                                                  addNewRowBtnIdx ==
                                                                    0 &&
                                                                  "notesFirstLineSum"
                                                                }
                                                                useGrouping={
                                                                  true
                                                                }
                                                                // disabled={
                                                                //   linkSharedUser ==
                                                                //   3
                                                                // }
                                                                locale="sv-SE"
                                                              />
                                                            ) : mutilYear.lineAmount !=
                                                                undefined &&
                                                              !mutilYear.isHeader &&
                                                              mutilYear.dataType !=
                                                                "Textarea" &&
                                                              mutilYear.lineAmount !=
                                                                0 ? (
                                                              <div>
                                                                <InputNumber
                                                                  className="NotesInputBox"
                                                                  value={Math.round(
                                                                    mutilYear.lineAmount
                                                                  )}
                                                                  onBlur={(e) =>
                                                                    this.AmountOnBlur(
                                                                      e,
                                                                      mutilYear,
                                                                      heading
                                                                    )
                                                                  }
                                                                  useGrouping={
                                                                    true
                                                                  }
                                                                  disabled={
                                                                    linkSharedUser ==
                                                                    3
                                                                  }
                                                                  locale="sv-SE"
                                                                />

                                                                {fieldExistsInMultiYear.length ==
                                                                  multiYearIdx +
                                                                    1 &&
                                                                  !BSTemplateFieldOutsideMap.isCustomRow &&
                                                                  linkSharedUser !=
                                                                    3 && (
                                                                    <i
                                                                      id="notesAddNewLineIcon"
                                                                      className="fa fa-plus"
                                                                      aria-hidden="true"
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        this.onClickAddNewRow(
                                                                          heading,
                                                                          BSTemplateFieldOutsideMap
                                                                        )
                                                                      }
                                                                      title="Add New Line"
                                                                      style={{
                                                                        display:
                                                                          linkSharedUser ==
                                                                            3 &&
                                                                          "none",
                                                                      }}
                                                                    ></i>
                                                                  )}
                                                              </div>
                                                            ) : !mutilYear.isHeader &&
                                                              mutilYear.dataType !=
                                                                "Textarea" ? (
                                                              <div>
                                                                <InputNumber
                                                                  className="NotesInputBox"
                                                                  onBlur={(e) =>
                                                                    this.AmountOnBlur(
                                                                      e,
                                                                      mutilYear,
                                                                      heading
                                                                    )
                                                                  }
                                                                  useGrouping={
                                                                    true
                                                                  }
                                                                  disabled={
                                                                    linkSharedUser ==
                                                                    3
                                                                  }
                                                                  locale="sv-SE"
                                                                />

                                                                {fieldExistsInMultiYear.length ==
                                                                  multiYearIdx +
                                                                    1 &&
                                                                  !BSTemplateFieldOutsideMap.isCustomRow &&
                                                                  linkSharedUser !=
                                                                    3 && (
                                                                    <i
                                                                      id="notesAddNewLineIcon"
                                                                      className="fa fa-plus"
                                                                      aria-hidden="true"
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        this.onClickAddNewRow(
                                                                          heading,
                                                                          BSTemplateFieldOutsideMap
                                                                        )
                                                                      }
                                                                      title="Add New Line"
                                                                      style={{
                                                                        display:
                                                                          linkSharedUser ==
                                                                            3 &&
                                                                          "none",
                                                                      }}
                                                                    ></i>
                                                                  )}
                                                              </div>
                                                            ) : mutilYear.isHeader &&
                                                              mutilYear.lineLevel ==
                                                                2 &&
                                                              mutilYear.dataType ==
                                                                "Label" ? (
                                                              prevousObject.lineType ==
                                                                "Line" &&
                                                              prevousObject.DataType !=
                                                                "Textarea" ? (
                                                                <div>
                                                                  <label className="notesFyLabel">
                                                                    <div>
                                                                      <InputMask
                                                                        value={
                                                                          fyValue
                                                                        }
                                                                        className="notesFyTextArea"
                                                                        disabled={
                                                                          !showFyDeleteIcon ||
                                                                          linkSharedUser ==
                                                                            3
                                                                        }
                                                                        onComplete={(
                                                                          e
                                                                        ) => {
                                                                          this.dateOnComplete(
                                                                            e
                                                                          );
                                                                        }}
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          this.custAddedDateOnChange(
                                                                            e,
                                                                            heading,
                                                                            mutilYear.lineNo,
                                                                            multiYearIdx
                                                                          );
                                                                        }}
                                                                        mask="9999-99-99 - 9999-99-99"
                                                                        placeholder="yyyy-dd-XX - yyyy-dd-mm"
                                                                        slotChar="yyyy-dd-mm - yyyy-dd-mm"
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          this.custFyOnBlur(
                                                                            e,
                                                                            heading,
                                                                            mutilYear.lineNo,
                                                                            fyIdxObject,
                                                                            multiYearIdx,
                                                                            mutilYear
                                                                          );
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    {showFyDeleteIcon && (
                                                                      <div
                                                                        className="deleteCustAddedColumnDiv"
                                                                        onClick={() => {
                                                                          this.deleteCustAddedColumn(
                                                                            fyIdxObject.validFromDate,
                                                                            heading,
                                                                            multiYearIdx
                                                                          );
                                                                        }}
                                                                      >
                                                                        <i className="pi pi-times-circle" />
                                                                      </div>
                                                                    )}
                                                                  </label>
                                                                </div>
                                                              ) : (
                                                                <label className="notesFyLabel">
                                                                  <div>
                                                                    <InputMask
                                                                      value={
                                                                        fyValue
                                                                      }
                                                                      className="notesFyTextArea"
                                                                      disabled={
                                                                        !showFyDeleteIcon ||
                                                                        linkSharedUser ==
                                                                          3
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        this.custAddedDateOnChange(
                                                                          e,
                                                                          heading,
                                                                          mutilYear.lineNo,
                                                                          multiYearIdx
                                                                        );
                                                                      }}
                                                                      onComplete={(
                                                                        e
                                                                      ) => {
                                                                        this.dateOnComplete(
                                                                          e
                                                                        );
                                                                      }}
                                                                      mask="9999-99-99 - 9999-99-99"
                                                                      placeholder="yyyy-dd-mm - yyyy-dd-mm"
                                                                      slotChar="yyyy-dd-mm - yyyy-dd-mm"
                                                                      onBlur={(
                                                                        e
                                                                      ) => {
                                                                        this.custFyOnBlur(
                                                                          e,
                                                                          heading,
                                                                          mutilYear.lineNo,
                                                                          fyIdxObject,
                                                                          multiYearIdx
                                                                        );
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  {showFyDeleteIcon && (
                                                                    <div
                                                                      className="deleteCustAddedColumnDiv"
                                                                      onClick={() => {
                                                                        this.deleteCustAddedColumn(
                                                                          fyIdxObject.validFromDate,
                                                                          heading,
                                                                          multiYearIdx
                                                                        );
                                                                      }}
                                                                    >
                                                                      <i className="pi pi-times-circle" />
                                                                    </div>
                                                                  )}
                                                                </label>
                                                              )
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  }
                                                }
                                              )}
                                              {fieldExistsInMultiYear &&
                                              fieldExistsInMultiYear.length &&
                                              fieldExistsInMultiYear[0]
                                                .isCustomRow &&
                                              !fieldExistsInMultiYear[0]
                                                .isHeader &&
                                              fieldExistsInMultiYear[0]
                                                .lineType != "Line Sum" ? (
                                                <div>
                                                  <i
                                                    id="NotesCustomRowDelete"
                                                    style={{
                                                      marginLeft:
                                                        fieldExistsInMultiYear[0]
                                                          .dataType == "" &&
                                                        "-18px",
                                                      display:
                                                        linkSharedUser == 3 &&
                                                        "none",
                                                    }}
                                                    className="pi pi-trash"
                                                    onClick={this.deleteCustomRow.bind(
                                                      this,
                                                      fieldExistsInMultiYear,
                                                      heading
                                                    )}
                                                  />
                                                  {/* <i
                                                    id="ISAddNewLineIconNotes"
                                                    class="fa fa-plus"
                                                    aria-hidden="true"
                                                    onClick={(e) =>
                                                      this.onClickAddNewRow(
                                                        heading,
                                                        BSTemplateFieldOutsideMap
                                                      )
                                                    }
                                                    title="Add New Line"
                                                    style={{
                                                      display:
                                                        linkSharedUser == 3 &&
                                                        "none",
                                                    }}
                                                  ></i> */}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </Col>
                                          )}
                                        {BSTemplateFieldOutsideMap.isHeader &&
                                          BSTemplateFieldOutsideMap.lineLevel ==
                                            2 &&
                                          BSTemplateFieldOutsideMap.dataType !=
                                            "Hide" &&
                                          parentIdx != 0 &&
                                          linkSharedUser != 3 && (
                                            <Col style={{ textAlign: "end" }}>
                                              <i
                                                id="notesDeleteHeader"
                                                className="fa fa-times-circle"
                                                aria-hidden="true"
                                                title="Remove Header "
                                                onClick={(e) =>
                                                  this.deleteHeadingByParentLineNoConfirmDialog(
                                                    e,
                                                    BSTemplateFieldOutsideMap,
                                                    heading,
                                                    notesValue
                                                  )
                                                }
                                                style={{
                                                  display:
                                                    linkSharedUser == 3 &&
                                                    "none",
                                                }}
                                              ></i>
                                            </Col>
                                          )}
                                      </Row>
                                    ) : (
                                      <div style={{ display: "none" }}>
                                        {
                                          (addNewBtnLbl =
                                            BSTemplateFieldOutsideMap.name)
                                        }
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        })}
                      </AccordionTab>
                    );
                  })}
                </Accordion>
              )}
            </Col>

            <div className="notesBottomBtnDiv">
              {linkSharedUser != 3 && (
                <Button
                  label={
                    TranslationFile.notesAddNewNoteBtn[userSelectedLanguage]
                  }
                  aria-label="Annual Report"
                  onClick={() => this.addNewNotesOnClick()}
                  id="notesAddNewBtn"
                />
              )}

              <center
                style={{ marginTop: linkSharedUser == 3 ? "15px" : "-48px" }}
                className="notesSaveBtnCenter"
              >
                <Button
                  label={TranslationFile.previousBtn[userSelectedLanguage]}
                  aria-label="Annual Report"
                  onClick={() => this.pageNavigation("balanceSheet")}
                  id="annualReportBtn"
                  className="BSSaveBtn"
                />
                <Button
                  label={TranslationFile.nextBtn[userSelectedLanguage]}
                  aria-label="Annual Report"
                  onClick={() => this.pageNavigation("managementStatement")}
                  id="annualReportBtn"
                  className="BSSaveBtn"
                  disabled={nextEnable ? true : false}
                />
              </center>
            </div>
          </Row>
        </div>
        <ScrolltoTop />
        {/* <Footerpage /> */}
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(NotesNew);
